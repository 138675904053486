import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1280 1280">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000"  transform="">

<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M756.000000,1281.000000 
	C504.000000,1281.000000 252.500000,1281.000000 1.000000,1281.000000 
	C1.000000,854.333313 1.000000,427.666656 1.000000,1.000000 
	C427.666656,1.000000 854.333313,1.000000 1281.000000,1.000000 
	C1281.000000,427.666656 1281.000000,854.333313 1281.000000,1281.000000 
	C1106.166626,1281.000000 931.333313,1281.000000 756.000000,1281.000000 
M179.127594,407.945496 
	C178.444870,408.996460 177.762146,410.047424 176.910461,411.817902 
	C176.842575,412.799561 176.774689,413.781250 176.057495,415.165619 
	C175.808716,416.934906 175.342499,418.704102 175.342102,420.473450 
	C175.308456,568.244141 175.306030,716.014832 175.368011,863.785522 
	C175.368774,865.607117 176.229309,867.428345 176.695190,870.050964 
	C176.358505,875.213806 179.445419,877.745544 183.664551,879.848450 
	C220.639771,898.276978 257.548615,916.838623 294.503021,935.309021 
	C359.174042,967.632690 423.867096,999.912292 488.543854,1032.224487 
	C537.723083,1056.794312 586.905457,1081.357788 636.039795,1106.017212 
	C640.316162,1108.163452 644.092102,1108.186035 648.347107,1105.999512 
	C659.006958,1100.521729 669.838318,1095.379028 680.555603,1090.011597 
	C729.846191,1065.326294 779.126648,1040.620605 828.400452,1015.901917 
	C873.209839,993.422852 918.006042,970.917542 963.461182,948.656128 
	C964.950806,947.756653 966.440430,946.857178 968.546692,945.734863 
	C969.298523,945.290100 970.050293,944.845337 971.394531,944.637085 
	C971.987488,944.143738 972.580444,943.650330 973.799072,942.901245 
	C974.802063,942.396484 975.805054,941.891724 977.393616,941.634338 
	C978.007996,941.161804 978.622437,940.689270 979.874939,940.022705 
	C980.854187,939.499634 981.833374,938.976562 983.409485,938.684082 
	C984.014160,938.192017 984.618835,937.699951 985.897095,937.008850 
	C992.866760,933.473022 999.836426,929.937195 1007.392151,926.659424 
	C1007.985535,926.157593 1008.578918,925.655823 1009.752136,925.002258 
	C1010.105164,924.818542 1010.458191,924.634766 1011.410217,924.660583 
	C1012.002441,924.164429 1012.594666,923.668274 1013.857178,922.973938 
	C1020.836792,919.432983 1027.816406,915.891968 1035.379272,912.616699 
	C1035.971924,912.125061 1036.564575,911.633423 1037.833862,910.950806 
	C1048.155273,905.745972 1058.476685,900.541199 1069.378662,895.601257 
	C1069.982666,895.124390 1070.586670,894.647522 1071.842773,893.999146 
	C1072.829346,893.465942 1073.816040,892.932739 1075.394531,892.648804 
	C1075.983398,892.147400 1076.572144,891.645996 1077.802612,890.863586 
	C1084.805908,887.391174 1091.809082,883.918762 1099.408325,880.681946 
	C1099.998901,880.175171 1100.589478,879.668457 1101.852173,878.974609 
	C1107.035767,876.903320 1107.960693,872.820374 1107.957642,867.677551 
	C1107.870728,721.454407 1107.890259,575.231201 1107.886108,429.007996 
	C1107.886108,427.677490 1107.801025,426.347015 1108.031616,424.126617 
	C1108.058960,421.048889 1108.501709,417.898804 1107.982300,414.916260 
	C1107.565430,412.521698 1105.982422,410.330231 1104.807739,407.360901 
	C1104.227783,406.808990 1103.739624,406.071259 1103.054321,405.732300 
	C1085.005127,396.805206 1066.899292,387.992004 1048.881958,379.001068 
	C1036.622070,372.883179 1024.534546,366.420013 1012.274414,360.302704 
	C996.325256,352.344696 980.254883,344.629944 964.284851,336.713257 
	C943.680054,326.498962 923.097595,316.238678 902.551941,305.905823 
	C880.430176,294.780304 858.380249,283.512146 836.264954,272.373627 
	C818.871216,263.613190 801.442078,254.922485 783.999573,246.259354 
	C756.860229,232.780060 729.687500,219.368103 702.546570,205.891968 
	C689.950928,199.637894 677.404602,193.284424 664.820312,187.007370 
	C659.338379,184.273010 653.600220,181.974869 648.384766,178.809128 
	C644.460815,176.427292 640.680908,176.445740 637.078369,178.199432 
	C621.615112,185.726837 606.322327,193.603500 590.913208,201.243408 
	C577.746338,207.771606 564.456116,214.051743 551.310303,220.621353 
	C536.078186,228.233582 520.970825,236.095154 505.756836,243.744125 
	C497.626526,247.831741 489.333862,251.596756 481.209015,255.694824 
	C472.555542,260.059570 464.073151,264.764435 455.406464,269.101685 
	C446.719360,273.449158 437.822083,277.377930 429.151825,281.757324 
	C416.786713,288.003021 404.600586,294.602997 392.237274,300.852417 
	C371.638245,311.264801 350.979248,321.559448 330.307373,331.826874 
	C315.195709,339.332611 300.001434,346.671967 284.883392,354.165039 
	C270.341431,361.372559 255.873779,368.730011 241.330521,375.934814 
	C223.973907,384.533386 206.539642,392.975861 189.216949,401.641724 
	C185.876007,403.313110 182.894577,405.703125 179.127594,407.945496 
z"/>
<path fill="#ED5C5C" opacity="1.000000" stroke="none" 
	d="
M970.802063,944.400513 
	C970.050293,944.845337 969.298523,945.290100 967.890503,945.824158 
	C965.758911,946.750244 964.283508,947.587097 962.808044,948.423889 
	C918.006042,970.917542 873.209839,993.422852 828.400452,1015.901917 
	C779.126648,1040.620605 729.846191,1065.326294 680.555603,1090.011597 
	C669.838318,1095.379028 659.006958,1100.521729 648.347107,1105.999512 
	C644.092102,1108.186035 640.316162,1108.163452 636.039795,1106.017212 
	C586.905457,1081.357788 537.723083,1056.794312 488.543854,1032.224487 
	C423.867096,999.912292 359.174042,967.632690 294.503021,935.309021 
	C257.548615,916.838623 220.639771,898.276978 183.664551,879.848450 
	C179.445419,877.745544 176.358505,875.213806 176.836121,869.177856 
	C177.017242,866.974182 177.092545,865.643616 177.092590,864.313049 
	C177.097946,716.112976 177.098969,567.912842 177.081528,419.712738 
	C177.081329,418.062805 176.837097,416.412842 176.706787,414.762909 
	C176.774689,413.781250 176.842575,412.799561 177.250336,411.679871 
	C177.742004,412.660889 177.893799,413.779907 178.027710,415.354553 
	C178.340088,420.765564 178.957062,425.720825 178.958878,430.676331 
	C179.010681,571.651672 179.000595,712.627075 179.000656,853.602478 
	C179.000656,854.935547 178.961288,856.270142 179.008957,857.601501 
	C179.192429,862.725342 183.717361,867.874695 189.297745,869.149414 
	C194.675034,873.765869 198.702560,879.733154 206.413971,880.925781 
	C207.129700,881.000122 207.539230,881.040833 208.019958,881.417480 
	C214.095322,884.801331 220.099487,887.849243 226.413055,890.924072 
	C227.135590,890.984924 227.548721,891.018860 228.024048,891.394531 
	C228.524460,892.206116 228.875763,892.845703 229.413544,893.120850 
	C244.791336,900.989990 260.177612,908.842896 275.605896,916.612244 
	C276.565216,917.095276 277.877869,916.876648 279.380768,917.034180 
	C283.142578,918.743286 286.549683,920.402771 290.018921,922.394043 
	C291.773560,923.763123 293.466034,924.800354 295.514130,925.866028 
	C296.579681,925.966309 297.289551,926.038147 298.049500,926.438965 
	C299.433167,927.481567 300.766815,928.195190 302.470734,928.935730 
	C306.544769,930.665710 310.248505,932.368774 314.007111,934.416504 
	C318.680206,936.868469 323.298431,938.975830 327.990326,941.433716 
	C330.745270,943.157898 333.426605,944.531555 336.470581,945.931946 
	C339.208374,946.992065 341.583557,948.025513 344.023804,949.397095 
	C346.092102,950.793335 348.095306,951.851318 350.465271,952.937744 
	C353.558777,954.302856 356.285492,955.639587 359.062561,957.329346 
	C360.776093,958.423462 362.439270,959.164490 364.411804,959.924683 
	C365.132294,959.984314 365.543457,960.024780 366.008667,960.414429 
	C368.073547,961.815186 370.084381,962.866760 372.406342,963.936951 
	C373.131561,963.990845 373.545624,964.026123 374.025391,964.398010 
	C376.763611,966.122986 379.436188,967.511414 382.417664,968.920776 
	C383.137451,968.980774 383.548401,969.019714 384.025391,969.396851 
	C389.433075,972.455444 394.774689,975.175964 400.424744,977.915100 
	C401.143158,977.971375 401.553070,978.009033 402.025146,978.388672 
	C410.093109,982.788818 418.098938,986.846863 426.413605,990.924438 
	C427.132843,990.986450 427.543243,991.028931 428.019897,991.406250 
	C430.092560,992.794861 432.098969,993.848755 434.445282,994.926514 
	C435.843292,995.319580 436.901398,995.688843 438.016418,996.378540 
	C438.751617,997.100037 439.429871,997.501160 440.469849,997.923035 
	C442.540161,998.650024 444.248779,999.356201 446.023926,1000.400757 
	C453.429626,1004.459839 460.768890,1008.180603 468.417664,1011.919983 
	C469.138885,1011.976379 469.550568,1012.014099 470.027283,1012.390930 
	C471.064850,1013.152527 472.037415,1013.575073 473.242920,1014.022949 
	C473.475891,1014.048279 473.942047,1014.096985 474.014893,1014.429871 
	C476.760254,1016.143127 479.432770,1017.523560 482.413116,1018.926025 
	C483.129913,1018.993469 483.538879,1019.038818 484.021637,1019.422729 
	C488.762939,1022.144775 493.430450,1024.528320 498.408997,1026.928711 
	C499.133667,1026.982178 499.547363,1027.018677 500.025970,1027.394165 
	C504.097198,1029.788818 508.103516,1031.844360 512.419983,1033.915283 
	C513.142273,1033.967285 513.554443,1034.003784 514.026489,1034.383057 
	C522.761841,1039.116089 531.437317,1043.506348 540.422729,1047.914551 
	C541.144958,1047.967773 541.557312,1048.002930 542.030945,1048.378540 
	C546.760132,1051.117920 551.428040,1053.516846 556.437134,1055.936035 
	C557.834961,1056.331787 558.891663,1056.707275 560.019409,1057.417969 
	C566.761292,1061.138062 573.432251,1064.523071 580.413086,1067.925781 
	C581.135193,1067.982544 581.547302,1068.021606 582.025513,1068.397705 
	C584.760437,1070.127319 587.429321,1071.519653 590.408569,1072.928955 
	C591.130859,1072.984497 591.542969,1073.023438 592.019653,1073.403320 
	C592.376343,1074.130249 592.584595,1074.679932 592.972717,1074.878906 
	C606.011292,1081.566528 619.048401,1088.257935 632.155762,1094.808716 
	C633.207764,1095.334473 634.761414,1094.856567 636.491089,1094.859985 
	C638.264099,1094.929199 639.627869,1094.983398 641.170471,1095.290771 
	C642.235596,1095.373901 643.121948,1095.203735 644.327148,1095.019287 
	C645.069580,1094.962769 645.493164,1094.920654 646.327026,1094.945190 
	C648.112549,1094.962524 649.487610,1094.913086 651.197510,1094.872192 
	C652.027832,1094.259888 652.523438,1093.638916 653.370239,1092.959473 
	C656.767578,1091.553833 659.813660,1090.206421 663.193726,1088.868896 
	C664.026794,1088.261841 664.525818,1087.645264 665.332336,1086.998535 
	C666.050476,1086.937988 666.461121,1086.907471 667.205322,1086.882324 
	C668.032898,1086.265625 668.526978,1085.643677 669.341309,1084.848755 
	C676.732300,1081.409668 683.803040,1078.143677 691.231567,1074.889282 
	C692.733032,1073.942017 693.876831,1072.983276 695.187866,1071.784668 
	C695.903809,1071.381836 696.452576,1071.218872 697.222168,1071.308228 
	C698.072571,1071.619507 698.835327,1071.940308 699.311707,1071.698120 
	C707.065063,1067.755249 714.804138,1063.782471 722.481506,1059.694580 
	C723.510254,1059.146851 724.186096,1057.936279 725.193115,1056.793579 
	C725.906921,1056.383789 726.452942,1056.213013 727.204590,1056.281860 
	C729.281067,1055.355713 731.151855,1054.189819 733.368103,1052.965820 
	C740.546204,1049.399902 747.330811,1045.793701 754.228149,1042.418213 
	C759.025818,1040.070190 763.978455,1038.038818 769.210815,1035.882324 
	C770.394470,1034.967529 771.229187,1034.036377 772.418335,1033.033936 
	C776.799866,1031.259888 780.826904,1029.557373 785.225525,1027.870483 
	C789.083923,1025.622314 792.570679,1023.358398 796.413818,1021.028198 
	C803.155396,1017.954895 809.540710,1014.947937 816.251587,1011.926514 
	C817.069153,1011.302551 817.560974,1010.693054 818.387756,1010.026611 
	C819.793396,1009.623718 820.864014,1009.277771 822.306641,1008.949402 
	C824.126770,1007.986755 825.574829,1007.006592 827.331726,1005.999756 
	C828.052673,1005.943115 828.464844,1005.912964 829.249512,1005.889404 
	C833.288940,1004.158264 837.040955,1002.575745 840.592102,1000.627197 
	C842.886047,999.368530 844.885803,997.573669 847.184570,995.777832 
	C847.900208,995.365051 848.448853,995.193420 849.203796,995.264404 
	C851.947754,994.013489 854.485352,992.519897 857.330322,990.996948 
	C858.047913,990.936035 858.458252,990.904358 859.201843,990.880188 
	C860.031982,990.268494 860.528870,989.649292 861.331055,989.001648 
	C862.043274,988.933838 862.450134,988.894531 863.190918,988.864624 
	C864.025513,988.259949 864.526245,987.645813 865.390869,986.998535 
	C872.657104,983.338318 879.578369,979.746887 886.454712,976.071350 
	C890.681763,973.811890 894.839905,971.423584 899.384521,969.034607 
	C901.112122,968.603455 902.484192,968.230774 904.225342,967.867981 
	C918.515137,961.015747 932.444641,954.171082 946.332703,947.243164 
	C947.076416,946.872131 947.470642,945.800293 948.333496,945.015381 
	C949.048340,944.941101 949.457214,944.906250 950.234802,944.879333 
	C961.288391,939.778381 971.986877,934.697327 982.627136,929.497070 
	C983.324829,929.156128 983.577026,927.903748 984.267700,927.043213 
	C984.498901,927.011108 984.963135,926.961121 985.348755,926.972351 
	C987.465515,926.459473 989.317261,926.175659 990.909912,925.375732 
	C1021.461914,910.031311 1051.908813,894.474121 1082.604004,879.422852 
	C1086.515137,877.505127 1089.389648,875.145325 1092.361328,872.002197 
	C1099.478760,869.438782 1102.999634,864.496399 1102.999634,857.571655 
	C1103.000000,718.348755 1102.990845,579.125916 1103.040283,439.903015 
	C1103.041992,435.283081 1103.480835,430.645599 1104.002563,426.048859 
	C1104.460327,422.017181 1105.321655,418.031342 1106.293945,414.306305 
	C1106.973877,418.063721 1107.364746,421.540131 1107.755615,425.016510 
	C1107.801025,426.347015 1107.886108,427.677490 1107.886108,429.007996 
	C1107.890259,575.231201 1107.870728,721.454407 1107.957642,867.677551 
	C1107.960693,872.820374 1107.035767,876.903320 1101.212280,879.015442 
	C1099.985718,879.519653 1099.399048,879.983032 1098.812378,880.446411 
	C1091.809082,883.918762 1084.805908,887.391174 1077.181152,890.952393 
	C1075.973999,891.494019 1075.388306,891.946777 1074.802612,892.399536 
	C1073.816040,892.932739 1072.829346,893.465942 1071.219360,894.026489 
	C1069.996582,894.481384 1069.397339,894.908813 1068.798096,895.336304 
	C1058.476685,900.541199 1048.155273,905.745972 1037.198242,910.993164 
	C1035.973755,911.474060 1035.384888,911.912598 1034.796021,912.351074 
	C1027.816406,915.891968 1020.836792,919.432983 1013.217468,923.014832 
	C1011.988892,923.520813 1011.400085,923.985962 1010.811218,924.451050 
	C1010.458191,924.634766 1010.105164,924.818542 1009.162842,925.030945 
	C1007.984375,925.506897 1007.395264,925.954163 1006.806152,926.401367 
	C999.836426,929.937195 992.866760,933.473022 985.253723,937.041870 
	C984.011108,937.534424 983.411865,937.993958 982.812622,938.453491 
	C981.833374,938.976562 980.854187,939.499634 979.254333,940.052490 
	C978.025146,940.517151 977.416565,940.952026 976.807983,941.386963 
	C975.805054,941.891724 974.802063,942.396484 973.184814,942.972290 
	C971.981018,943.495667 971.391541,943.948059 970.802063,944.400513 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M179.747314,407.761658 
	C182.894577,405.703125 185.876007,403.313110 189.216949,401.641724 
	C206.539642,392.975861 223.973907,384.533386 241.330521,375.934814 
	C255.873779,368.730011 270.341431,361.372559 284.883392,354.165039 
	C300.001434,346.671967 315.195709,339.332611 330.307373,331.826874 
	C350.979248,321.559448 371.638245,311.264801 392.237274,300.852417 
	C404.600586,294.602997 416.786713,288.003021 429.151825,281.757324 
	C437.822083,277.377930 446.719360,273.449158 455.406464,269.101685 
	C464.073151,264.764435 472.555542,260.059570 481.209015,255.694824 
	C489.333862,251.596756 497.626526,247.831741 505.756836,243.744125 
	C520.970825,236.095154 536.078186,228.233582 551.310303,220.621353 
	C564.456116,214.051743 577.746338,207.771606 590.913208,201.243408 
	C606.322327,193.603500 621.615112,185.726837 637.078369,178.199432 
	C640.680908,176.445740 644.460815,176.427292 648.384766,178.809128 
	C653.600220,181.974869 659.338379,184.273010 664.820312,187.007370 
	C677.404602,193.284424 689.950928,199.637894 702.546570,205.891968 
	C729.687500,219.368103 756.860229,232.780060 783.999573,246.259354 
	C801.442078,254.922485 818.871216,263.613190 836.264954,272.373627 
	C858.380249,283.512146 880.430176,294.780304 902.551941,305.905823 
	C923.097595,316.238678 943.680054,326.498962 964.284851,336.713257 
	C980.254883,344.629944 996.325256,352.344696 1012.274414,360.302704 
	C1024.534546,366.420013 1036.622070,372.883179 1048.881958,379.001068 
	C1066.899292,387.992004 1085.005127,396.805206 1103.054321,405.732300 
	C1103.739624,406.071259 1104.227783,406.808990 1104.459839,407.665955 
	C1099.240479,406.649231 1094.151001,405.842743 1089.544434,403.894501 
	C1080.906128,400.241119 1072.547363,395.927063 1064.018433,391.608459 
	C1063.310059,391.197021 1062.655518,391.064911 1061.671387,390.905731 
	C1060.237183,390.240356 1059.132324,389.602081 1057.973389,388.628876 
	C1056.607544,387.534882 1055.295776,386.775848 1053.621338,386.006622 
	C1052.181763,385.652069 1051.105103,385.307739 1049.982178,384.610535 
	C1047.946655,383.184174 1045.957275,382.110657 1043.608521,380.996155 
	C1029.508179,374.292023 1015.767212,367.628937 1001.966187,360.619995 
	C1000.276306,359.509857 998.646484,358.745544 996.658630,357.936890 
	C984.424561,351.733307 972.594788,345.482208 960.651489,339.456085 
	C955.231567,336.721405 949.586670,334.432617 944.001953,331.655518 
	C943.307007,331.224243 942.653992,331.079010 941.678345,330.907440 
	C940.252686,330.233032 939.149536,329.584900 937.983398,328.604919 
	C936.598511,327.534149 935.276550,326.795227 933.589050,326.006348 
	C930.160217,324.623199 927.097046,323.290039 923.972778,321.616150 
	C921.258484,319.870300 918.605347,318.465149 915.629639,316.904358 
	C910.221497,314.475769 905.135864,312.202728 899.984131,309.599243 
	C898.602295,308.523621 897.286560,307.778412 895.595093,307.019348 
	C886.163086,302.646454 877.106812,298.287476 867.981506,293.592102 
	C866.946472,292.831451 865.980591,292.407196 864.658569,291.944366 
	C861.549072,290.585236 858.795654,289.264709 855.987488,287.627319 
	C855.278137,286.885284 854.623657,286.460052 853.645752,286.025757 
	C852.891418,285.998901 852.460510,285.981140 851.973267,285.628052 
	C850.592957,284.552368 849.268921,283.812073 847.625977,283.054504 
	C846.882202,283.011078 846.457214,282.984924 845.971191,282.618347 
	C844.943848,281.847473 843.977600,281.416962 842.653687,280.945984 
	C840.254089,279.845215 838.253174,278.694824 836.162537,277.741394 
	C829.760559,274.821747 823.324158,271.977692 816.603516,268.948456 
	C814.881348,268.159363 813.456726,267.526031 812.020508,266.529175 
	C811.529663,265.385651 811.234070,264.211121 810.541687,263.888733 
	C805.405457,261.497314 800.190308,259.275238 794.679321,256.860535 
	C782.242188,250.809448 770.125793,244.897858 757.977783,238.662964 
	C756.963379,237.898163 755.980530,237.456680 754.637939,236.971634 
	C748.520447,233.949646 742.762695,230.971222 736.977173,227.699326 
	C736.300293,227.266418 735.651123,227.126968 734.704834,226.966461 
	C733.940063,226.627930 733.472473,226.310455 732.971558,225.649185 
	C731.626160,224.866043 730.314087,224.426682 728.704834,223.966553 
	C727.940552,223.627487 727.473572,223.309204 726.948669,222.637375 
	C724.930359,221.519608 722.969849,220.755402 720.691162,219.962326 
	C719.597290,219.595932 718.821716,219.258423 717.994751,218.579926 
	C716.004578,216.354034 714.799011,212.884872 710.700073,214.996765 
	C709.932007,214.650131 709.461426,214.330872 708.967957,213.715851 
	C708.296692,213.281723 707.648376,213.143372 706.642334,212.963470 
	C703.187073,211.284912 700.089478,209.647919 696.942139,207.656509 
	C694.258362,206.204697 691.624329,205.107315 688.747681,204.000946 
	C688.504944,203.991974 688.019714,203.969818 687.971558,203.605835 
	C686.277893,202.500504 684.632507,201.759125 682.992310,201.009186 
	C682.997559,201.000610 682.982422,201.018799 682.940063,200.670776 
	C680.131042,199.531921 679.026855,194.794968 674.681885,196.969727 
	C673.586304,196.612610 672.808777,196.281311 671.982178,195.594696 
	C668.955811,193.825851 665.978577,192.412323 662.757446,190.989792 
	C662.513611,190.980804 662.025940,190.959229 661.975037,190.596619 
	C660.282776,189.489334 658.641357,188.744675 656.972778,187.706116 
	C656.297058,187.273163 655.648560,187.134079 654.681885,186.966766 
	C653.579224,186.620544 652.794739,186.302551 651.965820,185.659912 
	C650.947571,185.225571 649.973755,185.115875 648.578979,185.004593 
	C646.103943,185.377441 644.049927,185.751892 641.624817,186.108704 
	C640.511353,186.106628 639.768982,186.122192 638.840820,185.845032 
	C637.770081,185.370010 636.885010,185.187714 635.804077,184.747543 
	C634.734253,184.986145 633.860413,185.482620 632.692871,186.106476 
	C631.598755,186.484543 630.798279,186.735245 629.799316,186.730499 
	C628.735657,186.985504 627.870422,187.495956 627.002563,188.003204 
	C627.000000,188.000000 627.005005,188.005600 626.650757,188.004425 
	C625.524536,188.658585 624.752502,189.313919 623.659241,190.117706 
	C619.224609,192.171585 615.111084,194.077011 610.798340,195.735687 
	C608.734985,196.662415 606.871033,197.835922 604.712524,199.039886 
	C603.946655,199.381546 603.475403,199.692764 602.649414,200.003693 
	C601.528198,200.666351 600.761597,201.329285 599.675903,202.007599 
	C598.931396,202.041840 598.505981,202.060699 597.749512,202.088623 
	C596.944824,202.730438 596.471191,203.363205 595.632568,204.014267 
	C593.536926,204.713730 591.806396,205.394897 589.744934,206.070694 
	C588.614380,206.719223 587.814758,207.373123 586.782349,208.046478 
	C586.549622,208.065933 586.082764,208.082275 585.751648,208.091125 
	C584.946228,208.732544 584.471924,209.365112 583.755432,210.008743 
	C583.513245,210.019806 583.028564,210.031357 582.671143,210.021271 
	C581.541260,210.672379 580.768799,211.333557 579.754944,212.006927 
	C579.513550,212.019135 579.030396,212.033600 578.672974,212.022827 
	C577.541626,212.671631 576.767700,213.331238 575.674072,214.005875 
	C574.928162,214.039734 574.501892,214.058548 573.701172,214.053009 
	C571.887756,215.023300 570.448792,216.017944 568.796143,217.270294 
	C567.394287,218.023285 566.206055,218.518600 564.696289,219.043793 
	C563.904236,219.695419 563.433655,220.317169 562.637268,220.956451 
	C561.876587,220.984924 561.441650,220.995850 560.651245,221.003983 
	C559.530518,221.667191 558.765259,222.333191 557.830933,223.241730 
	C557.107056,223.647751 556.552246,223.811234 555.794312,223.726318 
	C552.392761,225.316650 549.194214,227.155396 545.758301,229.008820 
	C545.520935,229.023529 545.045654,229.042755 544.673523,229.029602 
	C542.870605,230.014801 541.439758,231.013168 539.795776,232.273987 
	C537.721008,233.352661 535.859375,234.168869 533.826660,234.755417 
	C533.106140,235.020126 532.556702,235.514496 531.835999,236.249680 
	C531.108215,236.649734 530.551758,236.808975 529.785706,236.721771 
	C527.714478,237.646149 525.852783,238.816971 523.821960,240.229752 
	C523.101074,240.642105 522.549377,240.812500 521.791382,240.722885 
	C520.387207,241.304733 519.189392,242.146591 517.771362,243.255585 
	C514.700928,244.683212 511.850830,245.843719 508.817017,246.759445 
	C507.746979,247.330261 506.860626,248.145828 505.753296,249.231903 
	C503.362305,250.345901 501.192322,251.189377 498.642761,252.024475 
	C490.628967,255.714462 482.915161,259.260010 475.394745,263.176819 
	C471.733795,265.083527 468.434082,267.683838 464.648438,269.984436 
	C463.896210,270.018036 463.466370,270.037354 462.670288,270.039642 
	C461.190735,271.002869 460.077393,271.983124 458.642944,272.978302 
	C457.893738,273.014465 457.465668,273.035675 456.672180,273.069763 
	C447.893524,277.157928 439.410156,281.097015 431.099823,285.372375 
	C427.533752,287.207001 424.327118,289.740326 420.635590,291.970520 
	C419.884430,292.002747 419.454865,292.020630 418.660187,292.059387 
	C413.179626,295.034943 408.064117,297.989563 402.714539,300.964111 
	C402.480469,300.983978 402.011871,301.018097 401.634094,301.012573 
	C398.488678,302.654968 395.721039,304.302887 392.720398,305.969147 
	C392.487396,305.987488 392.021851,306.029175 391.647491,306.054321 
	C387.804047,307.982300 382.795471,307.192078 380.647644,311.983643 
	C379.896271,312.018005 379.466949,312.038177 378.661743,312.041107 
	C373.179718,314.247192 368.023834,316.367310 363.010620,318.783478 
	C362.042633,319.250000 361.632843,320.874786 360.644714,321.980225 
	C359.896240,322.017822 359.468628,322.040466 358.677307,322.074005 
	C355.859650,323.706146 353.405701,325.327362 350.633423,326.963409 
	C349.891815,327.007568 349.468536,327.036926 348.668579,327.051941 
	C347.797394,327.091644 347.236237,327.011444 346.817841,327.219025 
	C330.559326,335.284851 314.265747,343.282318 298.082611,351.497223 
	C286.333344,357.461426 274.814514,363.880920 263.045105,369.803619 
	C247.735275,377.507904 232.277084,384.917206 216.886337,392.460815 
	C204.837936,398.366180 193.277084,405.452759 179.747314,407.761658 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M856.042297,287.944183 
	C858.795654,289.264709 861.549072,290.585236 864.793457,292.240265 
	C866.206482,293.026001 867.128540,293.477234 868.050598,293.928467 
	C877.106812,298.287476 886.163086,302.646454 895.650696,307.354492 
	C897.404785,308.445587 898.727539,309.187653 900.050232,309.929718 
	C905.135864,312.202728 910.221497,314.475769 915.698242,317.240662 
	C918.737549,319.140625 921.385681,320.548737 924.033813,321.956848 
	C927.097046,323.290039 930.160217,324.623199 933.654053,326.337280 
	C935.405273,327.457703 936.725830,328.197235 938.046387,328.936798 
	C939.149536,329.584900 940.252686,330.233032 941.839417,331.169373 
	C942.896606,331.618896 943.470215,331.780243 944.043823,331.941559 
	C949.586670,334.432617 955.231567,336.721405 960.651489,339.456085 
	C972.594788,345.482208 984.424561,351.733307 996.800598,358.242920 
	C998.875732,359.384125 1000.450989,360.174988 1002.026184,360.965820 
	C1015.767212,367.628937 1029.508179,374.292023 1043.669434,381.333313 
	C1046.069214,382.795441 1048.048828,383.879425 1050.028320,384.963409 
	C1051.105103,385.307739 1052.181763,385.652069 1053.671387,386.343140 
	C1055.398804,387.447876 1056.713135,388.205841 1058.027588,388.963806 
	C1059.132324,389.602081 1060.237183,390.240356 1061.835205,391.169006 
	C1062.909790,391.602142 1063.491089,391.744965 1064.072266,391.887787 
	C1072.547363,395.927063 1080.906128,400.241119 1089.544434,403.894501 
	C1094.151001,405.842743 1099.240479,406.649231 1104.514404,408.009827 
	C1105.982422,410.330231 1107.565430,412.521698 1107.982300,414.916260 
	C1108.501709,417.898804 1108.058960,421.048889 1107.893555,424.571564 
	C1107.364746,421.540131 1106.973877,418.063721 1105.932495,414.319031 
	C1100.992554,417.217194 1097.926514,414.602692 1094.536743,412.098175 
	C1090.540039,409.145325 1086.191284,406.669312 1081.701172,403.955811 
	C1081.013306,403.908569 1080.619507,403.892090 1080.111572,403.550415 
	C1077.996582,402.142639 1075.995850,401.060120 1073.699829,399.950775 
	C1073.009399,399.910217 1072.614380,399.896454 1072.120972,399.573792 
	C1071.345215,398.841248 1070.667725,398.417603 1069.691895,397.844910 
	C1068.333618,397.426117 1067.273682,397.156342 1066.116211,396.577393 
	C1065.342529,395.844238 1064.666382,395.420288 1063.696533,394.966095 
	C1063.009521,394.917175 1062.616333,394.898499 1062.116333,394.551392 
	C1060.004639,393.141357 1057.999878,392.059814 1055.702393,390.949646 
	C1055.018555,390.904114 1054.627197,390.887115 1054.132080,390.564087 
	C1053.350708,389.832825 1052.672852,389.407562 1051.698975,388.951538 
	C1051.005859,388.909058 1050.609009,388.897369 1050.102295,388.556274 
	C1047.325073,386.819733 1044.657593,385.412598 1041.669434,383.844482 
	C1038.970459,382.750732 1036.592407,381.817963 1034.105591,380.550842 
	C1031.677612,379.101837 1029.358276,377.987274 1026.841553,376.629333 
	C1025.835938,376.217499 1025.027954,376.048981 1024.107056,375.552216 
	C1021.327026,373.813049 1018.659851,372.402161 1015.653198,370.933350 
	C1013.611389,370.213745 1011.909119,369.552094 1010.102661,368.561554 
	C1008.002686,367.143188 1006.006714,366.053711 1003.845825,364.718933 
	C1003.127075,364.306946 1002.573242,364.140289 1001.848816,363.727905 
	C1001.117615,363.312164 1000.556946,363.142151 999.669800,362.921997 
	C998.298523,362.543457 997.253784,362.215057 996.101685,361.564056 
	C994.660889,360.490051 993.327332,359.738617 991.635986,358.922363 
	C985.237000,355.875824 979.195923,352.894073 972.978638,349.637207 
	C971.866760,348.899902 970.931091,348.437683 969.693970,347.813293 
	C965.006348,345.724396 960.620178,343.797729 956.128235,341.565521 
	C955.346863,340.831024 954.671204,340.402039 953.709473,339.821167 
	C952.292175,339.093475 951.161072,338.517731 949.812378,337.686340 
	C948.725342,337.255981 947.855774,337.081238 946.800537,336.876526 
	C946.614807,336.846558 946.239075,336.866608 946.126160,336.545593 
	C944.673523,335.476440 943.333740,334.728333 941.639771,333.918152 
	C908.270508,317.526459 875.255493,301.196838 842.134705,284.561218 
	C841.352051,283.824158 840.675171,283.393066 839.702271,282.936401 
	C839.010254,282.901306 838.614319,282.891785 838.118774,282.575653 
	C837.353821,281.825775 836.688354,281.382477 835.800781,280.679932 
	C833.424438,279.593811 831.270386,278.766907 828.940186,277.643860 
	C826.508789,276.218201 824.253601,275.088654 821.699829,273.814301 
	C819.975159,273.094696 818.549011,272.519928 817.087158,271.590729 
	C817.001343,270.525635 816.951233,269.814911 816.901123,269.104218 
	C823.324158,271.977692 829.760559,274.821747 836.162537,277.741394 
	C838.253174,278.694824 840.254089,279.845215 842.789673,281.239868 
	C844.199646,282.035736 845.115967,282.497223 846.032288,282.958740 
	C846.457214,282.984924 846.882202,283.011078 847.694214,283.383179 
	C849.397400,284.473877 850.713440,285.218628 852.029541,285.963379 
	C852.460510,285.981140 852.891418,285.998901 853.696167,286.344666 
	C854.727417,287.096527 855.384888,287.520355 856.042297,287.944183 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M176.382141,414.964264 
	C176.837097,416.412842 177.081329,418.062805 177.081528,419.712738 
	C177.098969,567.912842 177.097946,716.112976 177.092590,864.313049 
	C177.092545,865.643616 177.017242,866.974182 176.832794,868.777222 
	C176.229309,867.428345 175.368774,865.607117 175.368011,863.785522 
	C175.306030,716.014832 175.308456,568.244141 175.342102,420.473450 
	C175.342499,418.704102 175.808716,416.934906 176.382141,414.964264 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M179.437454,407.853577 
	C193.277084,405.452759 204.837936,398.366180 216.886337,392.460815 
	C232.277084,384.917206 247.735275,377.507904 263.045105,369.803619 
	C274.814514,363.880920 286.333344,357.461426 298.082611,351.497223 
	C314.265747,343.282318 330.559326,335.284851 346.817841,327.219025 
	C347.236237,327.011444 347.797394,327.091644 348.657898,327.404358 
	C348.995270,328.476105 348.966736,329.181061 348.862366,330.231812 
	C346.860840,331.395416 344.935181,332.213226 342.679138,333.055786 
	C341.860413,333.718628 341.372070,334.356720 340.664246,335.208984 
	C339.644043,335.566925 338.843414,335.710632 337.832184,335.675934 
	C336.683533,336.303101 335.745453,337.108673 334.606201,337.894714 
	C334.404999,337.875244 334.003967,337.926331 333.677612,337.965332 
	C332.798981,338.622620 332.246704,339.240906 331.356598,339.898071 
	C329.347931,340.611176 327.677063,341.285461 325.678223,341.992889 
	C324.804840,342.640869 324.259491,343.255676 323.374908,343.907715 
	C321.359924,344.624084 319.684174,345.303253 317.680603,346.009705 
	C316.812897,346.651306 316.272980,347.265564 315.548828,347.858185 
	C315.364594,347.836548 314.998169,347.894501 314.666077,347.932312 
	C313.773407,348.592651 313.212769,349.215118 312.284058,349.832214 
	C311.103394,349.966766 310.186981,349.910492 309.494263,350.276978 
	C302.660767,353.892487 295.951569,357.752350 289.040161,361.209137 
	C272.406921,369.528442 255.683853,377.668213 238.665192,385.907684 
	C237.467087,386.578308 236.600815,387.221680 235.385101,387.903412 
	C223.690155,393.596436 212.344650,399.251129 200.699188,404.925232 
	C199.906357,405.245667 199.413483,405.546722 198.630890,405.880585 
	C197.880539,406.228668 197.419907,406.543945 196.612885,406.909058 
	C195.221695,407.712036 193.949448,408.282043 193.170395,409.249298 
	C189.218262,414.156158 184.688293,417.291595 178.045593,414.898926 
	C177.893799,413.779907 177.742004,412.660889 177.334808,411.320129 
	C177.762146,410.047424 178.444870,408.996460 179.437454,407.853577 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M963.134644,948.540039 
	C964.283508,947.587097 965.758911,946.750244 967.582214,945.935547 
	C966.440430,946.857178 964.950806,947.756653 963.134644,948.540039 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M983.111084,938.568787 
	C983.411865,937.993958 984.011108,937.534424 984.916870,937.141357 
	C984.618835,937.699951 984.014160,938.192017 983.111084,938.568787 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M1007.099121,926.530396 
	C1007.395264,925.954163 1007.984375,925.506897 1008.872925,925.106812 
	C1008.578918,925.655823 1007.985535,926.157593 1007.099121,926.530396 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M1011.110718,924.555786 
	C1011.400085,923.985962 1011.988892,923.520813 1012.882324,923.113892 
	C1012.594666,923.668274 1012.002441,924.164429 1011.110718,924.555786 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M977.100830,941.510620 
	C977.416565,940.952026 978.025146,940.517151 978.935303,940.149536 
	C978.622437,940.689270 978.007996,941.161804 977.100830,941.510620 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M971.098267,944.518799 
	C971.391541,943.948059 971.981018,943.495667 972.871948,943.100098 
	C972.580444,943.650330 971.987488,944.143738 971.098267,944.518799 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M1099.110352,880.564209 
	C1099.399048,879.983032 1099.985718,879.519653 1100.876221,879.109009 
	C1100.589478,879.668457 1099.998901,880.175171 1099.110352,880.564209 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M1069.088379,895.468750 
	C1069.397339,894.908813 1069.996582,894.481384 1070.893311,894.112305 
	C1070.586670,894.647522 1069.982666,895.124390 1069.088379,895.468750 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M1035.087646,912.483887 
	C1035.384888,911.912598 1035.973755,911.474060 1036.859863,911.088684 
	C1036.564575,911.633423 1035.971924,912.125061 1035.087646,912.483887 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M1075.098633,892.524170 
	C1075.388306,891.946777 1075.973999,891.494019 1076.860352,891.092896 
	C1076.572144,891.645996 1075.983398,892.147400 1075.098633,892.524170 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1081.995117,403.986572 
	C1086.191284,406.669312 1090.540039,409.145325 1094.536743,412.098175 
	C1097.926514,414.602692 1100.992554,417.217194 1105.643433,414.038025 
	C1105.321655,418.031342 1104.460327,422.017181 1104.002563,426.048859 
	C1103.480835,430.645599 1103.041992,435.283081 1103.040283,439.903015 
	C1102.990845,579.125916 1103.000000,718.348755 1102.999634,857.571655 
	C1102.999634,864.496399 1099.478760,869.438782 1092.505981,871.699097 
	C1094.071167,869.473633 1096.088501,867.710083 1097.595215,865.584595 
	C1100.500244,861.486267 1099.104004,858.481873 1094.323120,857.754272 
	C1093.808838,857.675964 1093.443970,856.615601 1093.176025,855.916931 
	C1093.223267,855.549316 1093.104370,855.275635 1092.679688,855.004883 
	C1092.170410,855.019653 1091.966919,855.031372 1091.704712,854.573547 
	C1091.635864,714.232361 1091.625366,574.360718 1091.626343,434.489136 
	C1091.626343,433.330750 1091.781372,432.172394 1092.263428,431.010895 
	C1098.747925,430.019379 1101.194214,425.519073 1097.973755,420.247467 
	C1096.047119,417.093872 1093.191162,414.464752 1090.546753,411.804871 
	C1087.826782,409.068970 1084.856079,406.582306 1081.995117,403.986572 
z"/>
<path fill="#F65F60" opacity="1.000000" stroke="none" 
	d="
M178.027710,415.354553 
	C184.688293,417.291595 189.218262,414.156158 193.170395,409.249298 
	C193.949448,408.282043 195.221695,407.712036 196.599609,407.269440 
	C193.431458,411.747101 189.889999,415.881256 186.443451,420.093079 
	C184.244217,422.780670 184.007126,425.820587 186.721420,428.070251 
	C189.863724,430.674652 190.041046,433.830933 190.039780,437.446594 
	C189.991974,574.069397 189.999329,710.692261 190.000107,847.315063 
	C190.000122,848.814514 189.689758,850.417480 190.128372,851.777283 
	C190.424301,852.694702 191.783630,853.269165 192.752136,854.273315 
	C192.781036,854.733887 192.725098,854.918213 192.493225,855.286987 
	C192.193542,855.962952 192.069794,856.454529 191.706955,856.962097 
	C191.467880,856.978088 190.989120,857.000000 190.620255,856.961914 
	C188.040436,856.667419 184.390945,855.503906 183.877609,856.317993 
	C182.050293,859.215759 182.537170,862.466309 185.453033,865.003479 
	C186.786133,866.163452 187.825729,867.660645 188.998444,869.004883 
	C183.717361,867.874695 179.192429,862.725342 179.008957,857.601501 
	C178.961288,856.270142 179.000656,854.935547 179.000656,853.602478 
	C179.000595,712.627075 179.010681,571.651672 178.958878,430.676331 
	C178.957062,425.720825 178.340088,420.765564 178.027710,415.354553 
z"/>
<path fill="#FEBEBD" opacity="1.000000" stroke="none" 
	d="
M640.991699,1095.037598 
	C639.627869,1094.983398 638.264099,1094.929199 636.498535,1094.635620 
	C636.096741,1094.396240 636.052734,1093.947144 636.003357,1093.592285 
	C631.279480,1090.858765 626.604980,1088.479980 621.619995,1086.073975 
	C620.895874,1086.008057 620.482178,1085.969116 620.007141,1085.589600 
	C617.270203,1083.872925 614.594543,1082.497070 611.611633,1081.091919 
	C610.895630,1081.014893 610.486816,1080.967163 610.015747,1080.579956 
	C602.334778,1076.119629 594.782104,1071.868896 587.062988,1067.945801 
	C584.232422,1066.507202 581.007263,1065.844727 577.680603,1064.827148 
	C576.951782,1064.532227 576.504578,1064.238281 576.002930,1063.600342 
	C575.069153,1062.545776 574.281860,1061.655029 573.296509,1061.151855 
	C561.361023,1055.056519 549.436096,1048.937744 537.421936,1043.000122 
	C531.691345,1040.167969 525.776672,1037.708252 519.640991,1035.038330 
	C518.928833,1034.955811 518.520935,1034.914795 518.034424,1034.539795 
	C510.624084,1030.488525 503.292511,1026.770996 495.966522,1023.042847 
	C495.972107,1023.032166 495.958710,1023.041443 495.828705,1022.717529 
	C494.450806,1021.950439 493.202881,1021.507324 491.644775,1021.033386 
	C490.921356,1020.966675 490.508118,1020.930786 490.026489,1020.553223 
	C489.218201,1019.587524 488.566010,1018.790344 487.725647,1018.364807 
	C470.287292,1009.536316 452.857391,1000.690125 435.355011,991.989807 
	C429.650360,989.154114 423.745728,986.720581 417.622742,984.071899 
	C416.899048,984.002869 416.485107,983.965149 416.009338,983.585205 
	C410.304077,980.487244 404.660706,977.731567 398.787842,974.951538 
	C398.558350,974.927185 398.097931,974.895142 398.033264,974.578430 
	C397.290924,973.871521 396.613251,973.481323 395.705017,973.066895 
	C395.474487,973.042664 395.014404,972.985840 394.965820,972.635254 
	C390.003510,969.612244 385.096619,966.926880 380.152954,964.310791 
	C379.937408,964.196716 379.385101,964.719055 378.679199,964.909363 
	C377.601685,964.559265 376.834656,964.244751 376.010315,963.584961 
	C374.695099,962.219666 373.572998,960.926147 372.157593,960.223328 
	C361.667206,955.014587 351.133972,949.891113 340.572876,944.827148 
	C338.827423,943.990173 336.879822,943.574890 334.795227,942.940796 
	C334.564850,942.916748 334.102478,942.887878 334.023346,942.545410 
	C331.314697,941.113647 328.685181,940.024353 326.045959,938.937195 
	C326.036285,938.939270 326.064423,938.919128 326.004211,938.576782 
	C320.715973,935.220093 315.577789,932.032593 310.226532,929.255737 
	C306.957031,927.559143 303.359985,926.493774 299.593933,925.098145 
	C298.853455,925.007385 298.429108,924.963257 297.960693,924.579529 
	C297.351685,923.674500 296.883606,922.921997 296.207184,922.571777 
	C285.715210,917.138794 275.202179,911.746216 264.658539,906.414429 
	C264.294098,906.230164 263.561188,906.774536 262.674316,906.930786 
	C261.240051,906.247070 260.131470,905.611877 258.971375,904.627808 
	C257.927185,903.880737 256.934448,903.482605 255.637024,903.045532 
	C254.924942,902.964600 254.517578,902.922485 254.043045,902.531006 
	C251.658646,901.110901 249.341446,900.040161 247.014069,898.983521 
	C247.003906,898.997559 247.028946,898.966553 246.974228,898.620056 
	C244.596268,897.205566 242.273026,896.137573 239.787979,894.828125 
	C239.080338,894.432251 238.534500,894.277832 237.845795,893.841736 
	C237.118439,893.396423 236.533951,893.232910 235.787445,892.827881 
	C235.080124,892.429871 234.534821,892.273315 233.844818,891.835815 
	C233.113022,891.398743 232.525894,891.242554 231.778564,890.844177 
	C231.077087,890.436157 230.535828,890.270325 229.823364,889.803955 
	C227.751450,888.691772 225.850739,887.880127 223.642731,887.030579 
	C222.924072,886.958557 222.512711,886.924500 222.035461,886.576843 
	C221.299820,885.859802 220.630051,885.456238 219.650513,885.019165 
	C218.926468,884.954529 218.512222,884.923401 218.022522,884.556458 
	C216.970779,883.804810 215.994476,883.389038 214.790085,882.948608 
	C214.561996,882.923950 214.105362,882.878967 214.032578,882.539307 
	C208.427917,878.366089 202.910156,874.511719 197.345078,870.726868 
	C196.698059,870.286743 195.788895,870.232117 194.833145,869.702881 
	C194.197693,868.219482 193.730347,867.031982 193.588943,865.929932 
	C198.997543,868.753906 204.022873,871.606018 209.174240,874.208679 
	C221.698059,880.536499 234.303070,886.703430 246.832672,893.019897 
	C258.494293,898.898865 270.044952,904.999756 281.746643,910.796692 
	C294.201813,916.966919 306.860657,922.728394 319.279205,928.969421 
	C335.864838,937.304688 352.216156,946.107666 368.828186,954.388611 
	C392.849030,966.362854 417.058777,977.957703 441.102570,989.886475 
	C461.297546,999.905823 481.372589,1010.166870 501.496826,1020.328674 
	C522.383911,1030.875610 543.253784,1041.456909 564.154175,1051.977295 
	C588.781433,1064.373657 613.466431,1076.655884 638.033142,1089.170776 
	C641.111511,1090.738892 643.453613,1090.610962 646.316711,1089.005981 
	C651.367554,1086.174438 656.561768,1083.592407 661.746094,1081.006348 
	C678.033081,1072.881958 694.374146,1064.865601 710.636108,1056.691650 
	C726.305481,1048.815674 741.866272,1040.723022 757.543945,1032.863770 
	C768.477234,1027.382935 779.580444,1022.240967 790.512695,1016.757996 
	C807.061829,1008.458130 823.482361,999.901550 840.042908,991.624756 
	C855.729309,983.784973 871.560974,976.236206 887.263611,968.428467 
	C902.805115,960.700867 918.261658,952.802490 933.762329,944.992676 
	C947.789734,937.925110 961.825439,930.874268 975.859192,923.819397 
	C986.192810,918.624756 996.513794,913.404663 1006.868103,908.251404 
	C1021.657227,900.890747 1036.501587,893.640564 1051.266235,886.231506 
	C1064.550903,879.565125 1077.760010,872.748108 1091.237427,866.175537 
	C1090.004028,867.921204 1088.535645,869.489014 1086.703979,871.066650 
	C1082.872314,874.014099 1079.404053,876.951721 1075.634766,880.048767 
	C1074.256348,880.504150 1073.178955,880.800049 1071.707275,881.061523 
	C1067.519165,883.312805 1063.725464,885.598511 1059.621582,887.920959 
	C1058.896118,887.990906 1058.481079,888.024109 1057.692383,888.064087 
	C1052.194214,891.016541 1047.069824,893.962219 1041.636230,896.939087 
	C1040.914673,897.006897 1040.502075,897.043457 1039.760498,897.080322 
	C1038.935547,897.689148 1038.439575,898.297607 1037.705322,898.929199 
	C1037.467041,898.952271 1036.990112,898.992554 1036.599609,898.982788 
	C1032.147461,900.997498 1028.085815,903.021912 1023.692261,905.093994 
	C1021.954895,905.807922 1020.549438,906.474060 1018.805176,907.121216 
	C1017.977112,907.733582 1017.487854,908.365051 1016.828003,909.236694 
	C1016.103943,909.638428 1015.550476,909.800049 1014.791138,909.711182 
	C1011.721741,911.023743 1008.858276,912.586731 1005.687988,914.165405 
	C1004.634155,914.498779 1003.887207,914.816589 1002.765869,915.112000 
	C998.569214,916.908936 994.650696,918.553406 990.948730,920.591370 
	C986.195312,923.208191 981.615967,926.141235 976.747192,929.210815 
	C972.733887,931.033203 968.934937,932.582825 964.776611,934.108521 
	C963.269897,935.043640 962.122559,936.002686 960.691284,937.100586 
	C959.621582,937.510681 958.835754,937.781982 957.663086,938.031128 
	C950.938599,941.035522 944.587097,944.033813 938.286072,947.134766 
	C937.694702,947.425903 937.421936,948.364441 936.780518,949.036133 
	C936.559814,949.069824 936.114929,949.108032 935.742310,949.096863 
	C926.877014,953.090637 918.317993,956.962830 909.921631,961.160339 
	C906.106140,963.067688 902.612366,965.618652 898.596313,967.911987 
	C884.237366,974.685852 870.186523,981.288208 856.290283,988.201355 
	C840.678467,995.968079 825.271484,1004.146851 809.655457,1011.904785 
	C793.182800,1020.088257 776.526794,1027.902222 760.028564,1036.034912 
	C745.327271,1043.281982 730.721924,1050.724243 716.092773,1058.116943 
	C702.987976,1064.739380 689.936218,1071.467163 676.808105,1078.042725 
	C667.829956,1082.539551 658.728149,1086.790161 649.764832,1091.315308 
	C648.264771,1092.072510 647.188721,1093.669556 645.916748,1094.878418 
	C645.493164,1094.920654 645.069580,1094.962769 644.154053,1094.751953 
	C642.771973,1094.678589 641.881836,1094.858154 640.991699,1095.037598 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M646.327087,1094.945190 
	C647.188721,1093.669556 648.264771,1092.072510 649.764832,1091.315308 
	C658.728149,1086.790161 667.829956,1082.539551 676.808105,1078.042725 
	C689.936218,1071.467163 702.987976,1064.739380 716.092773,1058.116943 
	C730.721924,1050.724243 745.327271,1043.281982 760.028564,1036.034912 
	C776.526794,1027.902222 793.182800,1020.088257 809.655457,1011.904785 
	C825.271484,1004.146851 840.678467,995.968079 856.290283,988.201355 
	C870.186523,981.288208 884.237366,974.685852 898.612244,968.216431 
	C899.012634,968.688660 899.020813,968.890808 899.028931,969.093018 
	C894.839905,971.423584 890.681763,973.811890 886.454712,976.071350 
	C879.578369,979.746887 872.657104,983.338318 865.071289,987.019714 
	C863.877563,987.667847 863.367371,988.261597 862.857056,988.855225 
	C862.450134,988.894531 862.043274,988.933838 861.010681,989.024048 
	C859.879517,989.674133 859.374023,990.273376 858.868530,990.872681 
	C858.458252,990.904358 858.047913,990.936035 856.971619,991.018555 
	C853.869568,992.386963 851.433533,993.704346 848.997437,995.021790 
	C848.448853,995.193420 847.900208,995.365051 846.820984,995.802002 
	C844.041504,997.112488 841.702087,998.001099 839.564026,999.237854 
	C835.934875,1001.337280 832.432617,1003.656189 828.876953,1005.882812 
	C828.464844,1005.912964 828.052673,1005.943115 826.988220,1006.016235 
	C824.868774,1007.016724 823.401672,1007.974243 821.934631,1008.931824 
	C820.864014,1009.277771 819.793396,1009.623718 818.063354,1010.038330 
	C816.911377,1010.718323 816.418640,1011.329651 815.925903,1011.940979 
	C809.540710,1014.947937 803.155396,1017.954895 796.047974,1021.033569 
	C791.835144,1023.355103 788.344543,1025.604980 784.853943,1027.854736 
	C780.826904,1029.557373 776.799866,1031.259888 772.075806,1033.031738 
	C770.539734,1034.022461 769.700745,1034.944214 768.861816,1035.865967 
	C763.978455,1038.038818 759.025818,1040.070190 754.228149,1042.418213 
	C747.330811,1045.793701 740.546204,1049.399902 733.016968,1052.985596 
	C730.546692,1054.056519 728.772827,1055.049316 726.998962,1056.042236 
	C726.452942,1056.213013 725.906921,1056.383789 724.828857,1056.813721 
	C715.198364,1061.733887 706.099854,1066.394897 697.001343,1071.055908 
	C696.452576,1071.218872 695.903809,1071.381836 694.840942,1071.805298 
	C693.175720,1073.002930 692.024719,1073.940186 690.873718,1074.877686 
	C683.803040,1078.143677 676.732300,1081.409668 669.021301,1084.874146 
	C667.877991,1085.673950 667.374878,1086.275391 666.871826,1086.876953 
	C666.461121,1086.907471 666.050476,1086.937988 665.013306,1087.022705 
	C663.877869,1087.671021 663.368835,1088.265015 662.859802,1088.859131 
	C659.813660,1090.206421 656.767578,1091.553833 653.051025,1092.985352 
	C651.874695,1093.667603 651.368713,1094.265503 650.862732,1094.863525 
	C649.487610,1094.913086 648.112549,1094.962524 646.327087,1094.945190 
z"/>
<path fill="#F27273" opacity="1.000000" stroke="none" 
	d="
M1058.065918,888.057373 
	C1058.481079,888.024109 1058.896118,887.990906 1059.989502,887.930908 
	C1064.479004,885.634827 1068.290283,883.365479 1072.101562,881.096069 
	C1073.178955,880.800049 1074.256348,880.504150 1075.996094,880.059204 
	C1080.128174,876.959106 1083.597778,874.007935 1087.067261,871.056763 
	C1088.535645,869.489014 1090.004028,867.921204 1091.274902,865.738403 
	C1091.306152,861.763245 1091.534912,858.403198 1091.763550,855.043152 
	C1091.966919,855.031372 1092.170410,855.019653 1092.684570,855.257141 
	C1093.000000,855.674561 1093.004883,855.842651 1093.009766,856.010803 
	C1093.443970,856.615601 1093.808838,857.675964 1094.323120,857.754272 
	C1099.104004,858.481873 1100.500244,861.486267 1097.595215,865.584595 
	C1096.088501,867.710083 1094.071167,869.473633 1092.139771,871.703857 
	C1089.389648,875.145325 1086.515137,877.505127 1082.604004,879.422852 
	C1051.908813,894.474121 1021.461914,910.031311 990.909912,925.375732 
	C989.317261,926.175659 987.465515,926.459473 985.505249,926.668518 
	C988.548401,924.525391 991.894592,922.815796 995.070496,920.833496 
	C997.859558,919.092529 1000.457703,917.045959 1003.140137,915.134399 
	C1003.887207,914.816589 1004.634155,914.498779 1005.898193,914.390259 
	C1009.275818,913.053589 1012.136414,911.507629 1014.997009,909.961670 
	C1015.550476,909.800049 1016.103943,909.638428 1017.148499,909.215454 
	C1018.140991,908.349426 1018.642456,907.744812 1019.143982,907.140259 
	C1020.549438,906.474060 1021.954895,905.807922 1024.083252,905.098755 
	C1028.867554,903.034790 1032.928833,901.013672 1036.990112,898.992554 
	C1036.990112,898.992554 1037.467041,898.952271 1038.031738,898.933105 
	C1039.094116,898.302612 1039.591919,897.691345 1040.089600,897.080078 
	C1040.502075,897.043457 1040.914673,897.006897 1042.003296,896.943848 
	C1047.808228,893.964111 1052.937134,891.010742 1058.065918,888.057373 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M417.932556,984.103027 
	C423.745728,986.720581 429.650360,989.154114 435.355011,991.989807 
	C452.857391,1000.690125 470.287292,1009.536316 487.725647,1018.364807 
	C488.566010,1018.790344 489.218201,1019.587524 489.661377,1020.565430 
	C487.559082,1020.307495 485.753448,1019.695862 483.947845,1019.084167 
	C483.538879,1019.038818 483.129913,1018.993469 482.329193,1018.594238 
	C479.272308,1016.859192 476.607178,1015.478088 473.942047,1014.096985 
	C473.942047,1014.096985 473.475891,1014.048279 473.105164,1013.729126 
	C471.810364,1012.957336 470.886292,1012.504639 469.962280,1012.051880 
	C469.550568,1012.014099 469.138885,1011.976379 468.330566,1011.587646 
	C460.608459,1007.511963 453.282928,1003.787170 445.957428,1000.062378 
	C444.248779,999.356201 442.540161,998.650024 440.394104,997.613525 
	C439.290955,996.874878 438.625214,996.466492 437.959503,996.058105 
	C436.901398,995.688843 435.843292,995.319580 434.361176,994.597473 
	C431.942657,993.186890 429.948151,992.129150 427.953674,991.071411 
	C427.543243,991.028931 427.132843,990.986450 426.329895,990.591553 
	C423.269104,988.193787 420.600861,986.148438 417.932556,984.103027 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M193.263000,865.844543 
	C193.730347,867.031982 194.197693,868.219482 194.860764,870.050720 
	C196.372284,872.133606 197.586197,873.685608 199.027328,874.985840 
	C201.307480,877.043213 203.739822,878.932007 206.107758,880.892151 
	C198.702560,879.733154 194.675034,873.765869 189.297729,869.149414 
	C187.825729,867.660645 186.786133,866.163452 185.453033,865.003479 
	C182.537170,862.466309 182.050293,859.215759 183.877609,856.317993 
	C184.390945,855.503906 188.040436,856.667419 190.625443,857.378052 
	C191.316956,859.882629 191.634415,861.933044 191.995148,864.285767 
	C192.376007,865.051147 192.713562,865.514221 193.085785,865.914917 
	C193.120453,865.852539 193.263000,865.844543 193.263000,865.844543 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M519.945374,1035.079956 
	C525.776672,1037.708252 531.691345,1040.167969 537.421936,1043.000122 
	C549.436096,1048.937744 561.361023,1055.056519 573.296509,1061.151855 
	C574.281860,1061.655029 575.069153,1062.545776 575.634766,1063.593018 
	C570.196838,1061.647339 565.072632,1059.365112 559.948425,1057.082764 
	C558.891663,1056.707275 557.834961,1056.331787 556.352539,1055.602783 
	C551.274475,1052.845459 546.622070,1050.441895 541.969666,1048.038208 
	C541.557312,1048.002930 541.144958,1047.967773 540.334412,1047.582764 
	C534.881104,1044.456909 529.782043,1041.756714 524.794556,1038.864136 
	C523.037598,1037.845093 521.553833,1036.354980 519.945374,1035.079956 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M233.989502,892.116699 
	C234.534821,892.273315 235.080124,892.429871 235.820892,893.112976 
	C236.673782,893.800781 237.331223,893.962097 237.988663,894.123474 
	C238.534500,894.277832 239.080338,894.432251 239.847977,895.170532 
	C242.389511,896.825134 244.709229,897.895813 247.028946,898.966553 
	C247.028946,898.966553 247.003906,898.997559 247.147125,899.300781 
	C249.563629,900.696106 251.836929,901.788269 254.110229,902.880371 
	C254.517578,902.922485 254.924942,902.964600 255.701645,903.384155 
	C257.054962,904.166626 258.038940,904.571594 259.022888,904.976685 
	C260.131470,905.611877 261.240051,906.247070 262.822784,907.251221 
	C263.969025,908.072998 264.619202,908.563049 265.316620,908.972656 
	C269.880798,911.652893 274.455109,914.315857 279.026093,916.984497 
	C277.877869,916.876648 276.565216,917.095276 275.605896,916.612244 
	C260.177612,908.842896 244.791336,900.989990 229.413544,893.120850 
	C228.875763,892.845703 228.524460,892.206116 228.319931,891.357178 
	C229.033936,890.686829 229.514252,890.395691 229.994568,890.104492 
	C230.535828,890.270325 231.077087,890.436157 231.815948,891.127502 
	C232.672211,891.807556 233.330856,891.962097 233.989502,892.116699 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M335.025635,942.964783 
	C336.879822,943.574890 338.827423,943.990173 340.572876,944.827148 
	C351.133972,949.891113 361.667206,955.014587 372.157593,960.223328 
	C373.572998,960.926147 374.695099,962.219666 375.658997,963.615540 
	C374.896576,964.014709 374.428131,964.038025 373.959717,964.061401 
	C373.545624,964.026123 373.131561,963.990845 372.336243,963.592407 
	C369.954926,962.174622 367.954773,961.119934 365.954651,960.065247 
	C365.543457,960.024780 365.132294,959.984314 364.325195,959.588562 
	C362.290253,958.480957 360.651245,957.728577 359.012238,956.976196 
	C356.285492,955.639587 353.558777,954.302856 350.383820,952.607971 
	C347.943329,951.186157 345.951019,950.122498 343.958740,949.058899 
	C341.583557,948.025513 339.208374,946.992065 336.414978,945.623535 
	C335.673065,944.513916 335.349365,943.739319 335.025635,942.964783 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M636.052734,1093.947144 
	C636.052734,1093.947144 636.096741,1094.396240 636.089355,1094.620605 
	C634.761414,1094.856567 633.207764,1095.334473 632.155762,1094.808716 
	C619.048401,1088.257935 606.011292,1081.566528 592.972717,1074.878906 
	C592.584595,1074.679932 592.376343,1074.130249 592.385498,1073.415771 
	C598.483887,1075.697998 604.281006,1078.308594 610.078064,1080.919312 
	C610.486816,1080.967163 610.895630,1081.014893 611.682495,1081.423828 
	C614.729919,1083.166870 617.399170,1084.548584 620.068481,1085.930176 
	C620.482178,1085.969116 620.895874,1086.008057 621.688049,1086.409424 
	C626.728638,1089.163696 631.390686,1091.555420 636.052734,1093.947144 
z"/>
<path fill="#F27273" opacity="1.000000" stroke="none" 
	d="
M936.114990,949.108032 
	C936.114929,949.108032 936.559814,949.069824 937.158081,949.044006 
	C941.180176,947.697021 944.603882,946.375916 948.027588,945.054810 
	C947.470642,945.800293 947.076416,946.872131 946.332703,947.243164 
	C932.444641,954.171082 918.515137,961.015747 904.319580,967.540527 
	C905.388062,966.292297 906.652344,965.226440 908.087402,964.496338 
	C916.075684,960.431763 924.133118,956.502380 932.096191,952.389771 
	C933.599670,951.613281 934.783569,950.217896 936.114990,949.108032 
z"/>
<path fill="#F27273" opacity="1.000000" stroke="none" 
	d="
M965.135925,934.132446 
	C968.934937,932.582825 972.733887,931.033203 977.118347,929.209717 
	C979.814758,928.315735 981.925598,927.695496 984.036438,927.075317 
	C983.577026,927.903748 983.324829,929.156128 982.627136,929.497070 
	C971.986877,934.697327 961.288391,939.778381 950.322144,944.552612 
	C952.710571,942.163025 955.380249,940.108154 958.049927,938.053284 
	C958.835754,937.781982 959.621582,937.510681 961.040955,937.099426 
	C962.828369,936.017090 963.982117,935.074768 965.135925,934.132446 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M279.380768,917.034180 
	C274.455109,914.315857 269.880798,911.652893 265.316620,908.972656 
	C264.619202,908.563049 263.969025,908.072998 263.148499,907.299683 
	C263.561188,906.774536 264.294098,906.230164 264.658539,906.414429 
	C275.202179,911.746216 285.715210,917.138794 296.207184,922.571777 
	C296.883606,922.921997 297.351685,923.674500 297.962921,924.877686 
	C298.005951,925.713562 298.002716,925.911804 297.999451,926.109985 
	C297.289551,926.038147 296.579681,925.966309 295.430359,925.537964 
	C293.312866,924.141846 291.634796,923.102051 289.956787,922.062256 
	C286.549683,920.402771 283.142578,918.743286 279.380768,917.034180 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M935.742310,949.096924 
	C934.783569,950.217896 933.599670,951.613281 932.096191,952.389771 
	C924.133118,956.502380 916.075684,960.431763 908.087402,964.496338 
	C906.652344,965.226440 905.388062,966.292297 903.950623,967.530640 
	C902.484192,968.230774 901.112122,968.603455 899.384460,969.034546 
	C899.020813,968.890808 899.012634,968.688660 898.988586,968.182129 
	C902.612366,965.618652 906.106140,963.067688 909.921631,961.160339 
	C918.317993,956.962830 926.877014,953.090637 935.742310,949.096924 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M610.015747,1080.579956 
	C604.281006,1078.308594 598.483887,1075.697998 592.320923,1073.074951 
	C591.542969,1073.023438 591.130859,1072.984497 590.326050,1072.596558 
	C587.275391,1070.851807 584.617432,1069.456299 581.959473,1068.060669 
	C581.547302,1068.021606 581.135193,1067.982544 580.342041,1067.615112 
	C579.294739,1066.467163 578.628479,1065.647705 577.962158,1064.828125 
	C581.007263,1065.844727 584.232422,1066.507202 587.062988,1067.945801 
	C594.782104,1071.868896 602.334778,1076.119629 610.015747,1080.579956 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M299.910034,925.144897 
	C303.359985,926.493774 306.957031,927.559143 310.226532,929.255737 
	C315.577789,932.032593 320.715973,935.220093 325.635803,938.580566 
	C321.535797,937.308472 317.744019,935.690186 313.952240,934.071899 
	C310.248505,932.368774 306.544769,930.665710 302.395020,928.622559 
	C301.269348,927.236694 300.589691,926.190735 299.910034,925.144897 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M697.222168,1071.308228 
	C706.099854,1066.394897 715.198364,1061.733887 724.661133,1057.052734 
	C724.186096,1057.936279 723.510254,1059.146851 722.481506,1059.694580 
	C714.804138,1063.782471 707.065063,1067.755249 699.311707,1071.698120 
	C698.835327,1071.940308 698.072571,1071.619507 697.222168,1071.308228 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M519.640991,1035.038330 
	C521.553833,1036.354980 523.037598,1037.845093 524.794556,1038.864136 
	C529.782043,1041.756714 534.881104,1044.456909 540.024536,1047.564697 
	C531.437317,1043.506348 522.761841,1039.116089 514.382874,1034.399902 
	C515.823975,1034.340454 516.968506,1034.607178 518.113098,1034.873779 
	C518.520935,1034.914795 518.928833,1034.955811 519.640991,1035.038330 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M984.267700,927.043213 
	C981.925598,927.695496 979.814758,928.315735 977.332764,928.937012 
	C981.615967,926.141235 986.195312,923.208191 990.948730,920.591370 
	C994.650696,918.553406 998.569214,916.908936 1002.765808,915.111938 
	C1000.457703,917.045959 997.859558,919.092529 995.070496,920.833496 
	C991.894592,922.815796 988.548401,924.525391 985.119629,926.657288 
	C984.963135,926.961121 984.498901,927.011108 984.267700,927.043213 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M518.034424,1034.539795 
	C516.968506,1034.607178 515.823975,1034.340454 514.322998,1034.057007 
	C513.554443,1034.003784 513.142273,1033.967285 512.332764,1033.583252 
	C507.943970,1031.175537 503.952484,1029.115356 499.961029,1027.055054 
	C499.547363,1027.018677 499.133667,1026.982178 498.331543,1026.612549 
	C497.282349,1025.204102 496.621674,1024.128784 495.960938,1023.053467 
	C503.292511,1026.770996 510.624084,1030.488525 518.034424,1034.539795 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M417.622772,984.071838 
	C420.600861,986.148438 423.269104,988.193787 426.021057,990.572021 
	C418.098938,986.846863 410.093109,982.788818 402.390137,978.406738 
	C407.152374,980.030945 411.611786,981.979187 416.071198,983.927368 
	C416.485107,983.965149 416.899048,984.002869 417.622772,984.071838 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M206.413971,880.925781 
	C203.739822,878.932007 201.307480,877.043213 199.027328,874.985840 
	C197.586197,873.685608 196.372284,872.133606 195.028870,870.346680 
	C195.788895,870.232117 196.698059,870.286743 197.345078,870.726868 
	C202.910156,874.511719 208.427917,878.366089 213.666840,882.556763 
	C211.565506,882.303101 209.757141,881.692383 207.948761,881.081543 
	C207.539230,881.040833 207.129700,881.000122 206.413971,880.925781 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M957.663086,938.031128 
	C955.380249,940.108154 952.710571,942.163025 949.953491,944.544678 
	C949.457214,944.906250 949.048340,944.941101 948.333496,945.015381 
	C944.603882,946.375916 941.180176,947.697021 937.378845,949.010315 
	C937.421936,948.364441 937.694702,947.425903 938.286072,947.134766 
	C944.587097,944.033813 950.938599,941.035522 957.663086,938.031128 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M446.023926,1000.400757 
	C453.282928,1003.787170 460.608459,1007.511963 468.021057,1011.569031 
	C460.768890,1008.180603 453.429626,1004.459839 446.023926,1000.400757 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M577.680603,1064.827148 
	C578.628479,1065.647705 579.294739,1066.467163 580.032104,1067.597412 
	C573.432251,1064.523071 566.761292,1061.138062 560.019409,1057.417969 
	C565.072632,1059.365112 570.196838,1061.647339 575.689209,1063.937012 
	C576.504578,1064.238281 576.951782,1064.532227 577.680603,1064.827148 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M208.019958,881.417480 
	C209.757141,881.692383 211.565506,882.303101 213.739624,882.896423 
	C214.105362,882.878967 214.561996,882.923950 214.914948,883.254395 
	C216.211258,884.020691 217.154602,884.456543 218.097961,884.892334 
	C218.512222,884.923401 218.926468,884.954529 219.695999,885.343994 
	C220.734634,886.098328 221.417999,886.494324 222.101364,886.890381 
	C222.512711,886.924500 222.924072,886.958557 223.691528,887.352051 
	C224.732986,888.773376 225.418320,889.835266 226.103668,890.897156 
	C220.099487,887.849243 214.095322,884.801331 208.019958,881.417480 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M378.989685,964.944946 
	C379.385101,964.719055 379.937408,964.196716 380.152954,964.310791 
	C385.096619,966.926880 390.003510,969.612244 394.586609,972.647217 
	C390.823730,971.692749 387.391541,970.375732 383.959320,969.058716 
	C383.548401,969.019714 383.137451,968.980774 382.342773,968.608643 
	C380.969208,967.165283 379.979462,966.055115 378.989685,964.944946 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M416.009338,983.585205 
	C411.611786,981.979187 407.152374,980.030945 402.327972,978.064697 
	C401.553070,978.009033 401.143158,977.971375 400.366882,977.608459 
	C399.672760,976.514099 399.345032,975.744995 399.017303,974.975891 
	C404.660706,977.731567 410.304077,980.487244 416.009338,983.585205 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M829.249512,1005.889404 
	C832.432617,1003.656189 835.934875,1001.337280 839.564026,999.237854 
	C841.702087,998.001099 844.041504,997.112488 846.653931,996.043213 
	C844.885803,997.573669 842.886047,999.368530 840.592102,1000.627197 
	C837.040955,1002.575745 833.288940,1004.158264 829.249512,1005.889404 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M398.787842,974.951538 
	C399.345032,975.744995 399.672760,976.514099 400.058380,977.589844 
	C394.774689,975.175964 389.433075,972.455444 384.025391,969.396851 
	C387.391541,970.375732 390.823730,971.692749 394.635193,972.997803 
	C395.014404,972.985840 395.474487,973.042664 395.761414,973.385315 
	C396.731537,974.117065 397.414734,974.506104 398.097931,974.895142 
	C398.097931,974.895142 398.558350,974.927185 398.787842,974.951538 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M495.966553,1023.042786 
	C496.621674,1024.128784 497.282349,1025.204102 498.020508,1026.595703 
	C493.430450,1024.528320 488.762939,1022.144775 484.021637,1019.422729 
	C485.753448,1019.695862 487.559082,1020.307495 489.729797,1020.907043 
	C490.508118,1020.930786 490.921356,1020.966675 491.713318,1021.386963 
	C493.380951,1022.194702 494.669830,1022.618042 495.958710,1023.041443 
	C495.958710,1023.041443 495.972107,1023.032166 495.966553,1023.042786 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M314.007111,934.416504 
	C317.744019,935.690186 321.535797,937.308472 325.695984,938.922974 
	C326.064423,938.919128 326.036285,938.939270 326.170929,939.199951 
	C326.871124,939.972900 327.436676,940.485229 327.980835,941.018982 
	C327.959473,941.040405 327.916656,941.083252 327.916687,941.083252 
	C323.298431,938.975830 318.680206,936.868469 314.007111,934.416504 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M542.030945,1048.378540 
	C546.622070,1050.441895 551.274475,1052.845459 556.011475,1055.582520 
	C551.428040,1053.516846 546.760132,1051.117920 542.030945,1048.378540 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M500.025970,1027.394165 
	C503.952484,1029.115356 507.943970,1031.175537 512.022583,1033.567871 
	C508.103516,1031.844360 504.097198,1029.788818 500.025970,1027.394165 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M785.225525,1027.870483 
	C788.344543,1025.604980 791.835144,1023.355103 795.691589,1021.099854 
	C792.570679,1023.358398 789.083923,1025.622314 785.225525,1027.870483 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M378.679230,964.909424 
	C379.979462,966.055115 380.969208,967.165283 382.033875,968.587646 
	C379.436188,967.511414 376.763611,966.122986 374.025391,964.397949 
	C374.428131,964.038025 374.896576,964.014709 375.716309,963.960815 
	C376.834656,964.244751 377.601685,964.559265 378.679230,964.909424 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M334.795227,942.940796 
	C335.349365,943.739319 335.673065,944.513916 336.052368,945.596863 
	C333.426605,944.531555 330.745270,943.157898 327.990295,941.433716 
	C327.916656,941.083252 327.959473,941.040405 328.336212,941.055176 
	C330.509460,941.675903 332.305969,942.281860 334.102478,942.887878 
	C334.102478,942.887878 334.564850,942.916748 334.795227,942.940796 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M582.025513,1068.397705 
	C584.617432,1069.456299 587.275391,1070.851807 590.015808,1072.579834 
	C587.429321,1071.519653 584.760437,1070.127319 582.025513,1068.397705 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M849.203796,995.264404 
	C851.433533,993.704346 853.869568,992.386963 856.664307,991.047913 
	C854.485352,992.519897 851.947754,994.013489 849.203796,995.264404 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M474.014893,1014.429871 
	C476.607178,1015.478088 479.272308,1016.859192 482.021362,1018.572144 
	C479.432770,1017.523560 476.760254,1016.143127 474.014893,1014.429871 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M366.008667,960.414429 
	C367.954773,961.119934 369.954926,962.174622 372.025146,963.573853 
	C370.084381,962.866760 368.073547,961.815186 366.008667,960.414429 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M226.413055,890.924072 
	C225.418320,889.835266 224.732986,888.773376 223.998840,887.390015 
	C225.850739,887.880127 227.751450,888.691772 229.823364,889.803955 
	C229.514252,890.395691 229.033936,890.686829 228.257736,891.015381 
	C227.548721,891.018860 227.135590,890.984924 226.413055,890.924072 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M344.023804,949.397095 
	C345.951019,950.122498 347.943329,951.186157 350.017090,952.579529 
	C348.095306,951.851318 346.092102,950.793335 344.023804,949.397095 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M727.204590,1056.281738 
	C728.772827,1055.049316 730.546692,1054.056519 732.671631,1053.043701 
	C731.151855,1054.189819 729.281067,1055.355713 727.204590,1056.281738 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M428.019897,991.406250 
	C429.948151,992.129150 431.942657,993.186890 434.021271,994.573608 
	C432.098969,993.848755 430.092560,992.794861 428.019897,991.406250 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M290.018921,922.394104 
	C291.634796,923.102051 293.312866,924.141846 295.074707,925.509644 
	C293.466034,924.800354 291.773560,923.763123 290.018921,922.394104 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M359.062561,957.329346 
	C360.651245,957.728577 362.290253,958.480957 364.015869,959.569336 
	C362.439270,959.164490 360.776093,958.423462 359.062561,957.329346 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M299.593933,925.098145 
	C300.589691,926.190735 301.269348,927.236694 302.024719,928.595703 
	C300.766815,928.195190 299.433167,927.481567 298.049500,926.438965 
	C298.002716,925.911804 298.005951,925.713562 298.006958,925.217285 
	C298.429108,924.963257 298.853455,925.007385 299.593933,925.098145 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M822.306641,1008.949341 
	C823.401672,1007.974243 824.868774,1007.016724 826.679382,1006.042786 
	C825.574829,1007.006592 824.126770,1007.986755 822.306641,1008.949341 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M691.231445,1074.889160 
	C692.024719,1073.940186 693.175720,1073.002930 694.673645,1072.045044 
	C693.876831,1072.983276 692.733032,1073.942017 691.231445,1074.889160 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M769.210754,1035.882324 
	C769.700745,1034.944214 770.539734,1034.022461 771.721313,1033.103027 
	C771.229187,1034.036377 770.394470,1034.967529 769.210754,1035.882324 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M641.170471,1095.290894 
	C641.881836,1094.858154 642.771973,1094.678589 643.835205,1094.766357 
	C643.121948,1095.203735 642.235596,1095.373901 641.170471,1095.290894 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M470.027283,1012.390991 
	C470.886292,1012.504639 471.810364,1012.957336 472.872192,1013.703796 
	C472.037415,1013.575073 471.064850,1013.152527 470.027283,1012.390991 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M816.251587,1011.926514 
	C816.418640,1011.329651 816.911377,1010.718323 817.728394,1010.095337 
	C817.560974,1010.693054 817.069153,1011.302551 816.251587,1011.926514 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M651.197510,1094.872070 
	C651.368713,1094.265503 651.874695,1093.667603 652.699829,1093.043701 
	C652.523438,1093.638916 652.027832,1094.259888 651.197510,1094.872070 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M663.193726,1088.868896 
	C663.368835,1088.265015 663.877869,1087.671021 664.705933,1087.052734 
	C664.525818,1087.645264 664.026794,1088.261841 663.193726,1088.868896 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M438.016418,996.378540 
	C438.625214,996.466492 439.290955,996.874878 440.032410,997.592773 
	C439.429871,997.501160 438.751617,997.100037 438.016418,996.378540 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M859.201782,990.880127 
	C859.374023,990.273376 859.879517,989.674133 860.705444,989.052490 
	C860.528870,989.649292 860.031982,990.268494 859.201782,990.880127 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M863.190979,988.864685 
	C863.367371,988.261597 863.877563,987.667847 864.707397,987.052979 
	C864.526245,987.645813 864.025513,988.259949 863.190979,988.864685 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M667.205322,1086.882324 
	C667.374878,1086.275391 667.877991,1085.673950 668.701050,1085.047119 
	C668.526978,1085.643677 668.032898,1086.265625 667.205322,1086.882324 
z"/>
<path fill="#ED5C5C" opacity="1.000000" stroke="none" 
	d="
M192.667267,853.997070 
	C191.783630,853.269165 190.424301,852.694702 190.128372,851.777283 
	C189.689758,850.417480 190.000122,848.814514 190.000107,847.315063 
	C189.999329,710.692261 189.991974,574.069397 190.039780,437.446594 
	C190.041046,433.830933 189.863724,430.674652 186.721420,428.070251 
	C184.007126,425.820587 184.244217,422.780670 186.443451,420.093079 
	C189.889999,415.881256 193.431458,411.747101 196.945984,407.219604 
	C197.419907,406.543945 197.880539,406.228668 198.801270,406.136597 
	C199.840607,405.875122 200.419876,405.390472 200.999146,404.905823 
	C212.344650,399.251129 223.690155,393.596436 235.773254,387.942963 
	C237.339584,387.256256 238.168289,386.568329 238.997009,385.880432 
	C255.683853,377.668213 272.406921,369.528442 289.040161,361.209137 
	C295.951569,357.752350 302.660767,353.892487 309.494263,350.276978 
	C310.186981,349.910492 311.103394,349.966766 312.652374,349.853516 
	C313.925232,349.218292 314.461700,348.556396 314.998169,347.894501 
	C314.998169,347.894501 315.364594,347.836548 315.903442,347.868408 
	C316.964294,347.260986 317.486359,346.621704 318.008423,345.982422 
	C319.684174,345.303253 321.359924,344.624084 323.732880,343.920044 
	C324.955444,343.250031 325.480835,342.604889 326.006226,341.959717 
	C327.677063,341.285461 329.347931,340.611176 331.719177,339.914307 
	C332.947693,339.236572 333.475830,338.581451 334.003967,337.926331 
	C334.003967,337.926331 334.404999,337.875244 334.990387,337.921143 
	C336.398102,337.262787 337.220428,336.558563 338.042755,335.854340 
	C338.843414,335.710632 339.644043,335.566925 340.998138,335.177002 
	C342.037537,334.297546 342.523529,333.664276 343.009521,333.031006 
	C344.935181,332.213226 346.860840,331.395416 349.259125,330.267700 
	C350.857941,329.744171 352.117371,329.785675 353.089783,329.277771 
	C363.338501,323.924500 373.550110,318.499786 383.735901,313.027649 
	C384.367950,312.688080 384.697266,311.784973 385.511810,311.070923 
	C387.873810,310.260010 389.891418,309.522217 392.271545,308.806274 
	C396.813416,306.264832 400.992767,303.701599 405.512573,301.076050 
	C407.554993,300.316528 409.257019,299.619354 411.335999,298.941711 
	C416.189362,296.343170 420.665741,293.725037 425.447083,291.092163 
	C426.155853,291.028503 426.559631,290.979584 427.331940,290.916077 
	C428.637970,290.587677 429.630249,290.382935 430.504700,289.943634 
	C442.113098,284.112305 453.734497,278.305298 465.283936,272.358826 
	C468.981995,270.454803 472.478821,268.159973 476.355042,266.014313 
	C477.100067,265.673645 477.557800,265.362610 478.392914,265.041107 
	C480.460968,264.372894 482.213348,263.839691 483.832428,263.037506 
	C495.897797,257.059509 507.911194,250.976410 519.989502,245.025009 
	C541.730774,234.312363 563.510925,223.678680 585.265320,212.992569 
	C598.919983,206.285187 612.568359,199.564789 626.195801,192.802292 
	C630.512268,190.660309 634.751831,188.363632 639.026611,186.137756 
	C639.768982,186.122192 640.511353,186.106628 641.857544,186.384720 
	C650.293701,189.912598 658.161072,193.065323 665.945374,196.411285 
	C670.694702,198.452728 675.316223,200.791626 680.045349,203.339386 
	C681.449707,204.423706 682.803833,205.165588 684.367310,205.903503 
	C684.576599,205.899536 684.993164,205.942200 685.135193,206.248444 
	C686.243530,207.000488 687.209839,207.446274 688.498047,208.056824 
	C691.339111,209.322311 693.870300,210.396591 696.374573,211.530380 
	C699.594055,212.987991 702.791931,214.493362 706.044434,216.313187 
	C706.786377,217.062607 707.483032,217.477005 708.544495,217.911560 
	C719.082397,223.145294 729.225220,228.418991 739.436707,233.556244 
	C750.480408,239.112198 761.543579,244.633698 772.683289,249.993378 
	C780.530518,253.768921 788.519836,257.249115 796.473267,261.127899 
	C797.211792,261.765594 797.922791,262.136627 799.006348,262.675171 
	C801.285278,263.500458 803.191711,264.158234 805.408936,264.861389 
	C806.402710,265.285339 807.085571,265.663879 807.516113,266.061768 
	C807.119019,266.156128 806.926147,266.197449 806.843506,266.315796 
	C806.763184,266.430725 806.736816,266.741608 806.787476,266.767212 
	C810.226135,268.506042 813.674866,270.224731 817.122803,271.945129 
	C818.549011,272.519928 819.975159,273.094696 821.750610,274.167847 
	C824.438721,275.757507 826.777466,276.848755 829.116272,277.940002 
	C831.270386,278.766907 833.424438,279.593811 835.836914,281.023071 
	C836.802917,282.044373 837.510620,282.463318 838.218323,282.882263 
	C838.614319,282.891785 839.010254,282.901306 839.749023,283.277832 
	C840.807983,284.052307 841.524292,284.459747 842.240540,284.867218 
	C875.255493,301.196838 908.270508,317.526459 941.690186,334.265228 
	C943.476318,335.405090 944.857666,336.135834 946.239075,336.866608 
	C946.239075,336.866608 946.614807,336.846558 946.924316,337.166565 
	C948.165833,337.638397 949.097839,337.790192 950.029907,337.941986 
	C951.161072,338.517731 952.292175,339.093475 953.757690,340.160614 
	C954.806030,341.058319 955.520020,341.464691 956.233948,341.871033 
	C960.620178,343.797729 965.006348,345.724396 969.750610,348.169067 
	C971.124084,349.095490 972.139465,349.503937 973.154846,349.912354 
	C979.195923,352.894073 985.237000,355.875824 991.686462,359.267792 
	C993.466248,360.414246 994.837585,361.150452 996.208984,361.886658 
	C997.253784,362.215057 998.298523,362.543457 999.699585,363.219971 
	C1000.710388,363.703217 1001.364929,363.838409 1002.019409,363.973602 
	C1002.573242,364.140289 1003.127075,364.306946 1003.889648,365.067352 
	C1006.134460,366.737549 1008.170654,367.813995 1010.206787,368.890442 
	C1011.909119,369.552094 1013.611389,370.213745 1015.705627,371.279175 
	C1018.804932,373.082123 1021.512390,374.481293 1024.219849,375.880493 
	C1025.027954,376.048981 1025.835938,376.217499 1026.967285,376.958984 
	C1029.598389,378.649689 1031.906372,379.767456 1034.214355,380.885223 
	C1036.592407,381.817963 1038.970459,382.750732 1041.723511,384.188538 
	C1044.803101,386.090973 1047.507568,387.488342 1050.212158,388.885681 
	C1050.609009,388.897369 1051.005859,388.909058 1051.747070,389.290161 
	C1052.806274,390.063080 1053.521118,390.466614 1054.235840,390.870117 
	C1054.627197,390.887115 1055.018555,390.904114 1055.752686,391.296356 
	C1058.138062,392.741028 1060.180664,393.810455 1062.223145,394.879822 
	C1062.616333,394.898499 1063.009521,394.917175 1063.746094,395.301453 
	C1064.797607,396.073547 1065.505737,396.480103 1066.213745,396.886597 
	C1067.273682,397.156342 1068.333618,397.426117 1069.741577,398.180908 
	C1070.799438,399.071533 1071.509399,399.477112 1072.219238,399.882690 
	C1072.614380,399.896454 1073.009399,399.910217 1073.750000,400.297607 
	C1076.138916,401.739349 1078.182251,402.807495 1080.225708,403.875671 
	C1080.619507,403.892090 1081.013306,403.908569 1081.701172,403.955811 
	C1084.856079,406.582306 1087.826782,409.068970 1090.546753,411.804871 
	C1093.191162,414.464752 1096.047119,417.093872 1097.973755,420.247467 
	C1101.194214,425.519073 1098.747925,430.019379 1092.174316,430.561890 
	C1091.633301,426.822021 1091.581177,423.528046 1091.622192,419.982880 
	C1091.476929,419.487793 1091.238403,419.243896 1090.903320,418.776428 
	C1090.619019,418.273254 1090.367554,418.074249 1089.961304,417.703888 
	C1089.548096,417.403656 1089.225708,417.355408 1088.531494,417.262146 
	C1038.258179,392.251740 988.357605,367.284729 938.455261,342.321442 
	C841.461609,293.801117 744.455444,245.305725 647.512512,196.684326 
	C643.427307,194.635376 640.220337,194.997528 636.322327,196.970139 
	C597.268677,216.733749 558.164673,236.398514 519.021057,255.983643 
	C484.947357,273.032104 450.792755,289.918854 416.695862,306.921082 
	C390.190704,320.137695 363.709229,333.401794 337.236206,346.682526 
	C313.748138,358.465820 290.319733,370.368408 266.803101,382.094116 
	C244.190582,393.368988 221.604004,404.707764 198.756348,415.489349 
	C193.951889,417.756531 192.907059,420.333496 192.910812,425.126221 
	C193.021271,565.929016 193.001984,706.731873 192.986771,847.534729 
	C192.986542,849.688843 192.778366,851.842957 192.667267,853.997070 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M679.995117,202.996948 
	C675.316223,200.791626 670.694702,198.452728 665.945374,196.411285 
	C658.161072,193.065323 650.293701,189.912598 642.228638,186.402359 
	C644.049927,185.751892 646.103943,185.377441 648.781677,185.271027 
	C650.273682,185.687546 651.141968,185.836060 652.010254,185.984558 
	C652.794739,186.302551 653.579224,186.620544 654.842163,187.219971 
	C655.880432,187.667587 656.440186,187.833801 657.145752,188.303986 
	C658.869690,189.391739 660.447815,190.175491 662.025940,190.959229 
	C662.025940,190.959229 662.513611,190.980804 662.906006,191.299591 
	C666.209351,193.062241 669.120300,194.506119 672.031250,195.950012 
	C672.808777,196.281311 673.586304,196.612610 674.832764,197.281525 
	C677.861938,198.752350 680.422180,199.885574 682.982422,201.018799 
	C682.982422,201.018799 682.997559,201.000610 683.140747,201.310715 
	C684.862549,202.403809 686.441101,203.186813 688.019714,203.969818 
	C688.019714,203.969818 688.504944,203.991974 688.902527,204.312317 
	C691.864014,205.758759 694.427979,206.884842 696.991882,208.010925 
	C700.089478,209.647919 703.187073,211.284912 706.802673,213.214630 
	C707.877380,213.675446 708.434143,213.843536 708.990845,214.011627 
	C709.461426,214.330872 709.932007,214.650131 710.889404,215.270966 
	C713.599487,216.688675 715.822815,217.804779 718.046143,218.920898 
	C718.821716,219.258423 719.597290,219.595932 720.840576,220.276489 
	C723.207642,221.409988 725.107117,222.200455 727.006531,222.990936 
	C727.473572,223.309204 727.940552,223.627487 728.886353,224.241913 
	C730.578430,225.023041 731.791626,225.507996 733.004883,225.992966 
	C733.472473,226.310455 733.940063,226.627930 734.864502,227.220734 
	C735.882507,227.661621 736.443726,227.827209 737.004883,227.992798 
	C742.762695,230.971222 748.520447,233.949646 754.775879,237.263290 
	C756.185425,238.061096 757.097412,238.523682 758.009338,238.986267 
	C770.125793,244.897858 782.242188,250.809448 794.720093,257.163208 
	C795.536316,258.690643 795.991028,259.775940 796.445740,260.861206 
	C788.519836,257.249115 780.530518,253.768921 772.683289,249.993378 
	C761.543579,244.633698 750.480408,239.112198 739.436707,233.556244 
	C729.225220,228.418991 719.082397,223.145294 708.451782,217.604340 
	C707.329163,216.844040 706.664124,216.411102 705.999084,215.978165 
	C702.791931,214.493362 699.594055,212.987991 696.374573,211.530380 
	C693.870300,210.396591 691.339111,209.322311 688.392090,207.726318 
	C686.973877,206.801422 685.983521,206.371811 684.993164,205.942200 
	C684.993164,205.942200 684.576599,205.899536 684.272827,205.578247 
	C682.644409,204.503616 681.319763,203.750290 679.995117,202.996948 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M638.840820,185.845032 
	C634.751831,188.363632 630.512268,190.660309 626.195801,192.802292 
	C612.568359,199.564789 598.919983,206.285187 585.265320,212.992569 
	C563.510925,223.678680 541.730774,234.312363 519.989502,245.025009 
	C507.911194,250.976410 495.897797,257.059509 483.832428,263.037506 
	C482.213348,263.839691 480.460968,264.372894 478.530579,264.720581 
	C483.923248,261.406769 489.593964,258.471466 495.161926,255.352692 
	C496.613220,254.539764 497.744232,253.154984 499.022369,252.032837 
	C501.192322,251.189377 503.362305,250.345901 506.078857,249.224579 
	C507.417236,248.299225 508.208984,247.651718 509.000732,247.004211 
	C511.850830,245.843719 514.700928,244.683212 518.106262,243.238007 
	C519.773560,242.296494 520.885620,241.639694 521.997681,240.982895 
	C522.549377,240.812500 523.101074,240.642105 524.178223,240.204376 
	C526.467529,238.947449 528.231445,237.957825 529.995300,236.968231 
	C530.551758,236.808975 531.108215,236.649734 532.136353,236.233856 
	C533.071289,235.646500 533.534485,235.315781 533.997742,234.985077 
	C535.859375,234.168869 537.721008,233.352661 540.153748,232.240082 
	C542.165100,230.976746 543.605408,230.009750 545.045654,229.042755 
	C545.045654,229.042755 545.520935,229.023529 546.122742,228.979675 
	C549.815491,227.282135 552.906494,225.628433 555.997437,223.974701 
	C556.552246,223.811234 557.107056,223.647751 558.153076,223.220245 
	C559.431824,222.306396 560.219299,221.656570 561.006775,221.006775 
	C561.441650,220.995850 561.876587,220.984924 562.963013,220.937683 
	C564.082275,220.272217 564.550049,219.643066 565.017761,219.013916 
	C566.206055,218.518600 567.394287,218.023285 569.153992,217.238449 
	C571.175476,215.991745 572.625549,215.034546 574.075623,214.077362 
	C574.501892,214.058548 574.928162,214.039734 575.996948,213.990311 
	C577.436401,213.317673 578.233398,212.675644 579.030396,212.033600 
	C579.030396,212.033600 579.513550,212.019135 580.077515,211.989990 
	C581.437134,211.317688 582.232849,210.674530 583.028564,210.031357 
	C583.028564,210.031357 583.513245,210.019806 584.075195,209.977417 
	C585.119019,209.317459 585.600891,208.699860 586.082764,208.082275 
	C586.082764,208.082275 586.549622,208.065933 587.133667,208.042145 
	C588.503723,207.370926 589.289795,206.723495 590.075867,206.076065 
	C591.806396,205.394897 593.536926,204.713730 595.952393,203.983154 
	C597.118347,203.315689 597.599426,202.697647 598.080505,202.079590 
	C598.505981,202.060699 598.931396,202.041840 599.999512,201.991058 
	C601.429443,201.307434 602.216797,200.655731 603.004089,200.003998 
	C603.475403,199.692764 603.946655,199.381546 605.067139,199.009979 
	C607.476807,197.960556 609.237183,196.971497 610.997620,195.982437 
	C615.111084,194.077011 619.224609,192.171585 623.984253,190.109772 
	C625.421997,189.304092 626.213501,188.654831 627.005005,188.005585 
	C627.005005,188.005600 627.000000,188.000000 627.341797,188.006866 
	C628.454956,187.671127 629.226379,187.328537 629.997803,186.985947 
	C630.798279,186.735245 631.598755,186.484543 633.032837,186.119705 
	C634.444336,185.672180 635.222168,185.338791 636.000000,185.005402 
	C636.885010,185.187714 637.770081,185.370010 638.840820,185.845032 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M476.067719,266.043945 
	C472.478821,268.159973 468.981995,270.454803 465.283936,272.358826 
	C453.734497,278.305298 442.113098,284.112305 430.504700,289.943634 
	C429.630249,290.382935 428.637970,290.587677 427.394806,290.578094 
	C428.400696,289.331085 429.624207,288.234985 431.038605,287.512848 
	C438.384308,283.762207 445.810394,280.167969 453.129639,276.367371 
	C454.606934,275.600281 455.743744,274.177429 457.037567,273.056946 
	C457.465668,273.035675 457.893738,273.014465 458.992645,272.960632 
	C460.787811,271.970917 461.912170,271.013794 463.036530,270.056641 
	C463.466370,270.037354 463.896210,270.018036 465.013550,269.960388 
	C469.156647,268.629303 472.612183,267.336609 476.067719,266.043945 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M385.167419,311.144043 
	C384.697266,311.784973 384.367950,312.688080 383.735901,313.027649 
	C373.550110,318.499786 363.338501,323.924500 353.089783,329.277771 
	C352.117371,329.785675 350.857941,329.744171 349.335022,329.921875 
	C348.966736,329.181061 348.995270,328.476105 349.034515,327.418701 
	C349.468536,327.036926 349.891815,327.007568 350.998047,326.947205 
	C354.134338,325.298492 356.587677,323.680786 359.041016,322.063110 
	C359.468628,322.040466 359.896240,322.017822 361.009430,321.957520 
	C365.134888,320.278717 368.662323,318.794891 371.985931,316.944855 
	C374.472656,315.560608 376.696655,313.704407 379.037628,312.058350 
	C379.466949,312.038177 379.896271,312.018005 381.007141,311.962708 
	C382.848236,311.666382 384.007812,311.405212 385.167419,311.144043 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M456.672180,273.069763 
	C455.743744,274.177429 454.606934,275.600281 453.129639,276.367371 
	C445.810394,280.167969 438.384308,283.762207 431.038605,287.512848 
	C429.624207,288.234985 428.400696,289.331085 427.026276,290.592651 
	C426.559631,290.979584 426.155853,291.028503 425.082886,291.080139 
	C423.261505,291.373932 422.109344,291.665070 420.957153,291.956207 
	C424.327118,289.740326 427.533752,287.207001 431.099823,285.372375 
	C439.410156,281.097015 447.893524,277.157928 456.672180,273.069763 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M476.355042,266.014343 
	C472.612183,267.336609 469.156647,268.629303 465.335938,269.946075 
	C468.434082,267.683838 471.733795,265.083527 475.394745,263.176819 
	C482.915161,259.260010 490.628967,255.714462 498.642761,252.024475 
	C497.744232,253.154984 496.613220,254.539764 495.161926,255.352692 
	C489.593964,258.471466 483.923248,261.406769 478.153198,264.731018 
	C477.557800,265.362610 477.100067,265.673645 476.355042,266.014343 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M796.473267,261.127899 
	C795.991028,259.775940 795.536316,258.690643 795.040771,257.302673 
	C800.190308,259.275238 805.405457,261.497314 810.541687,263.888733 
	C811.234070,264.211121 811.529663,265.385651 811.655579,266.557953 
	C810.124268,266.647675 808.946411,266.345032 807.768494,266.042419 
	C807.085571,265.663879 806.402710,265.285339 805.325745,264.539734 
	C800.804688,260.835571 800.447144,260.741058 798.633789,262.507629 
	C797.922791,262.136627 797.211792,261.765594 796.473267,261.127899 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M378.661743,312.041107 
	C376.696655,313.704407 374.472656,315.560608 371.985931,316.944855 
	C368.662323,318.794891 365.134888,320.278717 361.330261,321.942535 
	C361.632843,320.874786 362.042633,319.250000 363.010620,318.783478 
	C368.023834,316.367310 373.179718,314.247192 378.661743,312.041107 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M410.959015,298.922150 
	C409.257019,299.619354 407.554993,300.316528 405.142029,301.026794 
	C403.936890,301.007935 403.442780,300.976074 402.948639,300.944214 
	C408.064117,297.989563 413.179626,295.034943 418.602020,292.400330 
	C416.258972,294.787598 413.608978,296.854889 410.959015,298.922150 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M411.335999,298.941711 
	C413.608978,296.854889 416.258972,294.787598 418.967102,292.379395 
	C419.454865,292.020630 419.884430,292.002747 420.635590,291.970520 
	C422.109344,291.665070 423.261505,291.373932 424.777893,291.094849 
	C420.665741,293.725037 416.189362,296.343170 411.335999,298.941711 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M402.714539,300.964111 
	C403.442780,300.976074 403.936890,301.007935 404.801575,301.089111 
	C400.992767,303.701599 396.813416,306.264832 392.293884,308.466064 
	C391.976410,307.412384 391.999146,306.720795 392.021851,306.029175 
	C392.021851,306.029175 392.487396,305.987488 393.093079,305.949768 
	C396.469788,304.280762 399.240845,302.649445 402.011871,301.018127 
	C402.011871,301.018097 402.480469,300.983978 402.714539,300.964111 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M807.516113,266.061768 
	C808.946411,266.345032 810.124268,266.647675 811.667114,266.921509 
	C813.456726,267.526031 814.881348,268.159363 816.603516,268.948456 
	C816.951233,269.814911 817.001343,270.525635 817.087158,271.590729 
	C813.674866,270.224731 810.226135,268.506042 806.787476,266.767212 
	C806.736816,266.741608 806.763184,266.430725 806.843506,266.315796 
	C806.926147,266.197449 807.119019,266.156128 807.516113,266.061768 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M391.647522,306.054321 
	C391.999146,306.720795 391.976410,307.412384 391.931335,308.444214 
	C389.891418,309.522217 387.873810,310.260010 385.511780,311.070923 
	C384.007812,311.405212 382.848236,311.666382 381.329163,311.948547 
	C382.795471,307.192078 387.804047,307.982300 391.647522,306.054321 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M555.794312,223.726318 
	C552.906494,225.628433 549.815491,227.282135 546.360107,228.964981 
	C549.194214,227.155396 552.392761,225.316650 555.794312,223.726318 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M401.634094,301.012573 
	C399.240845,302.649445 396.469788,304.280762 393.326050,305.931458 
	C395.721039,304.302887 398.488678,302.654968 401.634094,301.012573 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M682.940063,200.670776 
	C680.422180,199.885574 677.861938,198.752350 675.150879,197.307312 
	C679.026855,194.794968 680.131042,199.531921 682.940063,200.670776 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M671.982178,195.594696 
	C669.120300,194.506119 666.209351,193.062241 663.149902,191.308563 
	C665.978577,192.412323 668.955811,193.825851 671.982178,195.594696 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M717.994751,218.579926 
	C715.822815,217.804779 713.599487,216.688675 711.186890,215.298370 
	C714.799011,212.884872 716.004578,216.354034 717.994751,218.579926 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M923.972778,321.616150 
	C921.385681,320.548737 918.737549,319.140625 916.020752,317.396240 
	C918.605347,318.465149 921.258484,319.870300 923.972778,321.616150 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M358.677307,322.074036 
	C356.587677,323.680786 354.134338,325.298492 351.316345,326.932373 
	C353.405701,325.327362 355.859650,323.706146 358.677307,322.074036 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M696.942139,207.656494 
	C694.427979,206.884842 691.864014,205.758759 689.145264,204.321289 
	C691.624329,205.107315 694.258362,206.204697 696.942139,207.656494 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M529.785706,236.721771 
	C528.231445,237.957825 526.467529,238.947449 524.347412,239.962433 
	C525.852783,238.816971 527.714478,237.646149 529.785706,236.721771 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M1049.982178,384.610535 
	C1048.048828,383.879425 1046.069214,382.795441 1044.028809,381.374329 
	C1045.957275,382.110657 1047.946655,383.184174 1049.982178,384.610535 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M610.798340,195.735687 
	C609.237183,196.971497 607.476807,197.960556 605.361694,198.979523 
	C606.871033,197.835922 608.734985,196.662415 610.798340,195.735687 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M726.948669,222.637360 
	C725.107117,222.200455 723.207642,221.409988 721.158813,220.305359 
	C722.969849,220.755402 724.930359,221.519608 726.948669,222.637360 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M544.673523,229.029602 
	C543.605408,230.009750 542.165100,230.976746 540.366943,231.977631 
	C541.439758,231.013168 542.870605,230.014801 544.673523,229.029602 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M573.701172,214.053009 
	C572.625549,215.034546 571.175476,215.991745 569.367554,216.980774 
	C570.448792,216.017944 571.887756,215.023300 573.701172,214.053009 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M687.971558,203.605835 
	C686.441101,203.186813 684.862549,202.403809 683.135498,201.319275 
	C684.632507,201.759125 686.277893,202.500504 687.971558,203.605835 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M661.975037,190.596619 
	C660.447815,190.175491 658.869690,189.391739 657.145752,188.303986 
	C658.641357,188.744675 660.282776,189.489334 661.975037,190.596619 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M1001.966187,360.619995 
	C1000.450989,360.174988 998.875732,359.384125 997.158569,358.287231 
	C998.646484,358.745544 1000.276306,359.509857 1001.966187,360.619995 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M732.971558,225.649185 
	C731.791626,225.507996 730.578430,225.023041 729.183594,224.262695 
	C730.314087,224.426682 731.626160,224.866043 732.971558,225.649185 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M521.791382,240.722885 
	C520.885620,241.639694 519.773560,242.296494 518.326538,242.970886 
	C519.189392,242.146591 520.387207,241.304733 521.791382,240.722885 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M462.670288,270.039612 
	C461.912170,271.013794 460.787811,271.970917 459.313751,272.945709 
	C460.077393,271.983124 461.190735,271.002869 462.670288,270.039612 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M899.984131,309.599243 
	C898.727539,309.187653 897.404785,308.445587 896.026489,307.368347 
	C897.286560,307.778412 898.602295,308.523621 899.984131,309.599243 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M851.973267,285.628021 
	C850.713440,285.218628 849.397400,284.473877 848.013062,283.400452 
	C849.268921,283.812073 850.592957,284.552368 851.973267,285.628021 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M1057.973389,388.628845 
	C1056.713135,388.205841 1055.398804,387.447876 1054.034180,386.353394 
	C1055.295776,386.775848 1056.607544,387.534882 1057.973389,388.628845 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M937.983398,328.604919 
	C936.725830,328.197235 935.405273,327.457703 934.019653,326.387207 
	C935.276550,326.795227 936.598511,327.534149 937.983398,328.604919 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M757.977783,238.662964 
	C757.097412,238.523682 756.185425,238.061096 755.135559,237.306854 
	C755.980530,237.456680 756.963379,237.898163 757.977783,238.662964 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M508.817017,246.759445 
	C508.208984,247.651718 507.417236,248.299225 506.299896,248.954071 
	C506.860626,248.145828 507.746979,247.330261 508.817017,246.759445 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M635.804077,184.747543 
	C635.222168,185.338791 634.444336,185.672180 633.326538,185.992340 
	C633.860413,185.482620 634.734253,184.986145 635.804077,184.747543 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M651.965820,185.659912 
	C651.141968,185.836060 650.273682,185.687546 649.202698,185.272614 
	C649.973755,185.115875 650.947571,185.225571 651.965820,185.659912 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M629.799316,186.730499 
	C629.226379,187.328537 628.454956,187.671127 627.344360,188.010056 
	C627.870422,187.495956 628.735657,186.985504 629.799316,186.730499 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M582.671143,210.021271 
	C582.232849,210.674530 581.437134,211.317688 580.318848,211.977783 
	C580.768799,211.333557 581.541260,210.672379 582.671143,210.021271 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M560.651245,221.003967 
	C560.219299,221.656570 559.431824,222.306396 558.322144,222.977692 
	C558.765259,222.333191 559.530518,221.667191 560.651245,221.003967 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M626.650757,188.004410 
	C626.213501,188.654831 625.421997,189.304092 624.305420,189.961304 
	C624.752502,189.313919 625.524536,188.658585 626.650757,188.004410 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M589.744934,206.070694 
	C589.289795,206.723495 588.503723,207.370926 587.366333,208.022690 
	C587.814758,207.373123 588.614380,206.719223 589.744934,206.070694 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M602.649414,200.003708 
	C602.216797,200.655731 601.429443,201.307434 600.318542,201.975693 
	C600.761597,201.329285 601.528198,200.666351 602.649414,200.003708 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M578.672974,212.022827 
	C578.233398,212.675644 577.436401,213.317673 576.316589,213.975266 
	C576.767700,213.331238 577.541626,212.671631 578.672974,212.022827 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M845.971191,282.618347 
	C845.115967,282.497223 844.199646,282.035736 843.147339,281.280365 
	C843.977600,281.416962 844.943848,281.847473 845.971191,282.618347 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M867.981506,293.592072 
	C867.128540,293.477234 866.206482,293.026001 865.149536,292.278870 
	C865.980591,292.407196 866.946472,292.831451 867.981506,293.592072 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M1064.018433,391.608459 
	C1063.491089,391.744965 1062.909790,391.602142 1062.164795,391.196106 
	C1062.655518,391.064911 1063.310059,391.197021 1064.018433,391.608459 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M597.749512,202.088638 
	C597.599426,202.697647 597.118347,203.315689 596.317383,203.964874 
	C596.471191,203.363205 596.944824,202.730438 597.749512,202.088638 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M855.987488,287.627319 
	C855.384888,287.520355 854.727417,287.096527 854.019531,286.353760 
	C854.623657,286.460052 855.278137,286.885284 855.987488,287.627319 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M708.967957,213.715851 
	C708.434143,213.843536 707.877380,213.675446 707.160339,213.256195 
	C707.648376,213.143372 708.296692,213.281723 708.967957,213.715851 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M564.696289,219.043808 
	C564.550049,219.643066 564.082275,220.272217 563.288818,220.920135 
	C563.433655,220.317169 563.904236,219.695419 564.696289,219.043808 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M736.977173,227.699341 
	C736.443726,227.827209 735.882507,227.661621 735.161682,227.241791 
	C735.651123,227.126968 736.300293,227.266418 736.977173,227.699341 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M944.001953,331.655518 
	C943.470215,331.780243 942.896606,331.618896 942.161987,331.195679 
	C942.653992,331.079010 943.307007,331.224243 944.001953,331.655518 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M533.826660,234.755417 
	C533.534485,235.315781 533.071289,235.646500 532.307678,235.993042 
	C532.556702,235.514496 533.106140,235.020126 533.826660,234.755417 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M585.751648,208.091125 
	C585.600891,208.699860 585.119019,209.317459 584.317383,209.966370 
	C584.471924,209.365112 584.946228,208.732544 585.751648,208.091125 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M656.972778,187.706116 
	C656.440186,187.833801 655.880432,187.667587 655.160278,187.248199 
	C655.648560,187.134079 656.297058,187.273163 656.972778,187.706116 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1024.107056,375.552216 
	C1021.512390,374.481293 1018.804932,373.082123 1016.045105,371.337097 
	C1018.659851,372.402161 1021.327026,373.813049 1024.107056,375.552216 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1050.102295,388.556274 
	C1047.507568,387.488342 1044.803101,386.090973 1042.044434,384.349548 
	C1044.657593,385.412598 1047.325073,386.819733 1050.102295,388.556274 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M828.940186,277.643890 
	C826.777466,276.848755 824.438721,275.757507 822.049133,274.312683 
	C824.253601,275.088654 826.508789,276.218201 828.940186,277.643890 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1034.105591,380.550842 
	C1031.906372,379.767456 1029.598389,378.649689 1027.164795,377.202332 
	C1029.358276,377.987274 1031.677612,379.101837 1034.105591,380.550842 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1010.102661,368.561554 
	C1008.170654,367.813995 1006.134460,366.737549 1004.054565,365.312653 
	C1006.006714,366.053711 1008.002686,367.143188 1010.102661,368.561554 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1080.111572,403.550415 
	C1078.182251,402.807495 1076.138916,401.739349 1074.045288,400.324402 
	C1075.995850,401.060120 1077.996582,402.142639 1080.111572,403.550415 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1062.116333,394.551392 
	C1060.180664,393.810455 1058.138062,392.741028 1056.045288,391.324951 
	C1057.999878,392.059814 1060.004639,393.141357 1062.116333,394.551392 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M996.101685,361.564056 
	C994.837585,361.150452 993.466248,360.414246 992.044312,359.332611 
	C993.327332,359.738617 994.660889,360.490051 996.101685,361.564056 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M946.126160,336.545593 
	C944.857666,336.135834 943.476318,335.405090 942.044434,334.327271 
	C943.333740,334.728333 944.673523,335.476440 946.126160,336.545593 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M949.812378,337.686340 
	C949.097839,337.790192 948.165833,337.638397 947.109985,337.196533 
	C947.855774,337.081238 948.725342,337.255981 949.812378,337.686340 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M972.978638,349.637207 
	C972.139465,349.503937 971.124084,349.095490 970.052063,348.331268 
	C970.931091,348.437683 971.866760,348.899902 972.978638,349.637207 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M842.134705,284.561218 
	C841.524292,284.459747 840.807983,284.052307 840.045044,283.303406 
	C840.675171,283.393066 841.352051,283.824158 842.134705,284.561218 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1054.132080,390.564087 
	C1053.521118,390.466614 1052.806274,390.063080 1052.043335,389.320923 
	C1052.672852,389.407562 1053.350708,389.832825 1054.132080,390.564087 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1066.116211,396.577423 
	C1065.505737,396.480103 1064.797607,396.073547 1064.039917,395.331665 
	C1064.666382,395.420288 1065.342529,395.844238 1066.116211,396.577423 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1072.120972,399.573792 
	C1071.509399,399.477112 1070.799438,399.071533 1070.039917,398.329956 
	C1070.667725,398.417603 1071.345215,398.841248 1072.120972,399.573792 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M956.128235,341.565521 
	C955.520020,341.464691 954.806030,341.058319 954.043823,340.312531 
	C954.671204,340.402039 955.346863,340.831024 956.128235,341.565521 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M838.118774,282.575653 
	C837.510620,282.463318 836.802917,282.044373 836.059082,281.282288 
	C836.688354,281.382477 837.353821,281.825775 838.118774,282.575653 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M1001.848816,363.727905 
	C1001.364929,363.838409 1000.710388,363.703217 1000.026123,363.270081 
	C1000.556946,363.142151 1001.117615,363.312164 1001.848816,363.727905 
z"/>
<path fill="#F65F60" opacity="1.000000" stroke="none" 
	d="
M337.832184,335.675934 
	C337.220428,336.558563 336.398102,337.262787 335.191589,337.940613 
	C335.745453,337.108673 336.683533,336.303101 337.832184,335.675934 
z"/>
<path fill="#F65F60" opacity="1.000000" stroke="none" 
	d="
M238.665176,385.907654 
	C238.168289,386.568329 237.339584,387.256256 236.122711,387.904602 
	C236.600815,387.221680 237.467087,386.578308 238.665176,385.907654 
z"/>
<path fill="#F65F60" opacity="1.000000" stroke="none" 
	d="
M200.699188,404.925232 
	C200.419876,405.390472 199.840607,405.875122 199.090973,406.103760 
	C199.413483,405.546722 199.906357,405.245667 200.699188,404.925232 
z"/>
<path fill="#F65F60" opacity="1.000000" stroke="none" 
	d="
M333.677612,337.965332 
	C333.475830,338.581451 332.947693,339.236572 332.057007,339.875458 
	C332.246704,339.240906 332.798981,338.622620 333.677612,337.965332 
z"/>
<path fill="#F65F60" opacity="1.000000" stroke="none" 
	d="
M325.678223,341.992889 
	C325.480835,342.604889 324.955444,343.250031 324.072083,343.882843 
	C324.259491,343.255676 324.804840,342.640869 325.678223,341.992889 
z"/>
<path fill="#F65F60" opacity="1.000000" stroke="none" 
	d="
M317.680603,346.009705 
	C317.486359,346.621704 316.964294,347.260986 316.087646,347.890076 
	C316.272980,347.265564 316.812897,346.651306 317.680603,346.009705 
z"/>
<path fill="#F65F60" opacity="1.000000" stroke="none" 
	d="
M342.679138,333.055756 
	C342.523529,333.664276 342.037537,334.297546 341.217651,334.962830 
	C341.372070,334.356720 341.860413,333.718628 342.679138,333.055756 
z"/>
<path fill="#F65F60" opacity="1.000000" stroke="none" 
	d="
M314.666107,347.932312 
	C314.461700,348.556396 313.925232,349.218292 313.020447,349.858887 
	C313.212769,349.215118 313.773407,348.592651 314.666107,347.932312 
z"/>
<path fill="#FFFEFE" opacity="1.000000" stroke="none" 
	d="
M192.752121,854.273315 
	C192.778366,851.842957 192.986542,849.688843 192.986771,847.534729 
	C193.001984,706.731873 193.021271,565.929016 192.910812,425.126221 
	C192.907059,420.333496 193.951889,417.756531 198.756348,415.489349 
	C221.604004,404.707764 244.190582,393.368988 266.803101,382.094116 
	C290.319733,370.368408 313.748138,358.465820 337.236206,346.682526 
	C363.709229,333.401794 390.190704,320.137695 416.695862,306.921082 
	C450.792755,289.918854 484.947357,273.032104 519.021057,255.983643 
	C558.164673,236.398514 597.268677,216.733749 636.322327,196.970139 
	C640.220337,194.997528 643.427307,194.635376 647.512512,196.684326 
	C744.455444,245.305725 841.461609,293.801117 938.455261,342.321442 
	C988.357605,367.284729 1038.258179,392.251740 1088.682861,417.433228 
	C1089.449707,417.857513 1089.731812,417.959686 1090.052124,417.955902 
	C1090.367554,418.074249 1090.619019,418.273254 1090.902466,419.013672 
	C1091.070923,419.800629 1091.247803,420.053833 1091.529053,420.234070 
	C1091.581177,423.528046 1091.633301,426.822021 1091.774658,430.565002 
	C1091.781372,432.172394 1091.626343,433.330750 1091.626343,434.489136 
	C1091.625366,574.360718 1091.635864,714.232361 1091.704712,854.573547 
	C1091.534912,858.403198 1091.306152,861.763245 1091.040039,865.560425 
	C1077.760010,872.748108 1064.550903,879.565125 1051.266235,886.231506 
	C1036.501587,893.640564 1021.657227,900.890747 1006.868103,908.251404 
	C996.513794,913.404663 986.192810,918.624756 975.859192,923.819397 
	C961.825439,930.874268 947.789734,937.925110 933.762329,944.992676 
	C918.261658,952.802490 902.805115,960.700867 887.263611,968.428467 
	C871.560974,976.236206 855.729309,983.784973 840.042908,991.624756 
	C823.482361,999.901550 807.061829,1008.458130 790.512695,1016.757996 
	C779.580444,1022.240967 768.477234,1027.382935 757.543945,1032.863770 
	C741.866272,1040.723022 726.305481,1048.815674 710.636108,1056.691650 
	C694.374146,1064.865601 678.033081,1072.881958 661.746094,1081.006348 
	C656.561768,1083.592407 651.367554,1086.174438 646.316711,1089.005981 
	C643.453613,1090.610962 641.111511,1090.738892 638.033142,1089.170776 
	C613.466431,1076.655884 588.781433,1064.373657 564.154175,1051.977295 
	C543.253784,1041.456909 522.383911,1030.875610 501.496826,1020.328674 
	C481.372589,1010.166870 461.297546,999.905823 441.102570,989.886475 
	C417.058777,977.957703 392.849030,966.362854 368.828186,954.388611 
	C352.216156,946.107666 335.864838,937.304688 319.279205,928.969421 
	C306.860657,922.728394 294.201813,916.966919 281.746643,910.796692 
	C270.044952,904.999756 258.494293,898.898865 246.832672,893.019897 
	C234.303070,886.703430 221.698059,880.536499 209.174240,874.208679 
	C204.022873,871.606018 198.997543,868.753906 193.588943,865.929932 
	C193.263000,865.844543 193.120453,865.852539 193.155884,865.469238 
	C193.090775,862.372314 192.990219,859.658752 192.920395,856.630737 
	C192.857132,855.911743 192.763138,855.507141 192.669144,855.102539 
	C192.725098,854.918213 192.781036,854.733887 192.752121,854.273315 
M679.756958,359.583893 
	C679.568481,359.317566 679.340454,359.091125 679.027710,358.256744 
	C678.328735,357.183044 677.629822,356.109344 676.907898,354.352478 
	C674.934448,352.571716 672.960938,350.790985 670.725037,348.404358 
	C669.473328,347.619965 668.221558,346.835541 666.422302,345.754730 
	C665.663208,345.456329 664.904053,345.157898 663.975037,344.189758 
	C652.893005,335.589508 639.470886,332.333618 626.525146,328.162048 
	C618.097351,325.446350 609.217529,324.158905 600.721069,321.625305 
	C583.107910,316.373199 564.959839,314.760132 546.806641,313.202423 
	C544.005432,312.962036 541.088684,314.550385 538.179993,314.999634 
	C522.563965,317.411713 506.748688,318.904785 491.367615,322.347565 
	C479.386444,325.029327 467.922089,330.048645 456.280212,334.176208 
	C455.020813,334.622772 454.108154,336.047363 453.004242,337.016846 
	C453.004242,337.016846 453.017944,337.034576 452.274017,337.022736 
	C449.853271,338.358887 447.432526,339.695038 444.408691,341.060699 
	C443.948303,341.397583 443.487915,341.734436 442.333221,342.088928 
	C441.145813,343.012146 439.958374,343.935333 438.166443,344.926880 
	C437.444366,345.285400 436.722290,345.643921 435.358124,346.043579 
	C434.566345,346.690948 433.774536,347.338318 433.001221,347.998779 
	C433.001221,347.998779 432.986206,347.979370 432.351959,348.062958 
	C431.829437,348.665466 431.306885,349.268005 430.045959,349.904114 
	C423.718231,354.280487 417.390472,358.656860 411.018066,363.025391 
	C411.018066,363.025391 411.034393,363.059814 410.371490,363.080841 
	C409.238373,364.047943 408.105255,365.015015 406.538666,366.194092 
	C406.267853,366.389648 406.081421,366.646484 405.310638,367.054321 
	C404.169556,368.310455 403.028503,369.566559 401.880707,370.868622 
	C401.880707,370.868622 401.904816,370.908264 401.417969,371.076294 
	C401.197296,371.335419 400.976593,371.594513 400.377197,371.910187 
	C400.377197,371.910187 400.017914,372.042664 399.391846,372.264496 
	C398.195038,373.458862 396.998199,374.653259 395.156311,376.032806 
	C388.092834,383.685852 381.029358,391.338898 373.799622,399.274384 
	C373.799622,399.274384 373.764587,399.600189 373.153473,399.862366 
	C372.363586,400.869202 371.573700,401.876038 370.230774,403.251068 
	C368.426147,406.050720 366.621521,408.850372 364.276367,412.085083 
	C354.895721,426.068268 347.975677,441.036224 345.521759,458.572205 
	C345.411224,460.060394 345.300690,461.548584 344.670532,463.502899 
	C344.727509,464.389984 344.784485,465.277100 344.490753,467.011688 
	C342.475189,485.398132 345.731873,502.526520 357.056183,517.540466 
	C365.935089,529.312256 377.474182,537.997620 390.398041,544.863159 
	C423.947327,562.685730 460.276672,569.330139 497.736816,570.595215 
	C521.707458,571.404663 545.624878,572.239685 569.388184,575.804993 
	C591.395142,579.106812 610.146851,587.104919 619.535461,609.356384 
	C619.578674,609.674683 619.760254,609.816162 620.153198,610.591064 
	C620.684631,612.415527 621.216125,614.239990 621.494995,616.394165 
	C621.499207,616.726257 621.664917,616.921814 622.050537,617.785339 
	C622.677856,623.492371 623.305237,629.199402 623.547302,635.153137 
	C623.321838,635.463745 623.364014,635.732300 623.401306,636.589539 
	C623.162720,638.708374 622.924072,640.827271 622.063293,643.152832 
	C620.982056,645.960144 619.900879,648.767517 618.345642,651.904663 
	C618.190552,652.247131 618.035461,652.589661 617.992004,652.931763 
	C617.992004,652.931763 617.888123,652.890869 617.291748,653.188782 
	C615.860046,655.124268 614.428345,657.059753 612.378418,659.246643 
	C608.933899,662.524536 605.489441,665.802429 601.402588,669.095276 
	C600.893616,669.694946 600.384705,670.294617 599.387695,671.139648 
	C598.935974,671.444763 598.484192,671.749939 597.366699,672.069763 
	C596.255981,673.056702 595.145325,674.043640 594.022461,675.016296 
	C594.022461,675.016296 594.028687,675.042175 593.319580,675.089844 
	C577.642334,682.638428 562.391357,691.310364 546.183472,697.460510 
	C524.333923,705.751343 501.361633,710.648438 477.872986,710.833679 
	C463.681000,710.945679 449.408264,709.528870 435.300476,707.768188 
	C428.723785,706.947449 422.334076,703.968872 416.086334,701.414001 
	C412.170593,699.812744 408.676666,697.179993 404.611969,694.471436 
	C401.482330,691.883484 398.352661,689.295593 395.029358,686.103943 
	C393.724762,684.722839 392.420197,683.341736 391.008972,681.227722 
	C386.321655,673.501953 381.634369,665.776184 376.754883,657.394470 
	C374.562195,655.536255 372.369537,653.678040 369.985016,651.152405 
	C366.975342,649.599182 364.089752,647.704346 360.932404,646.557678 
	C351.958099,643.298279 342.612335,643.419312 333.466858,645.259460 
	C328.742706,646.210083 324.373383,648.924011 319.116821,650.892822 
	C316.721313,652.848877 314.325806,654.804871 311.307861,657.040894 
	C299.574127,669.590698 298.246368,684.795654 300.920715,700.626770 
	C301.932190,706.614136 304.868652,712.276245 307.023956,718.729187 
	C307.216064,719.113708 307.408173,719.498169 307.441620,720.524841 
	C308.343750,721.924561 309.245911,723.324341 310.130096,724.858582 
	C310.130096,724.858582 310.262543,724.888245 310.203857,725.510132 
	C310.846313,726.241516 311.488800,726.972961 312.319305,728.320129 
	C312.682404,728.850220 313.045502,729.380371 313.322144,730.503052 
	C313.859161,731.024536 314.396210,731.546021 315.203552,732.679382 
	C316.626740,734.441406 318.049896,736.203491 319.430908,738.359985 
	C319.430908,738.359985 319.711578,738.640137 319.711578,738.640137 
	C319.711578,738.640137 320.107422,738.610840 320.162018,738.796204 
	C320.162018,738.796204 320.346191,738.854431 320.333313,739.523376 
	C323.573822,742.607361 326.814331,745.691345 330.476013,749.220276 
	C330.803406,749.463440 331.130768,749.706543 331.401642,750.312622 
	C331.401642,750.312622 331.684601,750.544800 331.684601,750.544800 
	C331.684601,750.544800 332.024231,750.407166 332.442749,750.891479 
	C332.948364,751.275879 333.453979,751.660339 334.093292,752.630432 
	C334.753174,752.974976 335.413055,753.319519 336.468384,754.101318 
	C336.957733,754.420776 337.447083,754.740234 338.073853,755.653320 
	C338.736969,756.009460 339.400055,756.365662 340.388489,757.012756 
	C340.388489,757.012756 340.801880,757.152649 341.008820,757.727722 
	C341.691681,758.065674 342.374542,758.403625 343.490143,759.139404 
	C343.994141,759.432800 344.498138,759.726135 345.122925,760.610413 
	C345.777802,760.942627 346.432648,761.274841 347.427032,761.929993 
	C347.427032,761.929993 347.863159,762.101135 348.041473,762.680298 
	C348.718933,762.990845 349.396393,763.301453 350.565430,764.033752 
	C351.343872,764.383118 352.122314,764.732483 353.114502,765.706970 
	C355.100708,766.695312 357.086884,767.683655 359.577393,769.067322 
	C360.153107,769.333557 360.728790,769.599731 361.530823,770.485474 
	C368.963287,773.618225 376.316803,776.960449 383.859009,779.801941 
	C388.109955,781.403503 392.671722,782.180176 397.725037,783.609009 
	C398.182312,783.632019 398.639618,783.655029 399.432800,784.187622 
	C399.998291,784.208740 400.563782,784.229858 401.727936,784.586121 
	C402.180054,784.627869 402.632172,784.669617 403.601624,785.240906 
	C409.773102,786.250854 415.944611,787.260803 422.828552,788.546326 
	C423.588257,788.567200 424.347961,788.588074 425.515076,789.155884 
	C426.380493,789.184326 427.245880,789.212769 428.851715,789.594482 
	C429.938354,789.626038 431.024963,789.657593 432.694183,790.208679 
	C435.498779,790.270752 438.303375,790.332886 441.820374,790.656372 
	C442.574127,790.679749 443.327881,790.703186 444.707062,791.227173 
	C458.847809,791.338562 472.988464,791.472351 487.129425,791.522766 
	C488.387360,791.527222 489.647186,790.989868 491.663727,790.791809 
	C492.405701,790.651123 493.147705,790.510498 494.736603,790.719421 
	C497.788666,790.385376 500.840698,790.051331 504.736420,789.835449 
	C506.125214,789.658813 507.514038,789.482117 509.626617,789.616699 
	C511.038940,789.265015 512.451233,788.913330 514.672729,788.687927 
	C515.742249,788.543701 516.811829,788.399475 518.566406,788.587158 
	C519.656860,788.233398 520.747375,787.879700 522.540649,787.611206 
	C522.992432,787.490784 523.444214,787.370361 524.707031,787.603394 
	C530.112061,786.652344 535.517029,785.701355 541.682068,784.819275 
	C542.413818,784.643188 543.145630,784.467102 544.506104,784.584106 
	C545.298645,784.273376 546.091125,783.962646 547.655151,783.739197 
	C548.404053,783.579102 549.152893,783.418945 550.648010,783.543396 
	C564.385132,779.904480 578.208679,776.556274 591.823669,772.506775 
	C598.872498,770.410217 605.551575,767.070679 613.060425,764.184082 
	C613.674377,763.924316 614.288391,763.664612 615.610901,763.578186 
	C619.607727,761.676575 623.604553,759.774963 628.209045,757.628479 
	C628.782410,757.313904 629.355774,756.999268 630.586121,756.800720 
	C631.412292,756.220947 632.238464,755.641174 633.755066,754.928711 
	C635.807373,753.806030 637.859680,752.683350 640.759338,751.691467 
	C641.549255,752.728577 642.712524,753.664368 643.029175,754.830017 
	C643.378784,756.117126 642.952087,757.615173 642.723511,759.667603 
	C642.694153,760.323975 642.664795,760.980286 642.082581,762.017151 
	C641.953247,763.050476 641.823914,764.083862 641.409302,765.746582 
	C641.416199,766.203369 641.423035,766.660095 640.860168,767.572693 
	C637.796753,779.098511 634.733337,790.624390 631.343018,802.740662 
	C631.314331,803.186584 631.285583,803.632568 630.679260,804.495972 
	C627.699097,815.052429 624.718994,825.608887 621.398376,836.748718 
	C621.367920,837.194458 621.337463,837.640198 620.723572,838.516052 
	C618.562683,846.528625 616.215088,854.497009 614.308777,862.569702 
	C613.104126,867.671082 612.558472,872.928162 611.429565,878.752380 
	C611.446472,879.213196 611.463318,879.673950 610.896179,880.570190 
	C610.845825,881.764038 610.795471,882.957886 610.435425,884.898376 
	C610.415833,885.972168 610.396240,887.046021 609.804810,888.604858 
	C609.730591,890.117126 609.656433,891.629456 609.310547,893.930542 
	C609.319336,895.318787 609.328064,896.707031 608.796082,898.688538 
	C608.636169,905.322388 606.676086,912.132324 610.821106,919.038635 
	C611.138855,919.644165 611.456543,920.249695 611.607605,921.449280 
	C612.370422,922.344238 613.133240,923.239197 614.165161,924.723450 
	C615.738159,926.182556 617.311096,927.641663 619.111938,929.741455 
	C622.988403,932.494934 627.052368,934.549316 632.846436,933.327515 
	C634.222595,933.286743 635.598755,933.245972 637.532715,933.612183 
	C641.060120,932.107056 644.587524,930.601929 648.687988,928.804749 
	C650.186340,927.282227 651.684692,925.759766 653.746338,924.005859 
	C654.051147,923.312683 654.355896,922.619507 654.945068,921.546753 
	C654.945068,921.546753 655.072815,921.089966 655.708984,920.861023 
	C661.405762,912.401550 662.680481,902.750061 663.526367,892.180176 
	C663.537048,891.405701 663.547729,890.631226 664.135681,889.426636 
	C664.221924,888.247314 664.308167,887.068054 664.674866,885.184265 
	C664.692322,884.430420 664.709717,883.676514 665.263977,882.391296 
	C666.615906,874.897156 667.967834,867.403015 669.612244,859.279358 
	C669.640381,858.820435 669.668518,858.361511 670.260986,857.438416 
	C673.252808,845.259338 676.244629,833.080261 679.557190,820.276794 
	C679.585449,819.813171 679.613647,819.349487 680.208862,818.420349 
	C683.243652,807.251282 686.278442,796.082214 689.313232,784.913147 
	C689.680725,784.985352 690.048279,785.057556 690.415771,785.129700 
	C691.836731,789.227661 693.257629,793.325562 694.630310,797.949036 
	C694.695618,798.293579 694.760864,798.638062 694.470764,799.558411 
	C695.868958,804.512329 697.267151,809.466248 698.639587,814.961975 
	C698.737854,815.307922 698.836121,815.653870 698.593201,816.545959 
	C700.606567,823.517578 702.619934,830.489136 704.589661,837.976440 
	C704.666321,838.311157 704.743042,838.645874 704.507324,839.546936 
	C711.249451,862.882507 717.930786,886.236023 724.825684,909.526367 
	C725.533569,911.917480 727.518616,913.930542 729.038025,916.563171 
	C729.038025,916.563171 729.322510,916.927124 729.241394,917.523132 
	C729.757629,918.071472 730.273865,918.619873 730.903015,918.983521 
	C730.903015,918.983521 730.806091,919.173035 730.888123,919.847778 
	C732.281860,921.167847 733.675598,922.487915 735.332703,924.304321 
	C735.797119,924.602844 736.261597,924.901367 736.978821,925.676025 
	C737.301636,925.806213 737.624512,925.936401 738.323730,926.497620 
	C738.853394,926.690430 739.382996,926.883240 740.160095,927.691040 
	C741.806580,928.201782 743.453003,928.712585 745.793091,929.581909 
	C746.565369,929.617249 747.337585,929.652527 748.598389,930.181458 
	C750.361145,930.020752 752.123840,929.860046 754.662292,929.794250 
	C755.409973,929.609741 756.157715,929.425171 757.590759,929.488403 
	C761.099182,927.690491 764.607605,925.892578 768.691467,923.803528 
	C770.165833,922.251343 771.640137,920.699097 773.750793,918.942810 
	C775.375122,916.657715 777.472534,914.573730 778.543579,912.053528 
	C783.734253,899.839905 788.904297,887.604736 793.564941,875.183411 
	C796.283630,867.937683 798.031372,860.327759 800.590881,852.298401 
	C800.656433,851.844666 800.721924,851.390869 801.329041,850.497070 
	C802.415283,847.305481 803.501465,844.113892 804.853943,840.308228 
	C804.992371,839.654358 805.130737,839.000488 805.843140,837.958191 
	C808.722778,829.605225 811.602356,821.252258 814.795288,812.313232 
	C814.971741,811.674194 815.148193,811.035095 815.869568,809.973511 
	C817.326904,805.608948 818.784180,801.244324 820.631287,796.253113 
	C820.852844,795.293762 821.074402,794.334473 821.866577,792.976929 
	C823.866272,787.680481 825.865967,782.384033 828.019043,776.681213 
	C829.158997,778.650391 829.943787,780.006104 830.684875,781.910034 
	C830.750793,782.269714 830.816650,782.629395 830.496277,783.590271 
	C831.544128,788.550110 832.591919,793.509949 833.569519,798.976074 
	C833.643738,799.306396 833.717896,799.636780 833.444214,800.424683 
	C833.847717,801.428223 834.251221,802.431702 834.604553,804.075500 
	C834.683167,804.711182 834.761780,805.346924 834.489136,806.578613 
	C835.873047,812.532776 837.256958,818.486877 838.575195,824.957886 
	C838.645142,825.296143 838.715088,825.634460 838.433411,826.543884 
	C839.176697,829.172668 839.919983,831.801392 840.645142,835.079956 
	C840.742859,835.721069 840.840576,836.362244 840.579285,837.569397 
	C841.938660,842.523987 843.298096,847.478516 844.606934,852.955139 
	C844.678223,853.295959 844.749512,853.636841 844.478210,854.558350 
	C845.863464,859.187561 847.248779,863.816772 848.614075,868.974976 
	C848.708374,869.313477 848.802734,869.651978 848.523376,870.523926 
	C849.236694,872.824951 849.950012,875.126038 850.619385,877.950012 
	C850.683472,878.292908 850.747498,878.635864 850.472595,879.562805 
	C851.862061,884.524536 853.251526,889.486206 854.585938,894.964294 
	C854.657959,895.301270 854.730042,895.638184 854.457947,896.532288 
	C855.204102,898.825256 855.950256,901.118286 856.678162,903.947937 
	C856.756042,904.296448 856.833923,904.644897 856.551270,905.540771 
	C857.948914,909.141113 859.346558,912.741516 860.835510,916.929382 
	C861.013611,917.281006 861.191711,917.632690 861.303040,918.437622 
	C861.398132,918.763855 861.611206,918.965454 862.000000,919.006470 
	C862.000000,919.006470 861.944641,919.050171 861.933533,919.713989 
	C862.670654,920.699585 863.407837,921.685120 864.295715,923.253113 
	C864.776184,923.886169 865.256714,924.519287 865.856323,925.839294 
	C868.267578,928.094299 870.678833,930.349365 873.447937,932.927307 
	C873.447937,932.927307 873.904907,933.080750 874.143372,933.737305 
	C882.175171,939.150085 896.176880,937.883667 902.653259,931.076355 
	C902.653259,931.076355 902.964050,930.813416 903.635071,930.796936 
	C911.026123,925.024902 914.127808,917.618591 911.704285,908.400635 
	C903.626099,877.674500 894.510010,847.185120 887.569336,816.206665 
	C881.069275,787.194702 876.812012,757.679443 871.604187,728.379150 
	C868.898682,713.157776 866.891296,697.776367 863.348633,682.755005 
	C861.571777,675.220825 857.064026,668.330688 853.718872,660.570679 
	C853.211792,660.002502 852.704712,659.434265 851.914429,658.244873 
	C849.646790,656.463745 847.379211,654.682678 844.915527,652.167664 
	C841.105164,649.399414 824.034729,648.940247 820.471741,651.558044 
	C820.131653,651.807922 820.056824,652.418884 819.156250,652.926147 
	C816.748962,654.934204 814.341675,656.942322 811.368896,659.293884 
	C810.166016,661.130249 808.963196,662.966553 807.189514,665.242310 
	C805.133667,671.163391 803.077759,677.084534 800.851929,683.743347 
	C800.920532,684.492126 800.989075,685.240845 800.696960,686.571655 
	C800.471436,687.382751 800.245972,688.193787 799.950562,689.668030 
	C799.974976,690.110535 799.999390,690.553040 799.637329,691.535889 
	C799.159607,692.712341 798.681946,693.888855 797.639832,695.544373 
	C795.622986,704.055664 793.606201,712.566895 791.284424,721.689819 
	C791.236328,722.140198 791.188232,722.590637 790.610413,723.504150 
	C789.962341,726.028320 789.314331,728.552429 788.352844,731.690430 
	C788.282166,732.141846 788.211548,732.593201 787.604126,733.467468 
	C787.299255,735.005371 786.994324,736.543213 786.350220,738.687561 
	C786.294067,739.143860 786.237976,739.600159 785.623962,740.510742 
	C783.355042,748.365784 781.086121,756.220886 778.463928,764.683228 
	C778.353455,765.135864 778.243042,765.588501 777.552490,766.466309 
	C770.275330,787.787476 762.998230,809.108643 755.316650,831.614807 
	C753.594421,825.254517 752.320923,819.978149 750.706604,814.808105 
	C749.758362,811.771179 748.268127,808.903564 747.054138,805.474731 
	C747.054138,805.474731 746.964355,804.997070 747.153076,804.463013 
	C746.775696,803.636475 746.398315,802.809998 746.000000,802.000000 
	C746.000000,802.000000 746.020081,801.985168 746.135925,801.251892 
	C746.062622,799.635681 746.204651,797.975342 745.884094,796.409790 
	C740.186157,768.586853 734.702454,740.715820 728.581970,712.985474 
	C725.258789,697.928772 720.771057,683.131348 716.944824,668.182068 
	C715.981689,664.419189 714.982788,660.523376 714.975952,656.686951 
	C714.960938,648.251892 715.830811,639.817078 715.872009,631.379883 
	C715.983887,608.478149 710.476990,586.870117 699.312378,566.928040 
	C695.813110,560.677673 690.568970,555.404297 686.028809,549.051453 
	C684.088806,546.989807 682.148804,544.928162 680.034790,542.209167 
	C676.421204,539.078979 672.807617,535.948853 668.998840,532.221619 
	C668.327454,531.822083 667.656067,531.422485 666.592468,530.478943 
	C662.760132,527.958740 658.927856,525.438538 654.952393,522.239807 
	C644.216003,517.187195 633.853088,510.971558 622.649353,507.374695 
	C608.457581,502.818542 593.648376,500.052734 578.974365,497.185944 
	C571.395447,495.705322 563.544373,495.474701 555.796082,495.034668 
	C541.566895,494.226562 527.318970,493.752625 513.085999,493.003632 
	C502.179169,492.429718 491.230530,492.087769 480.402435,490.791138 
	C473.951691,490.018677 467.618530,487.847443 461.382019,485.840424 
	C456.757507,484.352203 452.373657,482.116028 447.544830,479.632202 
	C445.418854,477.982849 443.292877,476.333496 441.012909,474.034607 
	C440.193176,472.707062 439.373413,471.379517 438.678802,469.445984 
	C438.160034,468.878418 437.641266,468.310822 437.036407,467.623138 
	C437.036407,467.623138 436.897156,467.672577 436.679352,466.912262 
	C436.452271,465.607025 436.225189,464.301788 436.516815,462.443634 
	C436.378113,459.961426 436.239380,457.479187 436.173279,454.468414 
	C436.144989,454.292847 436.116730,454.117249 436.229309,453.577026 
	C436.229309,453.577026 436.184357,453.188721 436.048309,453.024414 
	C436.048309,453.024414 436.207855,453.166046 436.837494,452.991364 
	C436.965027,452.230804 437.092590,451.470245 437.535034,450.094910 
	C437.733093,449.433899 437.931122,448.772888 438.577515,447.860077 
	C438.654877,447.540558 438.732239,447.221069 439.095886,446.268799 
	C444.007599,440.681183 448.148163,434.072235 453.963562,429.681519 
	C480.171875,409.893616 510.084015,399.683258 542.790649,397.735535 
	C559.043396,396.767639 574.902832,399.712128 590.729004,403.096649 
	C608.124512,406.816833 625.509094,410.829529 643.543030,409.809753 
	C659.753479,408.893066 676.803650,394.615692 680.424622,378.817535 
	C680.668213,378.548431 680.669067,378.278503 680.751343,377.188904 
	C680.894775,375.435730 681.038269,373.682587 681.323853,371.552856 
	C681.323853,371.552856 681.258606,371.155670 681.667847,370.578033 
	C681.535339,368.987427 681.402832,367.396820 681.339294,365.176880 
	C681.192322,364.786102 681.045288,364.395325 681.017578,363.308929 
	C680.904846,362.615204 680.792114,361.921448 680.759949,360.690918 
	C680.496582,360.473694 680.233276,360.256470 679.998962,359.998444 
	C679.998962,359.998444 679.955994,360.024628 679.756958,359.583893 
z"/>
<path fill="#ED5C5C" opacity="1.000000" stroke="none" 
	d="
M1093.176025,855.916931 
	C1093.004883,855.842651 1093.000000,855.674561 1092.990234,855.254150 
	C1093.104370,855.275635 1093.223267,855.549316 1093.176025,855.916931 
z"/>
<path fill="#ED5C5C" opacity="1.000000" stroke="none" 
	d="
M191.951874,863.983459 
	C191.634415,861.933044 191.316956,859.882629 190.994308,857.416138 
	C190.989120,857.000000 191.467880,856.978088 191.718307,856.972778 
	C191.968735,856.967529 191.981033,856.982422 191.989761,857.420898 
	C191.982956,859.900757 191.967422,861.942139 191.951874,863.983459 
z"/>
<path fill="#ED5C5C" opacity="1.000000" stroke="none" 
	d="
M192.493225,855.286987 
	C192.763138,855.507141 192.857132,855.911743 192.692856,856.626587 
	C192.434601,856.936768 191.981033,856.982422 191.981033,856.982422 
	C191.981033,856.982422 191.968735,856.967529 191.957382,856.956787 
	C192.069794,856.454529 192.193542,855.962952 192.493225,855.286987 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M1057.692383,888.064087 
	C1052.937134,891.010742 1047.808228,893.964111 1042.312378,896.912720 
	C1047.069824,893.962219 1052.194214,891.016541 1057.692383,888.064087 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M1086.703979,871.066650 
	C1083.597778,874.007935 1080.128174,876.959106 1076.297119,879.899780 
	C1079.404053,876.951721 1082.872314,874.014099 1086.703979,871.066650 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M636.003357,1093.592285 
	C631.390686,1091.555420 626.728638,1089.163696 621.998535,1086.436523 
	C626.604980,1088.479980 631.279480,1090.858765 636.003357,1093.592285 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M1071.707275,881.061584 
	C1068.290283,883.365479 1064.479004,885.634827 1060.299683,887.894226 
	C1063.725464,885.598511 1067.519165,883.312805 1071.707275,881.061584 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M1036.599609,898.982849 
	C1032.928833,901.013672 1028.867554,903.034790 1024.415161,905.051086 
	C1028.085815,903.021912 1032.147461,900.997498 1036.599609,898.982849 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M1014.791138,909.711243 
	C1012.136414,911.507629 1009.275818,913.053589 1006.204956,914.374634 
	C1008.858276,912.586731 1011.721741,911.023743 1014.791138,909.711243 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M620.007141,1085.589600 
	C617.399170,1084.548584 614.729919,1083.166870 611.989746,1081.453125 
	C614.594543,1082.497070 617.270203,1083.872925 620.007141,1085.589600 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M334.023346,942.545410 
	C332.305969,942.281860 330.509460,941.675903 328.357605,941.033691 
	C327.436676,940.485229 326.871124,939.972900 326.180603,939.197876 
	C328.685181,940.024353 331.314697,941.113647 334.023346,942.545410 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M254.043045,902.531006 
	C251.836929,901.788269 249.563629,900.696106 247.157288,899.286743 
	C249.341446,900.040161 251.658646,901.110901 254.043045,902.531006 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M246.974228,898.620056 
	C244.709229,897.895813 242.389511,896.825134 240.009781,895.411987 
	C242.273026,896.137573 244.596268,897.205566 246.974228,898.620056 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M964.776611,934.108521 
	C963.982117,935.074768 962.828369,936.017090 961.324890,936.960571 
	C962.122559,936.002686 963.269897,935.043640 964.776611,934.108521 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M495.828705,1022.717529 
	C494.669830,1022.618042 493.380951,1022.194702 492.023499,1021.417786 
	C493.202881,1021.507324 494.450806,1021.950439 495.828705,1022.717529 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M258.971405,904.627808 
	C258.038940,904.571594 257.054962,904.166626 256.006348,903.422974 
	C256.934448,903.482605 257.927185,903.880737 258.971405,904.627808 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M218.022507,884.556458 
	C217.154602,884.456543 216.211258,884.020691 215.143036,883.279053 
	C215.994476,883.389038 216.970779,883.804810 218.022507,884.556458 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M398.033264,974.578430 
	C397.414734,974.506104 396.731537,974.117065 395.991974,973.409546 
	C396.613251,973.481323 397.290924,973.871521 398.033264,974.578430 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M222.035461,886.576843 
	C221.417999,886.494324 220.734634,886.098328 220.005768,885.377502 
	C220.630051,885.456238 221.299820,885.859802 222.035461,886.576843 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M1039.760620,897.080383 
	C1039.591919,897.691345 1039.094116,898.302612 1038.270020,898.909973 
	C1038.439575,898.297607 1038.935547,897.689148 1039.760620,897.080383 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M237.845795,893.841736 
	C237.331223,893.962097 236.673782,893.800781 235.982895,893.354370 
	C236.533951,893.232910 237.118439,893.396423 237.845795,893.841736 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M1018.805176,907.121216 
	C1018.642456,907.744812 1018.140991,908.349426 1017.319092,908.975281 
	C1017.487854,908.365051 1017.977112,907.733582 1018.805176,907.121216 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M233.844818,891.835815 
	C233.330856,891.962097 232.672211,891.807556 231.976166,891.369690 
	C232.525894,891.242554 233.113022,891.398743 233.844818,891.835815 
z"/>
<path fill="#FB9392" opacity="1.000000" stroke="none" 
	d="
M191.989761,857.420898 
	C191.981033,856.982422 192.434601,856.936768 192.662140,856.940918 
	C192.990219,859.658752 193.090775,862.372314 193.121216,865.531616 
	C192.713562,865.514221 192.376007,865.051147 191.995163,864.285767 
	C191.967422,861.942139 191.982956,859.900757 191.989761,857.420898 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M799.006348,262.675171 
	C800.447144,260.741058 800.804688,260.835571 805.014893,264.494324 
	C803.191711,264.158234 801.285278,263.500458 799.006348,262.675171 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M680.045349,203.339401 
	C681.319763,203.750290 682.644409,204.503616 684.063477,205.582214 
	C682.803833,205.165588 681.449707,204.423706 680.045349,203.339401 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M685.135193,206.248444 
	C685.983521,206.371811 686.973877,206.801422 688.070190,207.561554 
	C687.209839,207.446274 686.243530,207.000488 685.135193,206.248444 
z"/>
<path fill="#F86161" opacity="1.000000" stroke="none" 
	d="
M706.044434,216.313187 
	C706.664124,216.411102 707.329163,216.844040 708.086914,217.584198 
	C707.483032,217.477005 706.786377,217.062607 706.044434,216.313187 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M1091.622192,419.982880 
	C1091.247803,420.053833 1091.070923,419.800629 1090.999146,419.237244 
	C1091.238403,419.243896 1091.476929,419.487793 1091.622192,419.982880 
z"/>
<path fill="#FEBEBD" opacity="1.000000" stroke="none" 
	d="
M1089.961304,417.703888 
	C1089.731812,417.959686 1089.449707,417.857513 1089.054688,417.478271 
	C1089.225708,417.355408 1089.548096,417.403656 1089.961304,417.703888 
z"/>
<path fill="#FD6162" opacity="1.000000" stroke="none" 
	d="
M680.898254,364.004578 
	C681.045288,364.395325 681.192322,364.786102 680.985046,365.710327 
	C678.732300,367.115540 676.680054,367.759888 674.972839,368.914581 
	C672.647034,370.487671 669.910645,372.038300 668.520386,374.313538 
	C660.553284,387.351715 650.973389,397.957977 634.082092,396.888031 
	C625.213745,396.326263 616.288208,395.159790 607.621826,393.222504 
	C587.720520,388.773834 567.734253,385.139679 547.295593,385.109528 
	C540.221680,385.099091 533.033630,385.753723 526.094849,387.111847 
	C512.500366,389.772797 498.769928,392.285706 485.622375,396.501923 
	C475.998810,399.588074 467.156769,405.181396 458.072113,409.862457 
	C441.742554,418.276672 428.100220,428.878326 426.083130,449.106720 
	C425.468018,455.275360 427.588654,459.194641 433.491455,460.741211 
	C434.000488,462.834991 434.503479,464.903839 434.709534,467.135376 
	C430.889130,470.024597 427.493408,473.105286 430.284149,477.927856 
	C432.385712,481.559448 434.754150,485.826477 438.165741,487.753082 
	C446.404419,492.405609 455.226166,496.094940 464.027740,499.638916 
	C466.659332,500.698517 470.001617,499.993164 473.355072,500.094330 
	C474.138245,500.046936 474.584595,500.001678 475.013214,499.976562 
	C474.995453,499.996704 474.967377,500.042511 475.011841,500.359741 
	C476.037598,500.787109 477.018860,500.897278 478.419922,501.024872 
	C480.240082,501.003815 481.640472,500.965302 483.399719,500.951416 
	C484.475586,501.030518 485.192657,501.084961 485.962158,501.413757 
	C486.676483,501.784088 487.338318,501.880096 488.439636,501.996887 
	C490.937164,501.979279 492.995239,501.940887 495.501160,501.928894 
	C499.933655,502.237427 503.918274,502.519562 508.324829,502.896118 
	C510.178284,502.969055 511.609802,502.947601 513.470337,502.949493 
	C515.900452,503.034485 517.901611,503.096100 520.000610,503.488892 
	C521.731995,503.889557 523.365479,503.959045 525.429810,504.039734 
	C527.586426,504.011444 529.312256,503.972015 531.474304,503.953400 
	C534.233337,504.050507 536.556091,504.126740 538.961670,504.499146 
	C540.029663,504.864868 541.014832,504.934418 542.386353,505.018250 
	C543.542542,504.970062 544.312317,504.907593 545.391296,504.870911 
	C546.113892,504.894531 546.527283,504.892334 547.345642,504.965057 
	C548.843445,505.009338 549.936401,504.978699 551.435120,504.968414 
	C553.192749,505.048920 554.544617,505.109100 555.986328,505.485962 
	C557.383667,505.881348 558.691162,505.960052 560.428711,506.049316 
	C562.580994,506.025269 564.303223,505.990662 566.339478,505.983704 
	C567.071716,506.054810 567.489807,506.098206 567.957275,506.416473 
	C568.672668,506.804352 569.338806,506.917328 570.349243,507.038757 
	C571.149414,506.995514 571.605225,506.943848 572.449707,506.922729 
	C573.877319,506.989929 574.916260,507.026581 576.005127,507.378052 
	C577.039185,507.810547 578.023438,507.928192 579.344604,508.053192 
	C580.129150,508.029297 580.576721,507.998016 581.006592,507.985229 
	C580.988953,508.003723 580.964172,508.048523 581.052673,508.419373 
	C588.113953,510.151642 595.086731,511.513062 602.383545,512.900208 
	C603.140686,512.935059 603.573914,512.944214 604.166626,513.217346 
	C604.907715,513.609192 605.489258,513.736938 606.064941,513.900635 
	C606.059082,513.936646 606.007507,513.988220 606.068237,514.352051 
	C610.111450,516.122559 614.093872,517.529297 618.395874,518.948242 
	C619.142212,518.976868 619.569031,518.993347 620.062622,519.369019 
	C622.425781,520.481140 624.722168,521.234009 627.367371,521.993958 
	C628.803528,522.351135 629.890930,522.701233 631.038940,523.390930 
	C632.068542,524.151978 633.037354,524.573425 634.250916,525.004150 
	C634.495605,525.013489 634.984863,525.034180 635.040527,525.375305 
	C636.115784,526.502319 637.086792,527.363159 638.162964,528.061890 
	C645.094727,532.562073 652.411072,536.559082 658.926025,541.594666 
	C675.630493,554.505981 688.136658,570.845093 696.575806,590.234802 
	C699.352783,596.615173 700.755493,597.143188 707.353271,594.145264 
	C709.773987,605.502869 712.053162,616.605713 713.906738,627.779114 
	C714.168274,629.356079 712.270813,631.243347 711.513550,633.046204 
	C709.306519,638.299805 707.151062,643.575439 705.014343,648.858215 
	C703.496338,652.611328 705.553162,654.862244 708.774353,655.516968 
	C711.972717,656.167175 712.277161,657.870056 712.116699,660.437866 
	C712.035156,661.743164 711.835022,663.390625 712.485962,664.331665 
	C715.259277,668.340454 713.240356,669.353821 709.545410,670.168884 
	C705.634094,671.031799 704.399536,674.936279 707.449707,677.586182 
	C709.455444,679.328674 712.677490,679.619019 715.265198,680.762451 
	C716.915710,681.491882 719.429993,682.300964 719.790710,683.574646 
	C721.710449,690.352844 723.157043,697.271301 724.606079,704.175110 
	C726.495117,713.175293 728.313660,722.192200 730.009338,731.230591 
	C731.286316,738.037476 732.161743,744.921265 733.503296,751.714050 
	C735.571594,762.186279 737.885681,772.609924 739.745361,782.993896 
	C735.964417,778.028687 732.533691,773.123657 728.560913,767.443726 
	C729.267517,773.671936 729.347595,778.822876 730.499023,783.722046 
	C734.492676,800.714294 738.742554,817.649841 743.214417,834.522278 
	C743.845642,836.903931 746.273926,840.640198 747.830505,840.620361 
	C753.746887,840.545044 760.126709,843.192627 765.601807,838.830566 
	C766.098328,838.434998 766.516785,837.819214 766.738647,837.219482 
	C769.425537,829.958008 772.260071,822.743347 774.681030,815.393005 
	C779.049438,802.130310 783.177734,788.788208 787.380005,775.471008 
	C791.128601,763.591797 795.145935,751.786316 798.495300,739.794800 
	C802.934204,723.902527 806.977661,707.895203 810.923584,691.871460 
	C813.468323,681.537720 817.278748,672.091309 826.002075,665.313660 
	C827.096375,664.463379 829.320190,663.173218 826.074097,661.757690 
	C826.054382,661.523926 826.015442,661.056335 826.402466,661.035461 
	C828.193359,660.653381 829.597229,660.292114 831.421265,659.964722 
	C833.246704,660.082092 834.651794,660.165649 836.029907,660.601807 
	C836.096191,661.441284 836.014465,662.149841 836.308777,662.382324 
	C842.940491,667.620667 847.468811,674.391846 851.174927,681.889221 
	C851.592163,682.733398 854.414429,683.014648 855.839783,682.596558 
	C858.345215,681.861755 860.597473,681.300415 861.552002,684.316101 
	C862.629272,687.719788 863.380249,691.249207 863.992615,694.772644 
	C865.009644,700.625366 865.789307,706.519043 866.693604,712.391663 
	C867.794983,719.545593 868.981506,726.686829 870.023499,733.849243 
	C871.497314,743.979919 872.611450,754.170471 874.369019,764.250061 
	C875.887573,772.958740 878.168213,781.531860 879.970154,790.194458 
	C882.414917,801.947510 885.329285,813.649536 886.884216,825.525085 
	C887.960205,833.743164 895.300110,841.471497 889.369629,850.390991 
	C889.263489,850.550659 889.656372,850.984192 889.689453,851.303162 
	C890.039856,854.678467 891.536438,858.506958 890.447998,861.338196 
	C888.299316,866.927307 890.884277,869.614502 894.895874,872.041809 
	C892.729187,877.385620 892.729187,877.385620 897.157043,881.405823 
	C898.420898,880.306030 899.680481,879.209900 901.193542,877.893188 
	C901.958984,881.122681 902.457397,883.631958 903.153015,886.085449 
	C905.411316,894.051392 907.741943,901.996887 909.614746,909.964600 
	C905.531372,909.647766 902.469177,910.223511 900.136780,913.687683 
	C898.602417,915.966370 896.109619,917.613037 893.994995,919.485107 
	C889.066772,923.848022 884.442322,924.198364 880.087769,919.355286 
	C875.923767,914.724121 871.518066,909.661133 869.408386,903.975464 
	C865.715149,894.022278 863.541321,883.484985 861.036682,873.118530 
	C859.513245,866.813049 858.774414,860.320068 857.311768,853.997314 
	C853.982117,839.604370 850.117859,825.329346 847.048340,810.883728 
	C844.269958,797.808228 842.234558,784.573975 839.921448,771.400940 
	C839.255737,767.609497 837.609192,765.479980 833.273315,765.923828 
	C829.646729,766.295105 825.930542,766.245605 822.293518,765.928589 
	C818.915344,765.634216 817.458191,767.369202 816.474121,770.104553 
	C813.629211,778.012512 810.715576,785.896179 807.929993,793.824829 
	C802.418457,809.512451 797.003906,825.234009 791.495117,840.922607 
	C786.013794,856.532837 780.504944,872.133606 774.934265,887.712158 
	C771.124023,898.367676 765.800964,908.150513 755.967773,914.337036 
	C753.772217,915.718262 749.413025,915.595093 747.004272,914.337097 
	C740.024048,910.691895 736.476990,903.854980 734.124023,896.688049 
	C729.562195,882.792969 725.229370,868.814331 721.188599,854.759094 
	C717.540710,842.070862 714.313721,829.258179 711.080444,816.455139 
	C708.513000,806.288513 706.839661,795.854187 703.655640,785.894775 
	C698.712524,770.432617 704.306580,772.035034 686.466248,772.000000 
	C685.966553,771.999023 685.455688,772.068665 684.968872,771.989624 
	C680.845764,771.319946 679.146179,774.240295 678.213318,777.226196 
	C676.244568,783.527405 674.686707,789.962891 673.113831,796.380920 
	C668.982117,813.239929 664.691956,830.065491 660.923035,847.006226 
	C657.628540,861.814331 654.595276,876.701477 652.134094,891.666748 
	C650.411072,902.143188 647.129028,911.328186 638.481934,918.150513 
	C635.260254,920.692200 632.129089,920.739624 629.418396,918.522095 
	C625.370605,915.210693 621.006104,911.952393 619.879578,906.191223 
	C619.001160,901.698730 617.231750,897.845093 610.981934,898.781067 
	C610.981934,896.500122 610.981934,894.707764 610.982910,892.696045 
	C610.983948,892.476746 611.004761,892.036621 611.291504,891.753723 
	C611.718872,888.973145 611.859436,886.475464 611.999878,883.601501 
	C612.049622,882.474060 612.099548,881.722839 612.459656,880.926758 
	C612.843567,879.908203 612.917297,878.934570 613.026245,877.599426 
	C613.375427,875.831848 613.689514,874.425720 614.225952,872.871216 
	C614.312744,872.151428 614.177246,871.580017 614.058228,870.689026 
	C614.391418,869.582275 614.708008,868.795166 615.291748,867.769592 
	C615.705872,866.345337 615.852905,865.159546 616.000122,863.730225 
	C616.000244,863.486633 616.020081,862.999878 616.287598,862.763794 
	C616.703369,861.346069 616.851685,860.164490 617.006836,858.638000 
	C617.055664,857.835144 617.097595,857.377197 617.357666,856.755066 
	C617.719055,856.068420 617.862244,855.546021 618.135742,854.697388 
	C618.524353,853.245422 618.782654,852.119629 619.276611,850.822510 
	C619.675659,850.102478 619.839050,849.553772 620.004395,848.751831 
	C620.013428,848.329956 620.020508,848.161255 620.241577,847.880737 
	C620.720215,847.565613 620.905212,847.305542 621.305420,846.947937 
	C621.913513,846.503906 622.226562,846.100708 622.539612,845.697510 
	C622.026306,845.464355 621.512939,845.231323 621.000732,844.658997 
	C621.026855,843.868164 621.051941,843.416626 621.323120,842.757812 
	C621.714844,841.709290 621.860352,840.867981 622.020996,839.766235 
	C622.052673,839.332520 622.069153,839.159363 622.311157,838.989136 
	C622.536743,838.992065 622.987793,839.000000 623.302917,838.858887 
	C627.689209,836.375916 625.874084,840.963989 627.409302,842.293396 
	C628.523132,841.404724 629.923340,840.827576 630.398926,839.815857 
	C632.621155,835.087769 635.652527,830.394531 636.378540,825.395935 
	C637.292847,819.100525 638.260071,813.152222 640.695312,807.120544 
	C642.978638,801.464966 642.326843,794.676086 644.255249,788.809509 
	C645.817261,784.057434 647.209290,780.831604 640.494324,780.336731 
	C640.301758,780.322449 640.171509,779.463318 640.270508,778.788940 
	C640.685425,777.716614 640.841919,776.853394 640.997009,775.742310 
	C640.995605,775.494324 641.014160,774.998779 641.283203,774.762390 
	C641.700684,773.339111 641.849121,772.152283 641.996826,770.726318 
	C641.996033,770.487305 642.018555,770.009705 642.259155,769.837708 
	C642.659241,769.109680 642.818604,768.553589 642.983643,767.611450 
	C642.985962,766.449707 642.982727,765.674072 642.981323,764.684692 
	C642.983215,764.470886 643.018066,764.044617 643.263428,764.031799 
	C643.508789,764.018982 644.000000,764.004883 644.375610,763.998901 
	C648.275208,763.017090 651.010559,761.591919 651.217529,757.158020 
	C651.428650,752.633301 652.623047,748.154297 652.830078,743.629761 
	C652.897461,742.154907 651.191284,739.275208 650.335693,739.290161 
	C643.172729,739.415466 635.828918,737.432556 628.897156,741.106079 
	C622.023010,744.749023 615.175659,748.587280 607.946533,751.382812 
	C595.968811,756.014648 583.748291,760.045471 571.525940,764.008423 
	C563.444702,766.628662 555.181396,768.687378 546.666382,770.998291 
	C545.888733,771.002625 545.444397,771.006226 544.702515,771.026733 
	C543.934998,771.358887 543.465088,771.674133 542.595764,771.991089 
	C541.131348,771.999329 540.066223,772.005737 538.607422,772.015747 
	C535.808350,772.677185 533.402893,773.335083 530.576660,773.995361 
	C504.078674,778.387390 477.794098,779.580688 451.406860,778.951172 
	C446.125458,778.825256 440.748260,778.661377 435.604431,777.598938 
	C421.391235,774.663269 407.253296,771.347107 393.132751,767.983582 
	C380.272675,764.920410 368.746033,758.664490 357.719788,751.705627 
	C347.655640,745.353882 338.405121,737.838135 330.341888,728.919739 
	C321.393524,719.022217 314.328064,707.795105 311.590912,694.946167 
	C310.190247,688.370850 307.945343,685.671448 301.976776,684.565918 
	C301.993378,683.113708 302.011444,682.056946 302.294800,680.764404 
	C302.712524,679.354919 302.864929,678.181213 303.275726,676.773438 
	C305.281128,671.758362 308.239532,674.854248 310.194153,675.931335 
	C312.836121,677.387146 313.930664,676.711731 315.304901,674.417297 
	C320.395325,665.918091 327.280334,659.581970 337.111511,656.906006 
	C337.864655,656.701050 338.398254,655.689453 339.310455,654.882080 
	C342.391754,654.844666 345.196472,654.978088 348.231171,655.373535 
	C351.795959,657.074402 355.220795,658.336975 358.448822,659.985046 
	C368.390503,665.060669 372.967560,674.566956 377.873444,683.804749 
	C379.476440,686.823120 380.914459,690.128357 383.227570,692.533142 
	C390.182434,699.763611 396.900543,707.130493 406.495667,711.465698 
	C418.898041,717.069397 431.699280,720.098145 445.475739,720.833618 
	C446.187561,720.845703 446.596466,720.828918 447.235565,721.122803 
	C449.652557,721.247559 451.839355,721.061646 454.449036,720.880493 
	C456.562225,720.875977 458.252502,720.866699 460.289490,720.935547 
	C461.105957,720.973022 461.575714,720.932373 462.509705,720.930542 
	C469.972656,720.931946 476.971344,720.894470 484.200439,721.162109 
	C486.302826,721.027100 488.174805,720.587097 490.454041,720.091797 
	C492.229034,719.966736 493.596832,719.896912 495.159363,720.123840 
	C496.582672,719.986267 497.811218,719.551880 499.265808,719.052979 
	C499.491852,718.988403 499.944611,718.861816 500.322998,718.931458 
	C501.468048,719.010071 502.234741,719.019043 503.272400,719.308838 
	C509.072296,718.870850 514.679626,718.521118 520.115845,717.366089 
	C538.583130,713.442322 556.538635,708.007996 573.879456,700.294495 
	C589.521667,693.336609 604.429688,685.408508 617.297119,674.049377 
	C623.707947,668.390015 628.822327,661.701416 630.959900,653.170654 
	C632.214111,648.165527 630.194214,644.438477 624.998474,641.589844 
	C625.470520,638.597351 625.946594,636.022339 626.422241,633.449524 
	C629.798279,632.626343 634.354309,633.569763 633.817444,628.067078 
	C633.288330,622.643738 632.626892,617.232910 631.989258,611.820679 
	C629.927307,594.319763 621.786926,580.953613 605.716187,572.845581 
	C595.648926,567.766418 584.803711,565.865356 573.842651,563.819641 
	C548.439087,559.078430 522.687317,559.809204 497.101837,557.994507 
	C487.853821,557.338562 478.511658,557.222778 469.386627,555.767944 
	C442.392731,551.464111 416.189850,544.563965 392.883698,529.434753 
	C373.712402,516.989624 357.724243,502.067535 354.254822,477.924133 
	C353.704895,474.097443 351.879181,472.103790 348.813965,470.290161 
	C347.490997,469.507385 346.916016,467.460449 345.999420,465.657227 
	C346.017914,464.886200 346.038239,464.445770 346.328796,463.735352 
	C346.734741,461.663147 346.870422,459.860870 347.050476,457.668152 
	C347.795685,454.435913 348.496490,451.594177 349.212311,448.691559 
	C357.800964,452.338074 358.084595,451.802795 361.868103,444.551941 
	C367.340973,434.063507 373.214752,423.731323 379.692719,413.840210 
	C383.532898,407.976715 388.676086,402.909454 393.571808,397.808990 
	C399.645508,391.481354 405.573212,384.855408 412.443665,379.483429 
	C427.231201,367.921112 442.305511,356.618591 459.340424,348.444183 
	C468.003113,344.287292 477.009674,340.851227 485.791077,336.933136 
	C487.042175,336.374878 487.898315,334.931427 489.165344,333.881683 
	C489.393494,333.865723 489.849243,333.849701 490.176514,334.015930 
	C495.018127,332.821136 499.532440,331.460144 504.369995,330.072937 
	C505.126312,330.039062 505.559357,330.031372 506.200500,330.273071 
	C507.254120,329.983063 508.099670,329.443665 509.271149,328.872192 
	C510.032471,328.806763 510.467865,328.773438 511.057495,329.011353 
	C511.799377,328.836395 512.387024,328.390228 513.330688,327.905518 
	C514.398499,327.788391 515.110168,327.709778 515.977173,327.913788 
	C516.771606,327.830048 517.410767,327.463684 518.080200,326.874847 
	C518.110474,326.652374 518.120178,326.203400 518.509338,326.203064 
	C519.929199,326.098816 520.959900,325.994934 522.222656,325.975952 
	C522.454651,326.060822 522.928711,326.197906 523.008606,326.517029 
	C524.030823,327.164398 524.973206,327.492676 525.915527,327.820953 
	C526.010498,327.219818 526.105469,326.618683 526.178345,325.817627 
	C526.156250,325.617706 526.130554,325.216248 526.576782,325.221558 
	C530.010254,325.068512 532.997437,324.910156 536.445679,324.852753 
	C543.276672,324.873749 549.646729,324.793823 556.455566,324.764038 
	C559.253296,324.904755 561.612061,324.995331 564.065186,325.459534 
	C564.838928,326.209229 565.479187,326.793121 566.203735,326.929596 
	C574.455322,328.483643 582.834351,329.513672 590.959778,331.556702 
	C618.193298,338.404297 645.601074,344.978668 667.701111,364.040009 
	C668.332031,364.584198 669.511841,364.716675 670.418762,364.674561 
	C673.276123,364.541931 676.125427,364.234436 679.298218,363.993469 
	C680.045105,363.996368 680.471680,364.000488 680.898254,364.004578 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M610.981934,892.915344 
	C610.981934,894.707764 610.981934,896.500122 610.981934,898.781067 
	C617.231750,897.845093 619.001160,901.698730 619.879578,906.191223 
	C621.006104,911.952393 625.370605,915.210693 629.418396,918.522095 
	C632.129089,920.739624 635.260254,920.692200 638.481934,918.150513 
	C647.129028,911.328186 650.411072,902.143188 652.134094,891.666748 
	C654.595276,876.701477 657.628540,861.814331 660.923035,847.006226 
	C664.691956,830.065491 668.982117,813.239929 673.113831,796.380920 
	C674.686707,789.962891 676.244568,783.527405 678.213318,777.226196 
	C679.146179,774.240295 680.845764,771.319946 684.968872,771.989624 
	C685.455688,772.068665 685.966553,771.999023 686.466248,772.000000 
	C704.306580,772.035034 698.712524,770.432617 703.655640,785.894775 
	C706.839661,795.854187 708.513000,806.288513 711.080444,816.455139 
	C714.313721,829.258179 717.540710,842.070862 721.188599,854.759094 
	C725.229370,868.814331 729.562195,882.792969 734.124023,896.688049 
	C736.476990,903.854980 740.024048,910.691895 747.004272,914.337097 
	C749.413025,915.595093 753.772217,915.718262 755.967773,914.337036 
	C765.800964,908.150513 771.124023,898.367676 774.934265,887.712158 
	C780.504944,872.133606 786.013794,856.532837 791.495117,840.922607 
	C797.003906,825.234009 802.418457,809.512451 807.929993,793.824829 
	C810.715576,785.896179 813.629211,778.012512 816.474121,770.104553 
	C817.458191,767.369202 818.915344,765.634216 822.293518,765.928589 
	C825.930542,766.245605 829.646729,766.295105 833.273315,765.923828 
	C837.609192,765.479980 839.255737,767.609497 839.921448,771.400940 
	C842.234558,784.573975 844.269958,797.808228 847.048340,810.883728 
	C850.117859,825.329346 853.982117,839.604370 857.311768,853.997314 
	C858.774414,860.320068 859.513245,866.813049 861.036682,873.118530 
	C863.541321,883.484985 865.715149,894.022278 869.408386,903.975464 
	C871.518066,909.661133 875.923767,914.724121 880.087769,919.355286 
	C884.442322,924.198364 889.066772,923.848022 893.994995,919.485107 
	C896.109619,917.613037 898.602417,915.966370 900.136780,913.687683 
	C902.469177,910.223511 905.531372,909.647766 909.883911,910.260132 
	C911.906677,916.667786 909.893311,921.834900 906.062561,926.516357 
	C904.946350,927.880493 903.992371,929.377380 902.964050,930.813416 
	C902.964050,930.813416 902.653259,931.076355 902.081543,931.098389 
	C892.659241,936.717529 883.362549,936.032410 873.904907,933.080750 
	C873.904907,933.080750 873.447937,932.927307 873.204773,932.456482 
	C870.553467,929.707886 868.145325,927.430115 865.737183,925.152344 
	C865.256714,924.519287 864.776184,923.886169 864.202148,922.638672 
	C863.387329,921.032898 862.666016,920.041565 861.944641,919.050171 
	C861.944641,919.050171 862.000000,919.006470 861.962585,918.808838 
	C861.836548,918.316833 861.651428,918.107849 861.369812,917.984314 
	C861.191711,917.632690 861.013611,917.281006 860.850708,916.238403 
	C859.547913,912.029358 858.229858,908.511414 856.911804,904.993408 
	C856.833923,904.644897 856.756042,904.296448 856.762207,903.270325 
	C856.164856,900.386841 855.483459,898.180969 854.802124,895.975159 
	C854.730042,895.638184 854.657959,895.301270 854.729004,894.281189 
	C853.518616,888.724976 852.165100,883.851868 850.811584,878.978760 
	C850.747498,878.635864 850.683472,878.292908 850.748596,877.279907 
	C850.217529,874.403442 849.557251,872.197021 848.897034,869.990540 
	C848.802734,869.651978 848.708374,869.313477 848.742065,868.295654 
	C847.520325,863.070068 846.170593,858.523865 844.820801,853.977661 
	C844.749512,853.636841 844.678223,853.295959 844.743042,852.264526 
	C843.565552,846.717041 842.251953,841.860168 840.938354,837.003357 
	C840.840576,836.362244 840.742859,835.721069 840.760864,834.334412 
	C840.179443,831.050171 839.482239,828.511414 838.785034,825.972717 
	C838.715088,825.634460 838.645142,825.296143 838.729736,824.259949 
	C837.536316,817.702209 836.188354,811.842407 834.840454,805.982605 
	C834.761780,805.346924 834.683167,804.711182 834.745117,803.369019 
	C834.521179,801.764038 834.156677,800.865601 833.792114,799.967102 
	C833.717896,799.636780 833.643738,799.306396 833.728088,798.274902 
	C832.885254,792.712219 831.883911,787.850647 830.882507,782.989075 
	C830.816650,782.629395 830.750793,782.269714 830.804138,781.216980 
	C830.435242,778.100403 829.947083,775.676880 829.458923,773.253357 
	C828.776123,773.169495 828.093384,773.085571 827.410583,773.001709 
	C825.372375,779.792847 823.334167,786.583984 821.295959,793.375122 
	C821.074402,794.334473 820.852844,795.293762 820.161865,796.712891 
	C818.236572,801.580505 816.780640,805.988281 815.324707,810.396057 
	C815.148193,811.035095 814.971741,811.674194 814.334473,812.734009 
	C812.162903,817.626343 810.334534,822.057373 808.777100,826.581787 
	C807.446228,830.447754 806.426514,834.420898 805.269165,838.346619 
	C805.130737,839.000488 804.992371,839.654358 804.407227,840.718323 
	C802.902832,844.398010 801.845154,847.667542 800.787476,850.937134 
	C800.721924,851.390869 800.656433,851.844666 800.136353,852.747314 
	C798.165833,857.202209 796.659790,861.211914 795.132141,865.213379 
	C789.419617,880.176697 783.769470,895.164429 777.927429,910.077026 
	C776.687195,913.242981 774.737366,916.130859 773.114502,919.146912 
	C771.640137,920.699097 770.165833,922.251343 768.033691,923.941406 
	C763.885803,925.799744 760.395630,927.520203 756.905396,929.240662 
	C756.157715,929.425171 755.409973,929.609741 754.019958,929.520874 
	C751.621704,929.394287 749.865784,929.541016 748.109863,929.687744 
	C747.337585,929.652527 746.565369,929.617249 745.303711,929.132263 
	C743.180420,928.147156 741.546570,927.611633 739.912659,927.076111 
	C739.382996,926.883240 738.853394,926.690430 738.024841,926.029236 
	C737.392639,925.440552 737.059326,925.320190 736.726013,925.199890 
	C736.261597,924.901367 735.797119,924.602844 735.158691,923.732788 
	C733.591797,921.831848 732.198914,920.502441 730.806091,919.173035 
	C730.806091,919.173035 730.903015,918.983521 730.893677,918.764709 
	C730.363708,918.006348 729.843079,917.466736 729.322510,916.927124 
	C729.322510,916.927124 729.038025,916.563171 728.987305,915.966064 
	C727.801819,912.619141 726.408752,909.945251 725.573669,907.107300 
	C719.508362,886.494202 713.581116,865.840393 707.529236,845.223267 
	C706.895264,843.063416 705.735474,841.057800 704.819702,838.980591 
	C704.743042,838.645874 704.666321,838.311157 704.706177,837.303101 
	C702.859924,829.753113 700.897156,822.876465 698.934387,815.999817 
	C698.836121,815.653870 698.737854,815.307922 698.763428,814.267578 
	C697.533630,808.709595 696.179871,803.846130 694.826172,798.982605 
	C694.760864,798.638062 694.695618,798.293579 694.737793,797.263733 
	C693.639038,791.390625 692.432800,786.202759 691.226562,781.014954 
	C690.636902,781.080627 690.047241,781.146301 689.457520,781.211975 
	C688.690735,782.810608 687.664062,784.337769 687.202026,786.020142 
	C685.153625,793.476868 683.170044,800.954712 681.366394,808.473755 
	C680.547913,811.885986 680.202148,815.411682 679.641907,818.885864 
	C679.613647,819.349487 679.585449,819.813171 679.120239,820.745117 
	C675.489685,833.004944 672.288208,844.794250 669.139648,856.597717 
	C669.043640,856.957520 669.498596,857.464294 669.696655,857.902588 
	C669.668518,858.361511 669.640381,858.820435 669.176880,859.751099 
	C667.978210,862.534607 666.881226,864.795715 666.519958,867.168701 
	C665.725098,872.390015 665.302002,877.667908 664.727173,882.922668 
	C664.709717,883.676514 664.692322,884.430420 664.269653,885.656128 
	C663.762451,887.370972 663.660400,888.613831 663.558411,889.856750 
	C663.547729,890.631226 663.537048,891.405701 663.103149,892.718506 
	C660.144287,902.534546 657.608582,911.812256 655.072876,921.089966 
	C655.072815,921.089966 654.945068,921.546753 654.500793,921.817749 
	C653.765320,922.804932 653.474182,923.521118 653.183044,924.237244 
	C651.684692,925.759766 650.186340,927.282227 648.023315,928.945435 
	C643.897400,930.459167 640.436157,931.832153 636.974915,933.205200 
	C635.598755,933.245972 634.222595,933.286743 632.202637,932.951050 
	C627.333923,931.416626 623.109009,930.258667 618.884094,929.100708 
	C617.311096,927.641663 615.738159,926.182556 614.056274,924.116577 
	C613.223022,922.624878 612.498657,921.739990 611.774292,920.855164 
	C611.456543,920.249695 611.138855,919.644165 610.861145,918.253845 
	C610.597961,914.394470 610.250305,911.323425 610.003540,908.244324 
	C609.732788,904.865173 609.555054,901.478638 609.336792,898.095276 
	C609.328064,896.707031 609.319336,895.318787 609.679688,893.506836 
	C610.356995,893.011353 610.668030,892.955444 610.981934,892.915344 
z"/>
<path fill="#EC5758" opacity="1.000000" stroke="none" 
	d="
M346.001221,465.987793 
	C346.916016,467.460449 347.490997,469.507385 348.813965,470.290161 
	C351.879181,472.103790 353.704895,474.097443 354.254822,477.924133 
	C357.724243,502.067535 373.712402,516.989624 392.883698,529.434753 
	C416.189850,544.563965 442.392731,551.464111 469.386627,555.767944 
	C478.511658,557.222778 487.853821,557.338562 497.101837,557.994507 
	C522.687317,559.809204 548.439087,559.078430 573.842651,563.819641 
	C584.803711,565.865356 595.648926,567.766418 605.716187,572.845581 
	C621.786926,580.953613 629.927307,594.319763 631.989258,611.820679 
	C632.626892,617.232910 633.288330,622.643738 633.817444,628.067078 
	C634.354309,633.569763 629.798279,632.626343 626.422241,633.449524 
	C625.946594,636.022339 625.470520,638.597351 624.798218,641.852661 
	C623.963135,642.670654 623.324280,642.808411 622.685425,642.946106 
	C622.924072,640.827271 623.162720,638.708374 623.649536,636.122864 
	C624.016296,635.411316 624.027893,635.161377 623.932556,634.906433 
	C623.305237,629.199402 622.677856,623.492371 622.039795,617.218262 
	C622.001587,616.423767 621.907776,616.228149 621.747620,616.064453 
	C621.216125,614.239990 620.684631,612.415527 620.106140,610.050354 
	C620.008362,609.330078 619.913025,609.178589 619.773071,609.055115 
	C610.146851,587.104919 591.395142,579.106812 569.388184,575.804993 
	C545.624878,572.239685 521.707458,571.404663 497.736816,570.595215 
	C460.276672,569.330139 423.947327,562.685730 390.398041,544.863159 
	C377.474182,537.997620 365.935089,529.312256 357.056183,517.540466 
	C345.731873,502.526520 342.475189,485.398132 344.858002,466.559998 
	C345.480713,466.047485 345.739380,466.007294 346.001221,465.987793 
z"/>
<path fill="#EC5758" opacity="1.000000" stroke="none" 
	d="
M545.000000,771.009766 
	C545.444397,771.006226 545.888733,771.002625 546.925781,771.282349 
	C548.654724,771.660339 549.842163,772.019226 550.919922,771.810852 
	C573.082764,767.524475 594.729797,761.471497 615.579712,752.728455 
	C623.436279,749.433899 630.961487,745.261353 640.029236,745.910339 
	C644.150269,746.205261 644.934448,746.086365 644.603577,749.030640 
	C642.805359,749.872131 641.358704,750.716431 639.912048,751.560669 
	C637.859680,752.683350 635.807373,753.806030 633.070557,754.957764 
	C631.567139,755.552795 630.748169,756.118774 629.929199,756.684692 
	C629.355774,756.999268 628.782410,757.313904 627.546814,757.755981 
	C622.890503,759.723999 618.896423,761.564453 614.902344,763.404907 
	C614.288391,763.664612 613.674377,763.924316 612.331299,764.166382 
	C610.329346,764.500488 609.047058,764.821655 607.785095,765.208984 
	C591.065979,770.340820 574.346436,775.471680 557.635925,780.631287 
	C555.035400,781.434265 552.478699,782.379395 549.901794,783.258789 
	C549.152893,783.418945 548.404053,783.579102 547.080994,783.499756 
	C545.630371,783.603882 544.753845,783.947449 543.877380,784.291016 
	C543.145630,784.467102 542.413818,784.643188 541.024536,784.546021 
	C534.876648,785.265198 529.386292,786.257568 523.895996,787.249939 
	C523.444214,787.370361 522.992432,787.490784 521.963196,787.385498 
	C520.217590,787.524963 519.049500,787.890137 517.881348,788.255310 
	C516.811829,788.399475 515.742249,788.543701 514.016479,788.429443 
	C511.874390,788.549072 510.388611,788.927246 508.902832,789.305420 
	C507.514038,789.482117 506.125214,789.658813 504.013885,789.535767 
	C500.157471,789.614014 497.023560,789.991943 493.889679,790.369873 
	C493.147705,790.510498 492.405701,790.651123 490.962585,790.507507 
	C476.467010,790.151978 462.672607,790.056763 448.878143,790.042297 
	C447.279541,790.040649 445.680481,790.487549 444.081635,790.726562 
	C443.327881,790.703186 442.574127,790.679749 441.292786,790.218750 
	C437.880676,789.750427 434.996155,789.719788 432.111603,789.689209 
	C431.024963,789.657593 429.938354,789.626038 428.370850,789.182983 
	C426.962524,788.717285 426.035095,788.663147 425.107666,788.608948 
	C424.347961,788.588074 423.588257,788.567200 422.297791,788.122620 
	C415.539459,786.703064 409.311859,785.707214 403.084290,784.711304 
	C402.632172,784.669617 402.180054,784.627869 401.341797,784.208618 
	C400.336060,783.780151 399.716461,783.729126 399.096893,783.678101 
	C398.639618,783.655029 398.182312,783.632019 397.261749,783.180725 
	C388.632080,779.819397 380.461243,776.898560 372.301331,773.947693 
	C368.624542,772.618042 364.969452,771.228394 361.304504,769.865967 
	C360.728790,769.599731 360.153107,769.333557 359.240234,768.556152 
	C356.902283,767.057312 354.901520,766.069580 352.900757,765.081909 
	C352.122314,764.732483 351.343872,764.383118 350.241211,763.532532 
	C349.232361,762.721252 348.547760,762.411194 347.863159,762.101196 
	C347.863159,762.101135 347.427032,761.929993 347.190369,761.483704 
	C346.303162,760.698120 345.652649,760.358826 345.002136,760.019531 
	C344.498138,759.726135 343.994141,759.432800 343.206238,758.631226 
	C342.215485,757.799561 341.508698,757.476135 340.801880,757.152649 
	C340.801880,757.152649 340.388489,757.012756 340.166412,756.567444 
	C339.275024,755.768005 338.605713,755.413879 337.936432,755.059692 
	C337.447083,754.740234 336.957733,754.420776 336.206543,753.590942 
	C335.283020,752.735291 334.621307,752.390015 333.959595,752.044800 
	C333.453979,751.660339 332.948364,751.275879 332.168396,750.542908 
	C331.778107,750.060547 331.632812,749.979004 331.458160,749.949707 
	C331.130768,749.706543 330.803406,749.463440 330.220245,748.672119 
	C326.758392,745.034119 323.552307,741.944275 320.346191,738.854431 
	C320.346191,738.854431 320.162018,738.796204 320.066864,738.562683 
	C319.849579,738.147461 319.683380,738.026245 319.473083,737.965515 
	C318.049896,736.203491 316.626740,734.441406 315.082642,732.072632 
	C314.444031,730.947388 313.926300,730.428955 313.408569,729.910522 
	C313.045502,729.380371 312.682404,728.850220 312.209808,727.697876 
	C311.487732,726.346436 310.875122,725.617371 310.262543,724.888245 
	C310.262543,724.888245 310.130096,724.858582 310.122314,724.463135 
	C309.276459,722.672668 308.438354,721.277649 307.600281,719.882690 
	C307.408173,719.498169 307.216064,719.113708 307.013245,718.014893 
	C306.227112,714.977966 305.398285,712.671448 304.685883,710.329590 
	C302.167023,702.049622 300.525024,693.668579 301.978241,684.961365 
	C307.945343,685.671448 310.190247,688.370850 311.590912,694.946167 
	C314.328064,707.795105 321.393524,719.022217 330.341888,728.919739 
	C338.405121,737.838135 347.655640,745.353882 357.719788,751.705627 
	C368.746033,758.664490 380.272675,764.920410 393.132751,767.983582 
	C407.253296,771.347107 421.391235,774.663269 435.604431,777.598938 
	C440.748260,778.661377 446.125458,778.825256 451.406860,778.951172 
	C477.794098,779.580688 504.078674,778.387390 530.764526,774.242432 
	C533.973999,773.841248 537.897217,777.271362 539.001160,772.012207 
	C540.066223,772.005737 541.131348,771.999329 542.766113,772.221558 
	C543.890503,771.970032 544.445251,771.489868 545.000000,771.009766 
z"/>
<path fill="#EC5758" opacity="1.000000" stroke="none" 
	d="
M435.006470,466.972687 
	C434.503479,464.903839 434.000488,462.834991 433.491455,460.741211 
	C427.588654,459.194641 425.468018,455.275360 426.083130,449.106720 
	C428.100220,428.878326 441.742554,418.276672 458.072113,409.862457 
	C467.156769,405.181396 475.998810,399.588074 485.622375,396.501923 
	C498.769928,392.285706 512.500366,389.772797 526.094849,387.111847 
	C533.033630,385.753723 540.221680,385.099091 547.295593,385.109528 
	C567.734253,385.139679 587.720520,388.773834 607.621826,393.222504 
	C616.288208,395.159790 625.213745,396.326263 634.082092,396.888031 
	C650.973389,397.957977 660.553284,387.351715 668.520386,374.313538 
	C669.910645,372.038300 672.647034,370.487671 674.972839,368.914581 
	C676.680054,367.759888 678.732300,367.115540 680.950562,366.024994 
	C681.402832,367.396820 681.535339,368.987427 681.226562,370.861450 
	C680.674927,371.253693 680.564636,371.362518 680.454346,371.471344 
	C680.696777,371.624023 680.939270,371.776733 681.181763,371.929413 
	C681.038269,373.682587 680.894775,375.435730 680.455261,377.732880 
	C680.010010,378.504852 679.965820,378.751678 680.026550,379.017334 
	C676.803650,394.615692 659.753479,408.893066 643.543030,409.809753 
	C625.509094,410.829529 608.124512,406.816833 590.729004,403.096649 
	C574.902832,399.712128 559.043396,396.767639 542.790649,397.735535 
	C510.084015,399.683258 480.171875,409.893616 453.963562,429.681519 
	C448.148163,434.072235 444.007599,440.681183 438.728516,446.710693 
	C438.283783,447.472321 438.206451,447.792084 438.129150,448.111847 
	C437.931122,448.772888 437.733093,449.433899 437.142517,450.561096 
	C436.569305,451.740173 436.388580,452.453094 436.207825,453.166046 
	C436.207855,453.166046 436.048309,453.024414 435.880402,453.081787 
	C435.599579,453.246857 435.486664,453.354584 435.373779,453.462311 
	C435.612000,453.622101 435.850220,453.781891 436.088440,453.941681 
	C436.116730,454.117249 436.144989,454.292847 435.828430,454.977295 
	C435.655121,457.989624 435.826630,460.493103 435.998138,462.996552 
	C436.225189,464.301788 436.452271,465.607025 436.422668,467.325806 
	C435.779480,467.483795 435.392975,467.228241 435.006470,466.972687 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M826.093750,661.991516 
	C829.320190,663.173218 827.096375,664.463379 826.002075,665.313660 
	C817.278748,672.091309 813.468323,681.537720 810.923584,691.871460 
	C806.977661,707.895203 802.934204,723.902527 798.495300,739.794800 
	C795.145935,751.786316 791.128601,763.591797 787.380005,775.471008 
	C783.177734,788.788208 779.049438,802.130310 774.681030,815.393005 
	C772.260071,822.743347 769.425537,829.958008 766.738647,837.219482 
	C766.516785,837.819214 766.098328,838.434998 765.601807,838.830566 
	C760.126709,843.192627 753.746887,840.545044 747.830505,840.620361 
	C746.273926,840.640198 743.845642,836.903931 743.214417,834.522278 
	C738.742554,817.649841 734.492676,800.714294 730.499023,783.722046 
	C729.347595,778.822876 729.267517,773.671936 728.560913,767.443726 
	C732.533691,773.123657 735.964417,778.028687 739.894409,783.339966 
	C740.917053,785.619324 741.392395,787.507507 741.973694,789.362427 
	C743.294800,793.578674 744.668030,797.778625 746.020081,801.985168 
	C746.020081,801.985168 746.000000,802.000000 745.991211,802.326477 
	C746.309692,803.434326 746.637024,804.215698 746.964355,804.997070 
	C746.964355,804.997070 747.054138,805.474731 747.010132,806.118042 
	C748.061829,811.122253 749.106995,814.996887 747.171875,819.935120 
	C743.921936,828.228516 746.534363,832.531860 753.289612,833.601807 
	C762.058716,834.990723 765.076477,832.545349 764.950256,823.844482 
	C764.909790,821.056396 764.236877,818.282166 763.959229,815.491028 
	C763.818481,814.076233 763.535339,812.513000 763.959839,811.229553 
	C768.442627,797.677795 773.083435,784.178284 777.590088,770.634338 
	C778.066833,769.201660 777.965698,767.576843 778.132690,766.041138 
	C778.243042,765.588501 778.353455,765.135864 778.903320,764.217407 
	C780.903992,759.275513 782.626587,754.847290 783.973083,750.307556 
	C784.964172,746.966125 785.463440,743.478760 786.181885,740.056458 
	C786.237976,739.600159 786.294067,739.143860 786.780518,738.257812 
	C787.520874,736.233643 787.830872,734.639099 788.140869,733.044617 
	C788.211548,732.593201 788.282166,732.141846 788.784912,731.241699 
	C789.858032,728.208923 790.499084,725.625000 791.140137,723.041016 
	C791.188232,722.590637 791.236328,722.140198 791.731567,721.244141 
	C793.882568,714.695740 795.685547,708.618042 797.240906,702.477600 
	C797.848450,700.079407 797.899658,697.540283 798.204224,695.065308 
	C798.681946,693.888855 799.159607,692.712341 800.127991,691.229187 
	C800.934814,690.515137 801.251038,690.107788 801.567261,689.700439 
	C801.051697,689.468567 800.536072,689.236755 800.020508,689.004883 
	C800.245972,688.193787 800.471436,687.382751 801.208008,686.232910 
	C801.486755,684.931274 801.254333,683.968445 801.021912,683.005615 
	C803.077759,677.084534 805.133667,671.163391 807.781250,665.238892 
	C810.605347,666.473083 812.837769,667.710754 814.605591,668.690857 
	C818.728516,666.286560 822.411133,664.139038 826.093750,661.991516 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M555.896423,505.169312 
	C554.544617,505.109100 553.192749,505.048920 551.370056,504.631348 
	C549.579712,504.479309 548.260193,504.684723 546.940735,504.890137 
	C546.527283,504.892334 546.113892,504.894531 545.322632,504.567261 
	C543.962158,504.150574 542.979553,504.063293 541.605713,503.972351 
	C540.435974,504.046753 539.657471,504.124847 538.878906,504.202972 
	C536.556091,504.126740 534.233337,504.050507 531.394043,503.598633 
	C528.918335,503.149902 526.959167,503.076721 524.575928,502.992004 
	C522.735474,503.039520 521.319153,503.098633 519.902771,503.157715 
	C517.901611,503.096100 515.900452,503.034485 513.392151,502.602295 
	C511.224304,502.421722 509.563599,502.611725 507.902893,502.801697 
	C503.918274,502.519562 499.933655,502.237427 495.407532,501.570984 
	C492.575989,501.114502 490.285919,501.042297 487.646240,500.967865 
	C486.834351,501.023560 486.372040,501.081482 485.909729,501.139404 
	C485.192657,501.084961 484.475586,501.030518 483.326172,500.609009 
	C481.257538,500.131866 479.621246,500.021820 477.607208,499.911499 
	C476.475433,499.954956 475.721405,499.998749 474.967377,500.042542 
	C474.967377,500.042511 474.995453,499.996704 474.986450,499.688660 
	C474.318939,499.232208 473.660461,499.083771 472.647766,498.924133 
	C471.585175,498.892212 470.876740,498.871552 470.066437,498.528778 
	C469.788025,497.935364 469.671021,497.581818 469.426300,497.404755 
	C463.092560,492.820435 456.707397,488.305725 450.437042,483.636719 
	C449.342560,482.821777 448.722046,481.370453 447.882629,480.213013 
	C452.373657,482.116028 456.757507,484.352203 461.382019,485.840424 
	C467.618530,487.847443 473.951691,490.018677 480.402435,490.791138 
	C491.230530,492.087769 502.179169,492.429718 513.085999,493.003632 
	C527.318970,493.752625 541.566895,494.226562 555.796082,495.034668 
	C563.544373,495.474701 571.395447,495.705322 578.974365,497.185944 
	C593.648376,500.052734 608.457581,502.818542 622.649353,507.374695 
	C633.853088,510.971558 644.216003,517.187195 654.633423,522.610596 
	C646.004822,521.081421 639.478027,513.721191 629.651489,515.818115 
	C626.319275,516.529175 622.332397,513.509888 618.531067,512.830811 
	C611.727905,511.615631 604.838623,510.882507 597.628723,509.948669 
	C596.559875,509.906281 595.847595,509.863098 595.046753,509.518677 
	C593.970947,509.125610 592.983765,509.033813 591.577271,508.934631 
	C587.760010,508.634338 584.362122,508.341431 580.964172,508.048523 
	C580.964172,508.048523 580.988953,508.003723 580.979858,507.697937 
	C580.314270,507.254242 579.657715,507.116272 578.620117,506.966034 
	C577.477783,506.990265 576.716553,507.026733 575.955261,507.063232 
	C574.916260,507.026581 573.877319,506.989929 572.407654,506.644226 
	C571.318481,506.212311 570.660034,506.089447 569.650391,505.958923 
	C568.835449,506.014709 568.371704,506.078156 567.907959,506.141602 
	C567.489807,506.098206 567.071716,506.054810 566.264709,505.626038 
	C563.918030,505.164093 561.960266,505.087494 559.591797,504.996216 
	C558.086243,505.044128 556.991333,505.106720 555.896423,505.169312 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M521.990601,325.891083 
	C520.959900,325.994934 519.929199,326.098816 518.313232,325.916992 
	C516.629211,325.686646 515.530579,325.742035 514.431885,325.797424 
	C514.794678,326.194702 515.157532,326.592041 515.609497,327.143372 
	C515.698669,327.297333 515.821899,327.631165 515.821899,327.631165 
	C515.110168,327.709778 514.398499,327.788391 512.995544,327.861084 
	C511.863800,327.764709 511.423279,327.674286 510.900452,327.274475 
	C509.551331,327.233307 508.284546,327.501495 506.639465,327.844238 
	C505.493347,327.913940 504.725494,327.909119 503.792419,327.693298 
	C503.073029,327.926086 502.518921,328.369873 501.564453,328.851196 
	C500.088135,328.917542 499.012238,328.946381 497.633972,328.837158 
	C496.217163,329.094055 495.102631,329.489044 493.615753,329.917969 
	C492.495636,329.949677 491.747925,329.947418 490.791412,329.695435 
	C489.053772,329.949921 487.524902,330.454071 485.612366,330.975128 
	C483.489716,331.331055 481.750763,331.670074 479.638977,332.045654 
	C477.502533,333.036743 475.738892,333.991302 473.629028,335.108124 
	C471.522736,335.521820 469.762726,335.773254 467.862366,335.699890 
	C466.191803,334.866180 464.535400,333.708252 463.156128,333.976257 
	C459.715210,334.644867 456.400177,335.961670 453.034668,337.018311 
	C454.108154,336.047363 455.020813,334.622772 456.280212,334.176208 
	C467.922089,330.048645 479.386444,325.029327 491.367615,322.347565 
	C506.748688,318.904785 522.563965,317.411713 538.179993,314.999634 
	C541.088684,314.550385 544.005432,312.962036 546.806641,313.202423 
	C564.959839,314.760132 583.107910,316.373199 600.721069,321.625305 
	C609.217529,324.158905 618.097351,325.446350 626.525146,328.162048 
	C639.470886,332.333618 652.893005,335.589508 663.664795,344.575745 
	C661.236755,344.145264 658.922607,343.637848 657.043640,342.445038 
	C653.612305,340.266571 650.443604,338.334167 646.197571,340.643494 
	C645.204590,341.183594 643.369446,340.404236 641.998596,339.982269 
	C634.619019,337.710938 627.351013,335.015472 619.877625,333.126556 
	C610.785034,330.828400 601.563232,328.979340 592.325134,327.343536 
	C587.294617,326.452759 582.114746,326.405884 576.638489,325.972656 
	C575.549133,325.919373 574.823425,325.871857 574.005859,325.503601 
	C572.608521,325.134369 571.303040,325.085876 569.567505,325.024658 
	C567.415222,325.036621 565.693054,325.061279 563.970886,325.085938 
	C561.612061,324.995331 559.253296,324.904755 556.334473,324.400269 
	C549.397522,323.812225 543.020813,323.625488 536.642761,323.518890 
	C536.430298,323.515350 536.204346,324.322571 535.984680,324.751831 
	C532.997437,324.910156 530.010254,325.068512 526.363037,324.921844 
	C524.465515,325.041565 523.228088,325.466309 521.990601,325.891083 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M459.942780,720.857422 
	C458.252502,720.866699 456.562225,720.875977 454.177155,720.617188 
	C451.686157,720.164368 449.889954,719.979553 448.016418,719.495117 
	C446.963348,719.114685 445.987610,719.033936 444.619812,718.952759 
	C443.183411,718.914734 442.139099,718.877136 441.036438,718.565247 
	C440.319885,718.178101 439.661621,718.065308 438.638275,717.949829 
	C437.543854,717.915771 436.814575,717.884338 435.992249,717.513977 
	C434.354248,716.383728 432.694214,715.757202 431.284302,714.772461 
	C425.385834,710.652954 419.510132,706.496399 413.732849,702.209534 
	C410.705719,699.963318 407.899475,697.419495 404.993469,695.010071 
	C408.676666,697.179993 412.170593,699.812744 416.086334,701.414001 
	C422.334076,703.968872 428.723785,706.947449 435.300476,707.768188 
	C449.408264,709.528870 463.681000,710.945679 477.872986,710.833679 
	C501.361633,710.648438 524.333923,705.751343 546.183472,697.460510 
	C562.391357,691.310364 577.642334,682.638428 593.637878,675.415527 
	C589.599365,678.785461 585.413635,682.123535 580.836304,684.788940 
	C576.479065,687.326233 571.542358,688.868225 567.185486,691.405945 
	C564.202148,693.143677 562.100220,696.526672 559.036804,697.968994 
	C551.304749,701.609314 543.246765,704.555603 535.329529,707.804749 
	C530.209473,709.905945 525.107300,712.050476 519.645935,714.167969 
	C518.593384,714.184814 517.891968,714.209106 516.825317,714.164429 
	C515.629944,714.715515 514.799744,715.335510 513.554993,715.980103 
	C509.819305,716.402161 506.498199,716.799683 502.815918,717.137573 
	C501.618073,717.672546 500.781342,718.267212 499.944611,718.861816 
	C499.944611,718.861816 499.491852,718.988403 498.902527,718.981689 
	C497.197021,719.259033 496.080811,719.543030 494.964630,719.827026 
	C493.596832,719.896912 492.229034,719.966736 490.053284,720.011230 
	C487.486908,720.276306 485.728455,720.566650 483.970032,720.857056 
	C476.971344,720.894470 469.972656,720.931946 462.464722,720.645691 
	C461.284607,720.500488 460.613708,720.678955 459.942780,720.857422 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M903.299561,930.805176 
	C903.992371,929.377380 904.946350,927.880493 906.062561,926.516357 
	C909.893311,921.834900 911.906677,916.667786 910.313843,910.245728 
	C907.741943,901.996887 905.411316,894.051392 903.153015,886.085449 
	C902.457397,883.631958 901.958984,881.122681 901.193542,877.893188 
	C899.680481,879.209900 898.420898,880.306030 897.157043,881.405823 
	C892.729187,877.385620 892.729187,877.385620 894.895874,872.041809 
	C890.884277,869.614502 888.299316,866.927307 890.447998,861.338196 
	C891.536438,858.506958 890.039856,854.678467 889.689453,851.303162 
	C889.656372,850.984192 889.263489,850.550659 889.369629,850.390991 
	C895.300110,841.471497 887.960205,833.743164 886.884216,825.525085 
	C885.329285,813.649536 882.414917,801.947510 879.970154,790.194458 
	C878.168213,781.531860 875.887573,772.958740 874.369019,764.250061 
	C872.611450,754.170471 871.497314,743.979919 870.023499,733.849243 
	C868.981506,726.686829 867.794983,719.545593 866.693604,712.391663 
	C865.789307,706.519043 865.009644,700.625366 863.992615,694.772644 
	C863.380249,691.249207 862.629272,687.719788 861.552002,684.316101 
	C860.597473,681.300415 858.345215,681.861755 855.839783,682.596558 
	C854.414429,683.014648 851.592163,682.733398 851.174927,681.889221 
	C847.468811,674.391846 842.940491,667.620667 836.308777,662.382324 
	C836.014465,662.149841 836.096191,661.441284 835.983582,660.511047 
	C835.964355,660.067627 835.927002,659.867371 836.304077,659.925232 
	C837.440491,660.033020 838.199768,660.082886 839.031494,660.463989 
	C840.968018,662.009888 842.746399,663.390259 844.712708,664.407410 
	C849.983398,667.133911 851.694336,666.464722 853.791321,661.149231 
	C857.064026,668.330688 861.571777,675.220825 863.348633,682.755005 
	C866.891296,697.776367 868.898682,713.157776 871.604187,728.379150 
	C876.812012,757.679443 881.069275,787.194702 887.569336,816.206665 
	C894.510010,847.185120 903.626099,877.674500 911.704285,908.400635 
	C914.127808,917.618591 911.026123,925.024902 903.299561,930.805176 
M895.303162,858.416199 
	C895.012085,858.632812 894.721008,858.849426 894.429932,859.065979 
	C894.607544,859.251404 894.785156,859.436890 894.962769,859.622314 
	C895.191895,859.341003 895.421021,859.059631 895.303162,858.416199 
M892.793091,852.377930 
	C892.744690,852.737061 892.696350,853.096130 892.647949,853.455261 
	C892.905029,853.457642 893.162048,853.460022 893.419128,853.462402 
	C893.379333,853.102478 893.339600,852.742493 892.793091,852.377930 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M488.937256,333.897644 
	C487.898315,334.931427 487.042175,336.374878 485.791077,336.933136 
	C477.009674,340.851227 468.003113,344.287292 459.340424,348.444183 
	C442.305511,356.618591 427.231201,367.921112 412.443665,379.483429 
	C405.573212,384.855408 399.645508,391.481354 393.571808,397.808990 
	C388.676086,402.909454 383.532898,407.976715 379.692719,413.840210 
	C373.214752,423.731323 367.340973,434.063507 361.868103,444.551941 
	C358.084595,451.802795 357.800964,452.338074 349.212311,448.691559 
	C348.496490,451.594177 347.795685,454.435913 346.777954,457.651611 
	C346.280762,458.000732 346.100525,457.975891 345.920288,457.951080 
	C347.975677,441.036224 354.895721,426.068268 364.723694,412.226379 
	C367.721313,415.102173 370.160522,414.437164 372.437805,412.038879 
	C376.092316,408.190216 379.458771,404.034943 383.363556,400.464905 
	C388.786591,395.506805 394.473572,390.815002 400.262634,386.284027 
	C413.255066,376.115082 425.915588,365.418762 439.612122,356.288605 
	C448.973816,350.048096 459.863983,346.104950 470.053925,341.100281 
	C474.066101,339.129791 478.032440,337.065887 482.326721,335.048096 
	C484.114990,334.616364 485.596191,334.181061 487.387299,333.776733 
	C488.110565,333.837677 488.523895,333.867676 488.937256,333.897644 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M746.078003,801.618530 
	C744.668030,797.778625 743.294800,793.578674 741.973694,789.362427 
	C741.392395,787.507507 740.917053,785.619324 740.244507,783.400269 
	C737.885681,772.609924 735.571594,762.186279 733.503296,751.714050 
	C732.161743,744.921265 731.286316,738.037476 730.009338,731.230591 
	C728.313660,722.192200 726.495117,713.175293 724.606079,704.175110 
	C723.157043,697.271301 721.710449,690.352844 719.790710,683.574646 
	C719.429993,682.300964 716.915710,681.491882 715.265198,680.762451 
	C712.677490,679.619019 709.455444,679.328674 707.449707,677.586182 
	C704.399536,674.936279 705.634094,671.031799 709.545410,670.168884 
	C713.240356,669.353821 715.259277,668.340454 712.485962,664.331665 
	C711.835022,663.390625 712.035156,661.743164 712.116699,660.437866 
	C712.277161,657.870056 711.972717,656.167175 708.774353,655.516968 
	C705.553162,654.862244 703.496338,652.611328 705.014343,648.858215 
	C707.151062,643.575439 709.306519,638.299805 711.513550,633.046204 
	C712.270813,631.243347 714.168274,629.356079 713.906738,627.779114 
	C712.053162,616.605713 709.773987,605.502869 707.335632,593.755615 
	C705.539307,584.165710 701.778320,576.051147 697.426941,568.194763 
	C696.704285,566.890015 695.159790,566.040405 693.994263,564.596497 
	C693.320312,562.634521 692.782043,560.980774 691.946655,559.493958 
	C690.084412,556.179626 688.073669,552.948669 686.123474,549.683777 
	C690.568970,555.404297 695.813110,560.677673 699.312378,566.928040 
	C710.476990,586.870117 715.983887,608.478149 715.872009,631.379883 
	C715.830811,639.817078 714.960938,648.251892 714.975952,656.686951 
	C714.982788,660.523376 715.981689,664.419189 716.944824,668.182068 
	C720.771057,683.131348 725.258789,697.928772 728.581970,712.985474 
	C734.702454,740.715820 740.186157,768.586853 745.884094,796.409790 
	C746.204651,797.975342 746.062622,799.635681 746.078003,801.618530 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M441.094757,718.839600 
	C442.139099,718.877136 443.183411,718.914734 444.626892,719.264343 
	C445.074982,719.985840 445.123871,720.395386 445.172791,720.804871 
	C431.699280,720.098145 418.898041,717.069397 406.495667,711.465698 
	C396.900543,707.130493 390.182434,699.763611 383.227570,692.533142 
	C380.914459,690.128357 379.476440,686.823120 377.873444,683.804749 
	C372.967560,674.566956 368.390503,665.060669 358.448822,659.985046 
	C355.220795,658.336975 351.795959,657.074402 348.246643,655.117920 
	C348.031342,654.430115 348.030548,654.259949 348.399902,654.064697 
	C349.511810,654.047485 350.253632,654.055359 351.263611,654.334290 
	C354.195984,655.299683 356.826111,656.202332 359.531433,656.645142 
	C365.288757,657.587524 370.822815,661.646973 376.947052,658.050415 
	C381.634369,665.776184 386.321655,673.501953 390.710358,681.738647 
	C389.617279,683.856262 387.847351,685.745056 388.214874,687.015564 
	C389.235687,690.544678 390.414215,694.532471 392.863342,697.034241 
	C397.310425,701.576904 402.427795,705.648438 407.829895,709.009338 
	C411.746643,711.446045 416.574036,712.419067 421.375000,714.080017 
	C426.531494,715.361145 431.308380,716.607056 436.085266,717.852966 
	C436.814575,717.884338 437.543854,717.915771 438.805542,718.211243 
	C439.923523,718.596741 440.509125,718.718201 441.094757,718.839600 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M640.012207,778.998108 
	C640.171509,779.463318 640.301758,780.322449 640.494324,780.336731 
	C647.209290,780.831604 645.817261,784.057434 644.255249,788.809509 
	C642.326843,794.676086 642.978638,801.464966 640.695312,807.120544 
	C638.260071,813.152222 637.292847,819.100525 636.378540,825.395935 
	C635.652527,830.394531 632.621155,835.087769 630.398926,839.815857 
	C629.923340,840.827576 628.523132,841.404724 627.409302,842.293396 
	C625.874084,840.963989 627.689209,836.375916 623.310059,838.558167 
	C622.580994,837.654175 622.159912,836.909790 621.738831,836.165344 
	C624.718994,825.608887 627.699097,815.052429 631.209106,804.359436 
	C632.136108,804.117065 632.533325,804.011169 632.930542,803.905273 
	C632.510376,803.320251 632.090149,802.735229 631.669922,802.150208 
	C634.733337,790.624390 637.796753,779.098511 641.319824,767.435852 
	C641.955444,767.502441 642.078003,767.734253 642.101440,768.328369 
	C642.043396,769.111511 642.030945,769.560608 642.018555,770.009705 
	C642.018555,770.009705 641.996033,770.487305 641.730957,770.967651 
	C641.315247,772.631653 641.164673,773.815186 641.014160,774.998779 
	C641.014160,774.998779 640.995605,775.494324 640.733154,775.947693 
	C640.317932,777.266724 640.165039,778.132446 640.012207,778.998108 
M636.937195,790.483582 
	C636.793945,790.233887 636.650696,789.984192 636.507446,789.734436 
	C636.374756,789.992371 636.091614,790.295532 636.151672,790.495544 
	C636.212158,790.697021 636.613892,790.795959 636.937195,790.483582 
M637.117981,786.527466 
	C637.257690,786.775085 637.397400,787.022705 637.537109,787.270325 
	C637.669006,787.015015 637.946472,786.720032 637.890869,786.515747 
	C637.837708,786.320312 637.438293,786.219177 637.117981,786.527466 
M635.833374,794.978271 
	C635.740051,794.567505 635.646667,794.156738 635.553345,793.745972 
	C635.434631,793.838562 635.205750,793.945129 635.215515,794.021362 
	C635.268555,794.437195 635.380310,794.845581 635.833374,794.978271 
M631.836792,807.977417 
	C631.745178,807.567505 631.653503,807.157532 631.561829,806.747559 
	C631.444763,806.840637 631.219177,806.947205 631.228699,807.024658 
	C631.279968,807.439697 631.389954,807.847473 631.836792,807.977417 
M625.855652,828.053467 
	C625.605957,828.206421 625.219421,828.313477 625.148010,828.526245 
	C625.085876,828.711487 625.379578,829.016052 625.516113,829.267944 
	C625.654968,829.015076 625.793823,828.762207 625.855652,828.053467 
M626.187622,824.966125 
	C626.437012,824.814026 626.828491,824.706421 626.894104,824.496887 
	C626.954346,824.304260 626.663757,824.001831 626.527771,823.747803 
	C626.390503,824.002258 626.253296,824.256714 626.187622,824.966125 
M627.225525,820.044250 
	C627.313171,820.451782 627.400818,820.859253 627.488464,821.266785 
	C627.605774,821.174316 627.831604,821.068848 627.822327,820.991699 
	C627.772522,820.578308 627.664185,820.171997 627.225525,820.044250 
M630.252258,812.178650 
	C630.420654,812.122131 630.588989,812.065613 630.757385,812.008972 
	C630.588745,811.954041 630.420044,811.899048 630.252258,812.178650 
M624.603821,833.517334 
	C624.603821,833.517334 624.478333,833.597229 624.603821,833.517334 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M503.177063,717.197205 
	C506.498199,716.799683 509.819305,716.402161 513.906738,716.004761 
	C515.512268,715.414429 516.351440,714.823975 517.190552,714.233459 
	C517.891968,714.209106 518.593384,714.184814 519.899658,714.393921 
	C524.316956,713.765747 528.208801,713.157715 531.925903,711.992249 
	C540.995300,709.148376 549.983826,706.046570 559.156616,703.271301 
	C559.874451,703.305298 560.440491,703.116638 561.362915,702.896484 
	C564.471130,701.569336 567.223083,700.273682 570.340820,698.950806 
	C573.136047,697.612427 575.565430,696.301331 578.285034,694.860840 
	C579.369751,694.475098 580.164307,694.218811 581.321899,693.942566 
	C590.792664,687.782837 599.978821,681.755371 608.984558,675.469299 
	C616.852661,669.977234 622.082275,663.237793 617.888123,652.890869 
	C617.888123,652.890869 617.992004,652.931763 618.209961,652.805115 
	C618.558472,652.310608 618.689087,651.942688 618.819641,651.574829 
	C619.900879,648.767517 620.982056,645.960144 622.374390,643.049438 
	C623.324280,642.808411 623.963135,642.670654 624.802185,642.270142 
	C630.194214,644.438477 632.214111,648.165527 630.959900,653.170654 
	C628.822327,661.701416 623.707947,668.390015 617.297119,674.049377 
	C604.429688,685.408508 589.521667,693.336609 573.879456,700.294495 
	C556.538635,708.007996 538.583130,713.442322 520.115845,717.366089 
	C514.679626,718.521118 509.072296,718.870850 503.284210,719.000916 
	C503.075714,718.007202 503.126373,717.602234 503.177063,717.197205 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M559.004883,703.048584 
	C549.983826,706.046570 540.995300,709.148376 531.925903,711.992249 
	C528.208801,713.157715 524.316956,713.765747 520.250854,714.401489 
	C525.107300,712.050476 530.209473,709.905945 535.329529,707.804749 
	C543.246765,704.555603 551.304749,701.609314 559.036804,697.968994 
	C562.100220,696.526672 564.202148,693.143677 567.185486,691.405945 
	C571.542358,688.868225 576.479065,687.326233 580.836304,684.788940 
	C585.413635,682.123535 589.599365,678.785461 593.992432,675.391724 
	C594.028687,675.042175 594.022461,675.016296 594.388123,675.019043 
	C595.846680,674.032837 596.939575,673.043945 598.032471,672.055054 
	C598.484192,671.749939 598.935974,671.444763 599.964355,671.026062 
	C601.042358,670.301758 601.543701,669.691040 602.044983,669.080322 
	C605.489441,665.802429 608.933899,662.524536 612.696228,659.528015 
	C612.049072,666.319153 610.048218,672.255371 604.285706,676.242126 
	C597.367126,681.028748 590.466919,685.843445 583.634827,690.751831 
	C582.539307,691.538818 581.840881,692.878357 580.958862,693.962463 
	C580.164307,694.218811 579.369751,694.475098 577.905884,694.865479 
	C574.816040,696.325745 572.395508,697.651917 569.974976,698.978027 
	C567.223083,700.273682 564.471130,701.569336 561.206726,702.679077 
	C560.131104,702.678284 559.567993,702.863403 559.004883,703.048584 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M482.019623,335.044495 
	C478.032440,337.065887 474.066101,339.129791 470.053925,341.100281 
	C459.863983,346.104950 448.973816,350.048096 439.612122,356.288605 
	C425.915588,365.418762 413.255066,376.115082 400.262634,386.284027 
	C394.473572,390.815002 388.786591,395.506805 383.363556,400.464905 
	C379.458771,404.034943 376.092316,408.190216 372.437805,412.038879 
	C370.160522,414.437164 367.721313,415.102173 364.993958,412.008850 
	C366.621521,408.850372 368.426147,406.050720 370.887024,403.076447 
	C372.283722,401.801270 373.024139,400.700714 373.764587,399.600189 
	C373.764587,399.600189 373.799622,399.274384 374.243713,399.103760 
	C379.327759,396.248413 384.237488,393.934082 388.547028,390.795593 
	C395.717682,385.573425 402.428650,379.725189 409.473450,374.323425 
	C415.872009,369.417175 422.457397,364.754517 429.270752,359.840027 
	C436.397491,355.751984 443.209106,351.800171 450.032074,347.867950 
	C454.020081,345.569580 458.026093,343.302429 462.390930,341.011902 
	C463.491455,340.977997 464.225037,340.953369 465.332458,340.915955 
	C469.486816,338.950287 473.267303,336.997437 477.318665,335.052856 
	C478.382904,335.025299 479.176208,334.989471 480.311035,334.969971 
	C481.108246,335.005676 481.563934,335.025085 482.019623,335.044495 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M686.076172,549.367615 
	C688.073669,552.948669 690.084412,556.179626 691.946655,559.493958 
	C692.782043,560.980774 693.320312,562.634521 693.599121,564.604004 
	C690.646301,564.005798 687.590942,563.598389 685.621826,561.918030 
	C679.572449,556.755737 674.158203,550.847656 668.094727,545.703796 
	C658.093506,537.219421 647.484436,529.577576 634.984863,525.034180 
	C634.984863,525.034180 634.495605,525.013489 634.113159,524.712036 
	C632.813293,523.957520 631.895752,523.504456 630.978271,523.051331 
	C629.890930,522.701233 628.803528,522.351135 627.360474,521.633911 
	C626.970093,520.550293 626.935486,519.833801 627.299927,519.086121 
	C630.591431,520.015015 633.638611,520.660034 636.348694,521.991516 
	C646.212097,526.837402 655.906250,532.028442 665.780518,536.851318 
	C670.454285,539.134155 675.391174,540.878296 680.208801,542.866516 
	C682.148804,544.928162 684.088806,546.989807 686.076172,549.367615 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M574.097778,325.824341 
	C574.823425,325.871857 575.549133,325.919373 576.897278,326.260132 
	C579.616455,327.012695 581.700073,327.545471 583.811829,327.920074 
	C609.886597,332.545471 634.909607,340.531525 658.983582,351.510925 
	C663.818298,353.715881 668.134277,358.032990 674.114502,354.345398 
	C674.691833,353.989380 675.975281,354.778259 676.930908,355.035645 
	C677.629822,356.109344 678.328735,357.183044 679.083130,358.841675 
	C679.411011,359.625916 679.683533,359.825287 679.955994,360.024628 
	C679.955994,360.024628 679.998962,359.998444 679.969116,360.279083 
	C679.912903,360.731628 679.886536,360.903503 679.622314,361.287109 
	C679.248962,362.330719 679.113403,363.162689 678.977844,363.994629 
	C676.125427,364.234436 673.276123,364.541931 670.418762,364.674561 
	C669.511841,364.716675 668.332031,364.584198 667.701111,364.040009 
	C645.601074,344.978668 618.193298,338.404297 590.959778,331.556702 
	C582.834351,329.513672 574.455322,328.483643 566.203735,326.929596 
	C565.479187,326.793121 564.838928,326.209229 564.065186,325.459534 
	C565.693054,325.061279 567.415222,325.036621 569.809814,325.293396 
	C571.687439,325.658020 572.892578,325.741150 574.097778,325.824341 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M676.919434,354.694061 
	C675.975281,354.778259 674.691833,353.989380 674.114502,354.345398 
	C668.134277,358.032990 663.818298,353.715881 658.983582,351.510925 
	C634.909607,340.531525 609.886597,332.545471 583.811829,327.920074 
	C581.700073,327.545471 579.616455,327.012695 577.260986,326.265930 
	C582.114746,326.405884 587.294617,326.452759 592.325134,327.343536 
	C601.563232,328.979340 610.785034,330.828400 619.877625,333.126556 
	C627.351013,335.015472 634.619019,337.710938 641.998596,339.982269 
	C643.369446,340.404236 645.204590,341.183594 646.197571,340.643494 
	C650.443604,338.334167 653.612305,340.266571 657.043640,342.445038 
	C658.922607,343.637848 661.236755,344.145264 663.749756,344.910645 
	C664.904053,345.157898 665.663208,345.456329 666.739624,346.253967 
	C668.367188,347.505524 669.677307,348.257874 670.987488,349.010223 
	C672.960938,350.790985 674.934448,352.571716 676.919434,354.694061 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M680.121826,542.537842 
	C675.391174,540.878296 670.454285,539.134155 665.780518,536.851318 
	C655.906250,532.028442 646.212097,526.837402 636.348694,521.991516 
	C633.638611,520.660034 630.591431,520.015015 626.983154,519.063660 
	C625.841492,519.068909 625.415649,519.065430 624.928833,518.694641 
	C622.550354,517.581360 620.232788,516.835449 617.562134,516.043213 
	C613.475159,515.327393 609.741333,514.657776 606.007507,513.988220 
	C606.007507,513.988220 606.059082,513.936646 606.013428,513.623840 
	C605.314148,513.191772 604.660645,513.072571 604.007080,512.953308 
	C603.573914,512.944214 603.140686,512.935059 602.307922,512.571228 
	C600.600647,511.460297 599.292969,510.704071 597.985291,509.947876 
	C604.838623,510.882507 611.727905,511.615631 618.531067,512.830811 
	C622.332397,513.509888 626.319275,516.529175 629.651489,515.818115 
	C639.478027,513.721191 646.004822,521.081421 654.705017,522.949829 
	C658.927856,525.438538 662.760132,527.958740 666.832214,531.084106 
	C667.779297,532.065735 668.486694,532.442200 669.194031,532.818665 
	C672.807617,535.948853 676.421204,539.078979 680.121826,542.537842 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M301.976776,684.565918 
	C300.525024,693.668579 302.167023,702.049622 304.685883,710.329590 
	C305.398285,712.671448 306.227112,714.977966 306.965088,717.693604 
	C304.868652,712.276245 301.932190,706.614136 300.920715,700.626770 
	C298.246368,684.795654 299.574127,669.590698 311.785553,657.278564 
	C315.217438,657.836060 318.242340,658.718994 321.111145,658.358765 
	C327.128876,657.603088 333.064545,656.193848 339.033905,655.052856 
	C338.398254,655.689453 337.864655,656.701050 337.111511,656.906006 
	C327.280334,659.581970 320.395325,665.918091 315.304901,674.417297 
	C313.930664,676.711731 312.836121,677.387146 310.194153,675.931335 
	C308.239532,674.854248 305.281128,671.758362 303.003571,677.004578 
	C302.325195,678.646545 302.177368,679.823303 302.029510,681.000122 
	C302.011444,682.056946 301.993378,683.113708 301.976776,684.565918 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M777.842590,766.253723 
	C777.965698,767.576843 778.066833,769.201660 777.590088,770.634338 
	C773.083435,784.178284 768.442627,797.677795 763.959839,811.229553 
	C763.535339,812.513000 763.818481,814.076233 763.959229,815.491028 
	C764.236877,818.282166 764.909790,821.056396 764.950256,823.844482 
	C765.076477,832.545349 762.058716,834.990723 753.289612,833.601807 
	C746.534363,832.531860 743.921936,828.228516 747.171875,819.935120 
	C749.106995,814.996887 748.061829,811.122253 746.993896,806.360535 
	C748.268127,808.903564 749.758362,811.771179 750.706604,814.808105 
	C752.320923,819.978149 753.594421,825.254517 755.316650,831.614807 
	C762.998230,809.108643 770.275330,787.787476 777.842590,766.253723 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M350.995422,654.063171 
	C350.253632,654.055359 349.511810,654.047485 348.118103,653.745544 
	C342.057465,653.559692 336.648804,653.733459 331.240051,653.735229 
	C329.675323,653.735718 327.245026,653.769531 326.703278,652.879883 
	C324.869049,649.867432 322.302521,650.846985 319.843750,650.841248 
	C324.373383,648.924011 328.742706,646.210083 333.466858,645.259460 
	C342.612335,643.419312 351.958099,643.298279 360.932404,646.557678 
	C364.089752,647.704346 366.975342,649.599182 369.684753,651.515869 
	C365.797974,650.976013 362.454437,648.805359 358.772369,652.662964 
	C357.303436,654.201904 353.652863,653.658630 350.995422,654.063171 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M428.960449,359.984497 
	C422.457397,364.754517 415.872009,369.417175 409.473450,374.323425 
	C402.428650,379.725189 395.717682,385.573425 388.547028,390.795593 
	C384.237488,393.934082 379.327759,396.248413 374.326813,398.962524 
	C381.029358,391.338898 388.092834,383.685852 395.845123,375.987183 
	C397.691925,375.295593 398.849915,374.649628 400.403198,373.995514 
	C405.056549,372.067017 409.382324,370.281921 413.555511,368.192047 
	C418.756287,365.587585 423.831207,362.731781 428.960449,359.984497 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M464.958649,340.928741 
	C464.225037,340.953369 463.491455,340.977997 462.000427,341.006653 
	C449.353973,346.314575 437.477448,351.646759 425.569763,356.908356 
	C420.769226,359.029572 415.900421,360.996216 411.062714,363.033203 
	C417.390472,358.656860 423.718231,354.280487 430.763153,349.899231 
	C431.982300,349.256012 432.484283,348.617706 432.986206,347.979370 
	C432.986206,347.979370 433.001221,347.998779 433.348022,347.994141 
	C434.463287,347.327179 435.231781,346.664825 436.000244,346.002441 
	C436.722290,345.643921 437.444366,345.285400 438.837189,344.915833 
	C440.681122,343.960266 441.854340,343.015778 443.027527,342.071289 
	C443.487915,341.734436 443.948303,341.397583 444.911377,341.290710 
	C447.948669,340.025330 450.483307,338.529938 453.017944,337.034546 
	C453.017944,337.034576 453.004242,337.016846 453.019440,337.017578 
	C456.400177,335.961670 459.715210,334.644867 463.156128,333.976257 
	C464.535400,333.708252 466.191803,334.866180 467.825928,336.037354 
	C466.939453,338.109344 465.949066,339.519043 464.958649,340.928741 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M470.168335,498.850891 
	C470.876740,498.871552 471.585175,498.892212 472.648987,499.214783 
	C473.009033,499.709900 473.013672,499.903198 473.018280,500.096466 
	C470.001617,499.993164 466.659332,500.698517 464.027740,499.638916 
	C455.226166,496.094940 446.404419,492.405609 438.165741,487.753082 
	C434.754150,485.826477 432.385712,481.559448 430.284149,477.927856 
	C427.493408,473.105286 430.889130,470.024597 434.709534,467.135376 
	C435.392975,467.228241 435.779480,467.483795 436.531555,467.705963 
	C436.897156,467.672577 437.036407,467.623138 437.060455,467.999115 
	C437.574219,468.934052 438.063965,469.493011 438.553680,470.051971 
	C439.373413,471.379517 440.193176,472.707062 441.034271,474.837158 
	C441.586639,479.384369 439.633545,483.341553 443.387756,486.781433 
	C451.063629,493.814514 460.316864,496.887848 470.168335,498.850891 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M411.040405,363.029297 
	C415.900421,360.996216 420.769226,359.029572 425.569763,356.908356 
	C437.477448,351.646759 449.353973,346.314575 461.633514,341.015930 
	C458.026093,343.302429 454.020081,345.569580 450.032074,347.867950 
	C443.209106,351.800171 436.397491,355.751984 429.270752,359.840027 
	C423.831207,362.731781 418.756287,365.587585 413.555511,368.192047 
	C409.382324,370.281921 405.056549,372.067017 400.403076,373.668396 
	C400.011078,372.913849 400.014496,372.478241 400.017914,372.042664 
	C400.017914,372.042664 400.377197,371.910187 400.835022,371.833435 
	C401.496826,371.473846 401.700806,371.191040 401.904785,370.908264 
	C401.904816,370.908264 401.880707,370.868622 402.172424,370.996338 
	C403.635895,369.737549 404.807648,368.351074 405.979431,366.964600 
	C406.081421,366.646484 406.267853,366.389648 407.120758,366.095703 
	C408.813354,365.018158 409.923859,364.038971 411.034363,363.059814 
	C411.034393,363.059814 411.018066,363.025391 411.040405,363.029297 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M435.992249,717.513977 
	C431.308380,716.607056 426.531494,715.361145 421.177094,713.791199 
	C419.728516,713.012329 418.896149,712.450745 417.980560,712.118713 
	C405.842102,707.716492 398.809845,698.759033 395.223022,686.707642 
	C398.352661,689.295593 401.482330,691.883484 404.802734,694.740723 
	C407.899475,697.419495 410.705719,699.963318 413.732849,702.209534 
	C419.510132,706.496399 425.385834,710.652954 431.284302,714.772461 
	C432.694214,715.757202 434.354248,716.383728 435.992249,717.513977 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M470.066437,498.528809 
	C460.316864,496.887848 451.063629,493.814514 443.387756,486.781433 
	C439.633545,483.341553 441.586639,479.384369 441.111267,475.161926 
	C443.292877,476.333496 445.418854,477.982849 447.713745,479.922607 
	C448.722046,481.370453 449.342560,482.821777 450.437042,483.636719 
	C456.707397,488.305725 463.092560,492.820435 469.426300,497.404755 
	C469.671021,497.581818 469.788025,497.935364 470.066437,498.528809 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M581.321899,693.942566 
	C581.840881,692.878357 582.539307,691.538818 583.634827,690.751831 
	C590.466919,685.843445 597.367126,681.028748 604.285706,676.242126 
	C610.048218,672.255371 612.049072,666.319153 613.005310,659.402344 
	C614.428345,657.059753 615.860046,655.124268 617.589966,653.039795 
	C622.082275,663.237793 616.852661,669.977234 608.984558,675.469299 
	C599.978821,681.755371 590.792664,687.782837 581.321899,693.942566 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M395.126190,686.405762 
	C398.809845,698.759033 405.842102,707.716492 417.980560,712.118713 
	C418.896149,712.450745 419.728516,713.012329 420.797485,713.755981 
	C416.574036,712.419067 411.746643,711.446045 407.829895,709.009338 
	C402.427795,705.648438 397.310425,701.576904 392.863342,697.034241 
	C390.414215,694.532471 389.235687,690.544678 388.214874,687.015564 
	C387.847351,685.745056 389.617279,683.856262 390.763672,682.105103 
	C392.420197,683.341736 393.724762,684.722839 395.126190,686.405762 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M836.010620,660.158447 
	C834.651794,660.165649 833.246704,660.082092 831.339539,659.588135 
	C829.526123,658.790283 828.128113,658.573486 826.928955,657.964722 
	C825.836792,657.410339 824.994446,656.383789 823.980774,655.649841 
	C822.637939,654.677612 821.235596,653.787598 819.858887,652.862244 
	C820.056824,652.418884 820.131653,651.807922 820.471741,651.558044 
	C824.034729,648.940247 841.105164,649.399414 844.641174,652.552002 
	C843.441406,652.749390 842.515930,652.562561 841.590515,652.375671 
	C841.847351,652.766907 842.104248,653.158203 842.361084,653.549438 
	C840.216431,655.655457 838.071716,657.761414 835.927002,659.867371 
	C835.927002,659.867371 835.964355,660.067627 836.010620,660.158447 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M319.480286,650.867065 
	C322.302521,650.846985 324.869049,649.867432 326.703278,652.879883 
	C327.245026,653.769531 329.675323,653.735718 331.240051,653.735229 
	C336.648804,653.733459 342.057465,653.559692 347.747986,653.770630 
	C348.030548,654.259949 348.031342,654.430115 348.016693,654.855957 
	C345.196472,654.978088 342.391754,654.844666 339.310455,654.882080 
	C333.064545,656.193848 327.128876,657.603088 321.111145,658.358765 
	C318.242340,658.718994 315.217438,657.836060 312.096771,657.138550 
	C314.325806,654.804871 316.721313,652.848877 319.480286,650.867065 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M704.663513,839.263794 
	C705.735474,841.057800 706.895264,843.063416 707.529236,845.223267 
	C713.581116,865.840393 719.508362,886.494202 725.573669,907.107300 
	C726.408752,909.945251 727.801819,912.619141 728.923767,915.744019 
	C727.518616,913.930542 725.533569,911.917480 724.825684,909.526367 
	C717.930786,886.236023 711.249451,862.882507 704.663513,839.263794 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M351.263611,654.334290 
	C353.652863,653.658630 357.303436,654.201904 358.772369,652.662964 
	C362.454437,648.805359 365.797974,650.976013 369.780640,651.849609 
	C372.369537,653.678040 374.562195,655.536255 376.850952,657.722412 
	C370.822815,661.646973 365.288757,657.587524 359.531433,656.645142 
	C356.826111,656.202332 354.195984,655.299683 351.263611,654.334290 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M773.432617,919.044861 
	C774.737366,916.130859 776.687195,913.242981 777.927429,910.077026 
	C783.769470,895.164429 789.419617,880.176697 795.132141,865.213379 
	C796.659790,861.211914 798.165833,857.202209 799.948853,853.038940 
	C798.031372,860.327759 796.283630,867.937683 793.564941,875.183411 
	C788.904297,887.604736 783.734253,899.839905 778.543579,912.053528 
	C777.472534,914.573730 775.375122,916.657715 773.432617,919.044861 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M550.274902,783.401062 
	C552.478699,782.379395 555.035400,781.434265 557.635925,780.631287 
	C574.346436,775.471680 591.065979,770.340820 607.785095,765.208984 
	C609.047058,764.821655 610.329346,764.500488 612.000000,764.220703 
	C605.551575,767.070679 598.872498,770.410217 591.823669,772.506775 
	C578.208679,776.556274 564.385132,779.904480 550.274902,783.401062 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M826.074097,661.757690 
	C822.411133,664.139038 818.728516,666.286560 814.605591,668.690857 
	C812.837769,667.710754 810.605347,666.473083 808.066650,665.019165 
	C808.963196,662.966553 810.166016,661.130249 812.039917,659.186646 
	C817.145752,659.738403 821.580566,660.397400 826.015442,661.056335 
	C826.015442,661.056335 826.054382,661.523926 826.074097,661.757690 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M679.925415,818.653076 
	C680.202148,815.411682 680.547913,811.885986 681.366394,808.473755 
	C683.170044,800.954712 685.153625,793.476868 687.202026,786.020142 
	C687.664062,784.337769 688.690735,782.810608 689.457520,781.211975 
	C690.047241,781.146301 690.636902,781.080627 691.226562,781.014954 
	C692.432800,786.202759 693.639038,791.390625 694.761963,797.000977 
	C693.257629,793.325562 691.836731,789.227661 690.415771,785.129700 
	C690.048279,785.057556 689.680725,784.985352 689.313232,784.913147 
	C686.278442,796.082214 683.243652,807.251282 679.925415,818.653076 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M826.402466,661.035461 
	C821.580566,660.397400 817.145752,659.738403 812.322632,659.014893 
	C814.341675,656.942322 816.748962,654.934204 819.507568,652.894165 
	C821.235596,653.787598 822.637939,654.677612 823.980774,655.649841 
	C824.994446,656.383789 825.836792,657.410339 826.928955,657.964722 
	C828.128113,658.573486 829.526123,658.790283 830.919312,659.554321 
	C829.597229,660.292114 828.193359,660.653381 826.402466,661.035461 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M444.394348,790.976868 
	C445.680481,790.487549 447.279541,790.040649 448.878143,790.042297 
	C462.672607,790.056763 476.467010,790.151978 490.583771,790.463684 
	C489.647186,790.989868 488.387360,791.527222 487.129425,791.522766 
	C472.988464,791.472351 458.847809,791.338562 444.394348,790.976868 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M620.027588,847.992615 
	C620.020508,848.161255 620.013428,848.329956 619.758667,848.914795 
	C619.354309,849.885193 619.197632,850.439514 619.040955,850.993774 
	C618.782654,852.119629 618.524353,853.245422 617.902588,854.853394 
	C617.405945,855.863403 617.272705,856.391296 617.139526,856.919250 
	C617.097595,857.377197 617.055664,857.835144 616.738525,858.874451 
	C616.315552,860.637146 616.167786,861.818542 616.020081,862.999878 
	C616.020081,862.999878 616.000244,863.486633 615.731995,863.969116 
	C615.317383,865.637146 615.171021,866.822571 615.024658,868.008057 
	C614.708008,868.795166 614.391418,869.582275 614.040649,871.023560 
	C614.005493,872.125061 614.004517,872.572327 614.003601,873.019592 
	C613.689514,874.425720 613.375427,875.831848 612.812622,877.621948 
	C612.285339,878.071472 612.003113,878.107971 611.717163,878.115356 
	C612.558472,872.928162 613.104126,867.671082 614.308777,862.569702 
	C616.215088,854.497009 618.562683,846.528625 621.201599,838.391235 
	C621.869568,838.475525 622.004883,838.715454 622.085632,838.986267 
	C622.069153,839.159363 622.052673,839.332520 621.762390,839.964600 
	C621.351379,841.270630 621.214172,842.117859 621.076965,842.965027 
	C621.051941,843.416626 621.026855,843.868164 621.004272,844.654663 
	C621.006653,844.989502 621.013611,844.977539 620.749878,845.184204 
	C620.333252,846.258057 620.180420,847.125366 620.027588,847.992615 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M836.304077,659.925232 
	C838.071716,657.761414 840.216431,655.655457 842.361084,653.549438 
	C842.104248,653.158203 841.847351,652.766907 841.590515,652.375671 
	C842.515930,652.562561 843.441406,652.749390 844.739197,652.918945 
	C847.379211,654.682678 849.646790,656.463745 851.620361,658.609497 
	C847.203918,659.360352 843.081482,659.746582 838.959045,660.132812 
	C838.199768,660.082886 837.440491,660.033020 836.304077,659.925232 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M839.031494,660.463989 
	C843.081482,659.746582 847.203918,659.360352 851.761963,658.920044 
	C852.704712,659.434265 853.211792,660.002502 853.755127,660.859985 
	C851.694336,666.464722 849.983398,667.133911 844.712708,664.407410 
	C842.746399,663.390259 840.968018,662.009888 839.031494,660.463989 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M669.978821,857.670532 
	C669.498596,857.464294 669.043640,856.957520 669.139648,856.597717 
	C672.288208,844.794250 675.489685,833.004944 678.959839,821.057373 
	C676.244629,833.080261 673.252808,845.259338 669.978821,857.670532 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M361.417664,770.175659 
	C364.969452,771.228394 368.624542,772.618042 372.301331,773.947693 
	C380.461243,776.898560 388.632080,779.819397 396.945984,783.040405 
	C392.671722,782.180176 388.109955,781.403503 383.859009,779.801941 
	C376.316803,776.960449 368.963287,773.618225 361.417664,770.175659 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M874.024170,933.409058 
	C883.362549,936.032410 892.659241,936.717529 901.886597,931.157166 
	C896.176880,937.883667 882.175171,939.150085 874.024170,933.409058 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M655.390930,920.975464 
	C657.608582,911.812256 660.144287,902.534546 662.962341,893.081909 
	C662.680481,902.750061 661.405762,912.401550 655.390930,920.975464 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M821.581299,793.176025 
	C823.334167,786.583984 825.372375,779.792847 827.410583,773.001709 
	C828.093384,773.085571 828.776123,773.169495 829.458923,773.253357 
	C829.947083,775.676880 830.435242,778.100403 830.825989,780.942871 
	C829.943787,780.006104 829.158997,778.650391 828.019043,776.681213 
	C825.865967,782.384033 823.866272,787.680481 821.581299,793.176025 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M797.921997,695.304810 
	C797.899658,697.540283 797.848450,700.079407 797.240906,702.477600 
	C795.685547,708.618042 793.882568,714.695740 791.884033,720.938354 
	C793.606201,712.566895 795.622986,704.055664 797.921997,695.304810 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M805.556152,838.152405 
	C806.426514,834.420898 807.446228,830.447754 808.777100,826.581787 
	C810.334534,822.057373 812.162903,817.626343 814.177795,813.027039 
	C811.602356,821.252258 808.722778,829.605225 805.556152,838.152405 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M664.995605,882.656982 
	C665.302002,877.667908 665.725098,872.390015 666.519958,867.168701 
	C666.881226,864.795715 667.978210,862.534607 669.030640,860.065796 
	C667.967834,867.403015 666.615906,874.897156 664.995605,882.656982 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M785.902954,740.283569 
	C785.463440,743.478760 784.964172,746.966125 783.973083,750.307556 
	C782.626587,754.847290 780.903992,759.275513 779.079956,763.913696 
	C781.086121,756.220886 783.355042,748.365784 785.902954,740.283569 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M609.066406,898.391907 
	C609.555054,901.478638 609.732788,904.865173 610.003540,908.244324 
	C610.250305,911.323425 610.597961,914.394470 610.810425,917.911987 
	C606.676086,912.132324 608.636169,905.322388 609.066406,898.391907 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M403.342957,784.976074 
	C409.311859,785.707214 415.539459,786.703064 421.941559,787.984802 
	C415.944611,787.260803 409.773102,786.250854 403.342957,784.976074 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M698.763794,816.272888 
	C700.897156,822.876465 702.859924,829.753113 704.727966,837.045288 
	C702.619934,830.489136 700.606567,823.517578 698.763794,816.272888 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M834.664795,806.280640 
	C836.188354,811.842407 837.536316,817.702209 838.762512,824.001526 
	C837.256958,818.486877 835.873047,812.532776 834.664795,806.280640 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M524.301514,787.426636 
	C529.386292,786.257568 534.876648,785.265198 540.644531,784.511597 
	C535.517029,785.701355 530.112061,786.652344 524.301514,787.426636 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M840.758789,837.286377 
	C842.251953,841.860168 843.565552,846.717041 844.768311,852.003418 
	C843.298096,847.478516 841.938660,842.523987 840.758789,837.286377 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M830.689392,783.289673 
	C831.883911,787.850647 832.885254,792.712219 833.763184,798.021790 
	C832.591919,793.509949 831.544128,788.550110 830.689392,783.289673 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M320.339783,739.188904 
	C323.552307,741.944275 326.758392,745.034119 330.009674,748.449646 
	C326.814331,745.691345 323.573822,742.607361 320.339783,739.188904 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M694.648438,799.270508 
	C696.179871,803.846130 697.533630,808.709595 698.776367,813.996643 
	C697.267151,809.466248 695.868958,804.512329 694.648438,799.270508 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M850.642090,879.270752 
	C852.165100,883.851868 853.518616,888.724976 854.756531,894.022949 
	C853.251526,889.486206 851.862061,884.524536 850.642090,879.270752 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M640.335693,751.626099 
	C641.358704,750.716431 642.805359,749.872131 644.623657,749.404053 
	C645.025696,750.529480 645.056030,751.278687 645.060181,752.266907 
	C645.034058,752.505859 645.046265,752.986450 645.023804,753.357788 
	C644.979675,754.472900 644.957886,755.216553 644.709290,756.146118 
	C644.328735,756.887939 644.175049,757.443848 643.999573,758.228516 
	C643.977844,758.457275 643.960632,758.916504 643.686768,758.947388 
	C643.229858,758.992676 643.046692,759.006958 642.863525,759.021301 
	C642.952087,757.615173 643.378784,756.117126 643.029175,754.830017 
	C642.712524,753.664368 641.549255,752.728577 640.335693,751.626099 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M618.998047,929.421082 
	C623.109009,930.258667 627.333923,931.416626 631.793213,932.890137 
	C627.052368,934.549316 622.988403,932.494934 618.998047,929.421082 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M815.597168,810.184814 
	C816.780640,805.988281 818.236572,801.580505 819.966980,797.026184 
	C818.784180,801.244324 817.326904,805.608948 815.597168,810.184814 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M844.649536,854.268005 
	C846.170593,858.523865 847.520325,863.070068 848.752075,868.031128 
	C847.248779,863.816772 845.863464,859.187561 844.649536,854.268005 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M615.256592,763.491577 
	C618.896423,761.564453 622.890503,759.723999 627.242981,757.878418 
	C623.604553,759.774963 619.607727,761.676575 615.256592,763.491577 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M637.253784,933.408691 
	C640.436157,931.832153 643.897400,930.459167 647.736816,929.091431 
	C644.587524,930.601929 641.060120,932.107056 637.253784,933.408691 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M856.731567,905.267090 
	C858.229858,908.511414 859.547913,912.029358 860.805054,915.944580 
	C859.346558,912.741516 857.948914,909.141113 856.731567,905.267090 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M757.248047,929.364502 
	C760.395630,927.520203 763.885803,925.799744 767.745972,924.086914 
	C764.607605,925.892578 761.099182,927.690491 757.248047,929.364502 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M865.796753,925.495850 
	C868.145325,927.430115 870.553467,929.707886 873.025879,932.295044 
	C870.678833,930.349365 868.267578,928.094299 865.796753,925.495850 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M494.313141,790.544678 
	C497.023560,789.991943 500.157471,789.614014 503.592041,789.476685 
	C500.840698,790.051331 497.788666,790.385376 494.313141,790.544678 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M801.058228,850.717102 
	C801.845154,847.667542 802.902832,844.398010 804.274048,841.025391 
	C803.501465,844.113892 802.415283,847.305481 801.058228,850.717102 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M432.402893,789.948914 
	C434.996155,789.719788 437.880676,789.750427 440.936584,790.088013 
	C438.303375,790.332886 435.498779,790.270752 432.402893,789.948914 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M452.645996,337.028625 
	C450.483307,338.529938 447.948669,340.025330 445.212921,341.275940 
	C447.432526,339.695038 449.853271,338.358887 452.645996,337.028625 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M436.257477,462.720093 
	C435.826630,460.493103 435.655121,457.989624 435.792114,455.241577 
	C436.239380,457.479187 436.378113,459.961426 436.257477,462.720093 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M790.875244,723.272583 
	C790.499084,725.625000 789.858032,728.208923 788.941650,730.934753 
	C789.314331,728.552429 789.962341,726.028320 790.875244,723.272583 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M838.609253,826.258301 
	C839.482239,828.511414 840.179443,831.050171 840.769897,834.009521 
	C839.919983,831.801392 839.176697,829.172668 838.609253,826.258301 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M405.645020,367.009460 
	C404.807648,368.351074 403.635895,369.737549 402.175781,370.973358 
	C403.028503,369.566559 404.169556,368.310455 405.645020,367.009460 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M848.710205,870.257202 
	C849.557251,872.197021 850.217529,874.403442 850.770569,877.018433 
	C849.950012,875.126038 849.236694,872.824951 848.710205,870.257202 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M353.007629,765.394409 
	C354.901520,766.069580 356.902283,767.057312 358.988037,768.358521 
	C357.086884,767.683655 355.100708,766.695312 353.007629,765.394409 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M612.000000,883.977783 
	C611.859436,886.475464 611.718872,888.973145 611.287292,891.406250 
	C610.789734,890.267700 610.583191,889.193787 610.376587,888.119812 
	C610.396240,887.046021 610.415833,885.972168 610.798096,884.497437 
	C611.437073,884.032654 611.716858,883.993103 612.000000,883.977783 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M748.354126,929.934570 
	C749.865784,929.541016 751.621704,929.394287 753.632080,929.473389 
	C752.123840,929.860046 750.361145,930.020752 748.354126,929.934570 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M854.630005,896.253723 
	C855.483459,898.180969 856.164856,900.386841 856.771301,903.001953 
	C855.950256,901.118286 855.204102,898.825256 854.630005,896.253723 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M730.847107,919.510376 
	C732.198914,920.502441 733.591797,921.831848 735.026978,923.484619 
	C733.675598,922.487915 732.281860,921.167847 730.847107,919.510376 
z"/>
<path fill="#EC5758" opacity="1.000000" stroke="none" 
	d="
M345.721008,458.261658 
	C346.100525,457.975891 346.280762,458.000732 346.733582,458.042053 
	C346.870422,459.860870 346.734741,461.663147 346.238403,463.526947 
	C345.733093,463.299194 345.503906,463.115295 345.190155,463.036774 
	C345.300690,461.548584 345.411224,460.060394 345.721008,458.261658 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M399.704895,372.153564 
	C400.014496,372.478241 400.011078,372.913849 400.007782,373.676544 
	C398.849915,374.649628 397.691925,375.295593 396.167664,375.894592 
	C396.998199,374.653259 398.195038,373.458862 399.704895,372.153564 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M787.872498,733.256042 
	C787.830872,734.639099 787.520874,736.233643 786.950195,737.954590 
	C786.994324,736.543213 787.299255,735.005371 787.872498,733.256042 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M509.264709,789.461060 
	C510.388611,788.927246 511.874390,788.549072 513.611877,788.366211 
	C512.451233,788.913330 511.038940,789.265015 509.264709,789.461060 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M740.036377,927.383545 
	C741.546570,927.611633 743.180420,928.147156 744.956909,928.953003 
	C743.453003,928.712585 741.806580,928.201782 740.036377,927.383545 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M610.090698,888.362305 
	C610.583191,889.193787 610.789734,890.267700 611.000488,891.689148 
	C611.004761,892.036621 610.983948,892.476746 610.982910,892.696045 
	C610.668030,892.955444 610.356995,893.011353 609.815552,893.112427 
	C609.656433,891.629456 609.730591,890.117126 610.090698,888.362305 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M307.520935,720.203735 
	C308.438354,721.277649 309.276459,722.672668 310.131317,724.395874 
	C309.245911,723.324341 308.343750,721.924561 307.520935,720.203735 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M518.223877,788.421265 
	C519.049500,787.890137 520.217590,787.524963 521.611816,787.342896 
	C520.747375,787.879700 519.656860,788.233398 518.223877,788.421265 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M670.856262,348.707275 
	C669.677307,348.257874 668.367188,347.505524 667.013428,346.402161 
	C668.221558,346.835541 669.473328,347.619965 670.856262,348.707275 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M410.702942,363.070312 
	C409.923859,364.038971 408.813354,365.018158 407.337463,365.989746 
	C408.105255,365.015015 409.238373,364.047943 410.702942,363.070312 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M597.699585,672.062378 
	C596.939575,673.043945 595.846680,674.032837 594.394165,675.026123 
	C595.145325,674.043640 596.255981,673.056702 597.699585,672.062378 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M663.847046,889.641724 
	C663.660400,888.613831 663.762451,887.370972 664.129395,886.008423 
	C664.308167,887.068054 664.221924,888.247314 663.847046,889.641724 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M611.999878,883.601501 
	C611.716858,883.993103 611.437073,884.032654 610.953003,884.124084 
	C610.795471,882.957886 610.845825,881.764038 611.349426,880.442627 
	C611.949768,880.517273 612.065369,880.736145 612.149475,880.971680 
	C612.099548,881.722839 612.049622,882.474060 611.999878,883.601501 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M442.680359,342.080109 
	C441.854340,343.015778 440.681122,343.960266 439.139435,344.881653 
	C439.958374,343.935333 441.145813,343.012146 442.680359,342.080109 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M612.459656,880.926758 
	C612.065369,880.736145 611.949768,880.517273 611.641418,880.224915 
	C611.463318,879.673950 611.446472,879.213196 611.573364,878.433838 
	C612.003113,878.107971 612.285339,878.071472 612.777466,877.983398 
	C612.917297,878.934570 612.843567,879.908203 612.459656,880.926758 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M373.459045,399.731262 
	C373.024139,400.700714 372.283722,401.801270 371.163544,402.892334 
	C371.573700,401.876038 372.363586,400.869202 373.459045,399.731262 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M833.618164,800.195923 
	C834.156677,800.865601 834.521179,801.764038 834.770264,803.048889 
	C834.251221,802.431702 833.847717,801.428223 833.618164,800.195923 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M861.939087,919.382080 
	C862.666016,920.041565 863.387329,921.032898 864.126831,922.347534 
	C863.407837,921.685120 862.670654,920.699585 861.939087,919.382080 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M611.690918,921.152222 
	C612.498657,921.739990 613.223022,922.624878 613.921753,923.821960 
	C613.133240,923.239197 612.370422,922.344238 611.690918,921.152222 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M425.311371,788.882446 
	C426.035095,788.663147 426.962524,788.717285 428.000610,789.006348 
	C427.245880,789.212769 426.380493,789.184326 425.311371,788.882446 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M344.930359,463.269836 
	C345.503906,463.115295 345.733093,463.299194 345.968140,463.796875 
	C346.038239,464.445770 346.017914,464.886200 345.999390,465.657227 
	C345.739380,466.007294 345.480713,466.047485 345.033356,466.136261 
	C344.784485,465.277100 344.727509,464.389984 344.930359,463.269836 
z"/>
<path fill="#FD6162" opacity="1.000000" stroke="none" 
	d="
M800.936890,683.374512 
	C801.254333,683.968445 801.486755,684.931274 801.388428,685.941895 
	C800.989075,685.240845 800.920532,684.492126 800.936890,683.374512 
z"/>
<path fill="#EC5758" opacity="1.000000" stroke="none" 
	d="
M679.298218,363.993469 
	C679.113403,363.162689 679.248962,362.330719 679.823730,361.346130 
	C680.263000,361.193512 680.679382,361.227722 680.679382,361.227722 
	C680.792114,361.921448 680.904846,362.615204 680.957886,363.656738 
	C680.471680,364.000488 680.045105,363.996368 679.298218,363.993469 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M630.257690,756.742737 
	C630.748169,756.118774 631.567139,755.552795 632.725403,755.024048 
	C632.238464,755.641174 631.412292,756.220947 630.257690,756.742737 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M643.018066,764.044617 
	C643.018066,764.044617 642.983215,764.470886 642.766785,764.723633 
	C642.270752,765.057556 641.985474,765.104492 641.694580,765.117188 
	C641.823914,764.083862 641.953247,763.050476 642.489624,762.216187 
	C642.937073,762.958313 642.977539,763.501465 643.018066,764.044617 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M544.191772,784.437561 
	C544.753845,783.947449 545.630371,783.603882 546.695251,783.456116 
	C546.091125,783.962646 545.298645,784.273376 544.191772,784.437561 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M435.679199,346.023010 
	C435.231781,346.664825 434.463287,347.327179 433.338745,347.987610 
	C433.774536,347.338318 434.566345,346.690948 435.679199,346.023010 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M310.233215,725.199219 
	C310.875122,725.617371 311.487732,726.346436 312.115784,727.389954 
	C311.488800,726.972961 310.846313,726.241516 310.233215,725.199219 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M747.058716,804.730042 
	C746.637024,804.215698 746.309692,803.434326 746.001648,802.318237 
	C746.398315,802.809998 746.775696,803.636475 747.058716,804.730042 
z"/>
<path fill="#EC5758" opacity="1.000000" stroke="none" 
	d="
M641.551941,765.431885 
	C641.985474,765.104492 642.270752,765.057556 642.764893,764.937378 
	C642.982727,765.674072 642.985962,766.449707 642.776001,767.611450 
	C642.562805,767.997559 642.147034,767.994324 642.147034,767.994324 
	C642.078003,767.734253 641.955444,767.502441 641.604675,767.207947 
	C641.423035,766.660095 641.416199,766.203369 641.551941,765.431885 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M631.506470,802.445435 
	C632.090149,802.735229 632.510376,803.320251 632.930542,803.905273 
	C632.533325,804.011169 632.136108,804.117065 631.497925,804.150696 
	C631.285583,803.632568 631.314331,803.186584 631.506470,802.445435 
z"/>
<path fill="#EC5758" opacity="1.000000" stroke="none" 
	d="
M643.263428,764.031799 
	C642.977539,763.501465 642.937073,762.958313 642.765991,762.025879 
	C642.664795,760.980286 642.694153,760.323975 642.793457,759.344482 
	C643.046692,759.006958 643.229858,758.992676 643.700928,759.374084 
	C643.992554,761.181519 643.996277,762.593201 644.000000,764.004883 
	C644.000000,764.004883 643.508789,764.018982 643.263428,764.031799 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M622.311157,838.989136 
	C622.004883,838.715454 621.869568,838.475525 621.493286,838.176147 
	C621.337463,837.640198 621.367920,837.194458 621.568604,836.457031 
	C622.159912,836.909790 622.580994,837.654175 622.994995,838.699341 
	C622.987793,839.000000 622.536743,838.992065 622.311157,838.989136 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M653.464722,924.121582 
	C653.474182,923.521118 653.765320,922.804932 654.358582,922.007568 
	C654.355896,922.619507 654.051147,923.312683 653.464722,924.121582 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M436.522644,453.078705 
	C436.388580,452.453094 436.569305,451.740173 436.985077,450.868469 
	C437.092590,451.470245 436.965027,452.230804 436.522644,453.078705 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M438.616241,469.748962 
	C438.063965,469.493011 437.574219,468.934052 437.103516,468.059174 
	C437.641266,468.310822 438.160034,468.878418 438.616241,469.748962 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M432.669098,348.021179 
	C432.484283,348.617706 431.982300,349.256012 431.132324,349.882446 
	C431.306885,349.268005 431.829437,348.665466 432.669098,348.021179 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M313.365356,730.206787 
	C313.926300,730.428955 314.444031,730.947388 314.947510,731.766663 
	C314.396210,731.546021 313.859161,731.024536 313.365356,730.206787 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M601.723755,669.087769 
	C601.543701,669.691040 601.042358,670.301758 600.208435,670.903442 
	C600.384705,670.294617 600.893616,669.694946 601.723755,669.087769 
z"/>
<path fill="#FD6162" opacity="1.000000" stroke="none" 
	d="
M799.985535,689.336426 
	C800.536072,689.236755 801.051697,689.468567 801.567261,689.700439 
	C801.251038,690.107788 800.934814,690.515137 800.321289,690.958984 
	C799.999390,690.553040 799.974976,690.110535 799.985535,689.336426 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M347.952301,762.390747 
	C348.547760,762.411194 349.232361,762.721252 349.995422,763.321655 
	C349.396393,763.301453 348.718933,762.990845 347.952301,762.390747 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M345.062500,760.314941 
	C345.652649,760.358826 346.303162,760.698120 347.020569,761.322266 
	C346.432648,761.274841 345.777802,760.942627 345.062500,760.314941 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M334.026428,752.337646 
	C334.621307,752.390015 335.283020,752.735291 336.008850,753.372253 
	C335.413055,753.319519 334.753174,752.974976 334.026428,752.337646 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M338.005127,755.356506 
	C338.605713,755.413879 339.275024,755.768005 340.003723,756.421997 
	C339.400055,756.365662 338.736969,756.009460 338.005127,755.356506 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M340.905334,757.440186 
	C341.508698,757.476135 342.215485,757.799561 342.989868,758.432312 
	C342.374542,758.403625 341.691681,758.065674 340.905334,757.440186 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M399.264832,783.932861 
	C399.716461,783.729126 400.336060,783.780151 401.042450,784.041016 
	C400.563782,784.229858 399.998291,784.208740 399.264832,783.932861 
z"/>
<path fill="#FEC7C7" opacity="1.000000" stroke="none" 
	d="
M669.096436,532.520142 
	C668.486694,532.442200 667.779297,532.065735 667.028320,531.356079 
	C667.656067,531.422485 668.327454,531.822083 669.096436,532.520142 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M729.281982,917.225098 
	C729.843079,917.466736 730.363708,918.006348 730.837219,918.857056 
	C730.273865,918.619873 729.757629,918.071472 729.281982,917.225098 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M861.336426,918.210938 
	C861.651428,918.107849 861.836548,918.316833 861.933716,918.826904 
	C861.611206,918.965454 861.398132,918.763855 861.336426,918.210938 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M436.158875,453.759338 
	C435.850220,453.781891 435.612000,453.622101 435.373779,453.462311 
	C435.486664,453.354584 435.599579,453.246857 435.948425,453.163940 
	C436.184357,453.188721 436.229309,453.577026 436.158875,453.759338 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M736.852417,925.437988 
	C737.059326,925.320190 737.392639,925.440552 737.836670,925.813721 
	C737.624512,925.936401 737.301636,925.806213 736.852417,925.437988 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M438.353333,447.985962 
	C438.206451,447.792084 438.283783,447.472321 438.585327,447.027069 
	C438.732239,447.221069 438.654877,447.540558 438.353333,447.985962 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M619.654297,609.205750 
	C619.913025,609.178589 620.008362,609.330078 620.069580,609.645264 
	C619.760254,609.816162 619.578674,609.674683 619.654297,609.205750 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M621.621338,616.229309 
	C621.907776,616.228149 622.001587,616.423767 622.010559,616.816040 
	C621.664917,616.921814 621.499207,616.726257 621.621338,616.229309 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M623.739929,635.029785 
	C624.027893,635.161377 624.016296,635.411316 623.785889,635.807495 
	C623.364014,635.732300 623.321838,635.463745 623.739929,635.029785 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M618.582642,651.739746 
	C618.689087,651.942688 618.558472,652.310608 618.154175,652.805298 
	C618.035461,652.589661 618.190552,652.247131 618.582642,651.739746 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M680.225586,378.917419 
	C679.965820,378.751678 680.010010,378.504852 680.293213,378.142334 
	C680.669067,378.278503 680.668213,378.548431 680.225586,378.917419 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M681.252808,371.741150 
	C680.939270,371.776733 680.696777,371.624023 680.454346,371.471344 
	C680.564636,371.362518 680.674927,371.253693 681.021912,371.150269 
	C681.258606,371.155670 681.323853,371.552856 681.252808,371.741150 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M401.661377,370.992279 
	C401.700806,371.191040 401.496826,371.473846 401.024353,371.805115 
	C400.976593,371.594513 401.197296,371.335419 401.661377,370.992279 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M680.719666,360.959320 
	C680.679382,361.227722 680.263000,361.193512 680.061584,361.134460 
	C679.886536,360.903503 679.912903,360.731628 679.954590,360.299500 
	C680.233276,360.256470 680.496582,360.473694 680.719666,360.959320 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M679.856445,359.804260 
	C679.683533,359.825287 679.411011,359.625916 679.105713,359.165588 
	C679.340454,359.091125 679.568481,359.317566 679.856445,359.804260 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M319.451996,738.162720 
	C319.683380,738.026245 319.849579,738.147461 320.039551,738.469971 
	C320.107422,738.610840 319.711578,738.640137 319.711578,738.640137 
	C319.711578,738.640137 319.430908,738.359985 319.451996,738.162720 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M331.429901,750.131165 
	C331.632812,749.979004 331.778107,750.060547 331.959106,750.300781 
	C332.024231,750.407166 331.684601,750.544800 331.684601,750.544800 
	C331.684601,750.544800 331.401642,750.312622 331.429901,750.131165 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M644.375610,763.998901 
	C643.996277,762.593201 643.992554,761.181519 643.974731,759.343140 
	C643.960632,758.916504 643.977844,758.457275 644.293640,758.195801 
	C644.718323,757.276367 644.827209,756.618286 644.936096,755.960266 
	C644.957886,755.216553 644.979675,754.472900 645.180359,753.274658 
	C645.421326,752.720703 645.559204,752.570435 645.532471,752.530579 
	C645.408813,752.346252 645.239380,752.192627 645.086365,752.027954 
	C645.056030,751.278687 645.025696,750.529480 644.975220,749.406799 
	C644.934448,746.086365 644.150269,746.205261 640.029236,745.910339 
	C630.961487,745.261353 623.436279,749.433899 615.579712,752.728455 
	C594.729797,761.471497 573.082764,767.524475 550.919922,771.810852 
	C549.842163,772.019226 548.654724,771.660339 547.259033,771.281616 
	C555.181396,768.687378 563.444702,766.628662 571.525940,764.008423 
	C583.748291,760.045471 595.968811,756.014648 607.946533,751.382812 
	C615.175659,748.587280 622.023010,744.749023 628.897156,741.106079 
	C635.828918,737.432556 643.172729,739.415466 650.335693,739.290161 
	C651.191284,739.275208 652.897461,742.154907 652.830078,743.629761 
	C652.623047,748.154297 651.428650,752.633301 651.217529,757.158020 
	C651.010559,761.591919 648.275208,763.017090 644.375610,763.998901 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M635.040527,525.375305 
	C647.484436,529.577576 658.093506,537.219421 668.094727,545.703796 
	C674.158203,550.847656 679.572449,556.755737 685.621826,561.918030 
	C687.590942,563.598389 690.646301,564.005798 693.599976,564.987915 
	C695.159790,566.040405 696.704285,566.890015 697.426941,568.194763 
	C701.778320,576.051147 705.539307,584.165710 707.058411,593.523254 
	C700.755493,597.143188 699.352783,596.615173 696.575806,590.234802 
	C688.136658,570.845093 675.630493,554.505981 658.926025,541.594666 
	C652.411072,536.559082 645.094727,532.562073 638.162964,528.061890 
	C637.086792,527.363159 636.115784,526.502319 635.040527,525.375305 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M597.628723,509.948669 
	C599.292969,510.704071 600.600647,511.460297 601.983887,512.545471 
	C595.086731,511.513062 588.113953,510.151642 581.052673,508.419373 
	C584.362122,508.341431 587.760010,508.634338 591.786133,509.214478 
	C593.321289,509.607758 594.228333,509.713867 595.135315,509.819946 
	C595.847595,509.863098 596.559875,509.906281 597.628723,509.948669 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M536.445679,324.852753 
	C536.204346,324.322571 536.430298,323.515350 536.642761,323.518890 
	C543.020813,323.625488 549.397522,323.812225 555.895630,324.350128 
	C549.646729,324.793823 543.276672,324.873749 536.445679,324.852753 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M504.046783,330.099121 
	C499.532440,331.460144 495.018127,332.821136 490.312134,333.768066 
	C490.392212,332.858643 490.664001,332.363312 491.234802,331.928101 
	C492.354614,331.642395 493.175446,331.296539 494.395966,330.951965 
	C496.117371,330.853241 497.439117,330.753204 499.116516,330.724518 
	C500.343475,330.450012 501.214691,330.104187 502.378235,329.800232 
	C503.060577,329.808380 503.450653,329.774567 503.893005,329.829895 
	C503.945312,329.919037 504.046783,330.099121 504.046783,330.099121 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M606.068237,514.352051 
	C609.741333,514.657776 613.475159,515.327393 617.583740,516.398010 
	C617.997742,517.511414 618.037048,518.223694 618.076355,518.936035 
	C614.093872,517.529297 610.111450,516.122559 606.068237,514.352051 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M490.935791,331.867981 
	C490.664001,332.363312 490.392212,332.858643 489.984863,333.601837 
	C489.849243,333.849701 489.393494,333.865723 489.165375,333.881714 
	C488.523895,333.867676 488.110565,333.837677 487.340454,333.486328 
	C486.654480,332.429413 486.325256,331.693848 485.996033,330.958252 
	C487.524902,330.454071 489.053772,329.949921 490.783203,330.017578 
	C490.967743,331.015594 490.951752,331.441772 490.935791,331.867981 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M618.395874,518.948242 
	C618.037048,518.223694 617.997742,517.511414 617.936890,516.444336 
	C620.232788,516.835449 622.550354,517.581360 624.716064,518.934814 
	C623.041443,519.364868 621.518677,519.187317 619.995850,519.009766 
	C619.569031,518.993347 619.142212,518.976868 618.395874,518.948242 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M538.607422,772.015747 
	C537.897217,777.271362 533.973999,773.841248 531.185364,774.240112 
	C533.402893,773.335083 535.808350,772.677185 538.607422,772.015747 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M620.062622,519.369019 
	C621.518677,519.187317 623.041443,519.364868 624.777039,519.302185 
	C625.415649,519.065430 625.841492,519.068909 626.584106,519.094849 
	C626.935486,519.833801 626.970093,520.550293 627.011658,521.626831 
	C624.722168,521.234009 622.425781,520.481140 620.062622,519.369019 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M448.093750,719.794739 
	C449.889954,719.979553 451.686157,720.164368 453.754242,720.612427 
	C451.839355,721.061646 449.652557,721.247559 447.370880,720.913330 
	C447.548553,720.193726 447.821167,719.994202 448.093750,719.794739 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M487.995850,500.970093 
	C490.285919,501.042297 492.575989,501.114502 494.959656,501.544617 
	C492.995239,501.940887 490.937164,501.979279 488.440125,501.745483 
	C487.999390,501.305573 487.997620,501.137848 487.995850,500.970093 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M507.017761,327.769653 
	C508.284546,327.501495 509.551331,327.233307 510.870148,327.564697 
	C510.915833,328.356201 510.909515,328.548157 510.903229,328.740112 
	C510.467865,328.773438 510.032471,328.806763 508.966858,328.856750 
	C507.929321,328.885101 507.522034,328.896790 507.084900,328.624237 
	C507.042664,328.149902 507.030212,327.959778 507.017761,327.769653 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M560.002441,505.010864 
	C561.960266,505.087494 563.918030,505.164093 565.950684,505.598389 
	C564.303223,505.990662 562.580994,506.025269 560.429382,505.792175 
	C560.000793,505.353271 560.001648,505.182068 560.002441,505.010864 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M484.200439,721.162048 
	C485.728455,720.566650 487.486908,720.276306 489.646088,720.066528 
	C488.174805,720.587097 486.302826,721.027100 484.200439,721.162048 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M525.000000,503.003571 
	C526.959167,503.076721 528.918335,503.149902 530.957764,503.577820 
	C529.312256,503.972015 527.586426,504.011444 525.430420,503.783356 
	C525.000183,503.345093 525.000061,503.174347 525.000000,503.003571 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M508.324829,502.896118 
	C509.563599,502.611725 511.224304,502.421722 512.963135,502.578918 
	C511.609802,502.947601 510.178284,502.969055 508.324829,502.896118 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M524.575928,502.992004 
	C525.000061,503.174347 525.000183,503.345093 524.999634,503.772186 
	C523.365479,503.959045 521.731995,503.889557 520.000610,503.488892 
	C521.319153,503.098633 522.735474,503.039520 524.575928,502.992004 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M477.984955,499.911774 
	C479.621246,500.021820 481.257538,500.131866 482.967346,500.584351 
	C481.640472,500.965302 480.240082,501.003815 478.418518,500.750916 
	C477.993256,500.276886 477.989105,500.094330 477.984955,499.911774 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M515.520325,326.989380 
	C515.157532,326.592041 514.794678,326.194702 514.431885,325.797424 
	C515.530579,325.742035 516.629211,325.686646 517.924072,325.917358 
	C518.120178,326.203400 518.110474,326.652374 517.662354,326.817841 
	C516.649597,326.985321 516.084961,326.987335 515.520325,326.989380 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M526.200500,326.017548 
	C526.105469,326.618683 526.010498,327.219818 525.915527,327.820953 
	C524.973206,327.492676 524.030823,327.164398 523.409546,326.426941 
	C524.553955,326.017670 525.377197,326.017609 526.200500,326.017548 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M526.178345,325.817627 
	C525.377197,326.017609 524.553955,326.017670 523.329712,326.107849 
	C522.928711,326.197906 522.454651,326.060822 522.222656,325.975952 
	C523.228088,325.466309 524.465515,325.041565 525.916748,324.916534 
	C526.130554,325.216248 526.156250,325.617706 526.178345,325.817627 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M448.016418,719.495117 
	C447.821167,719.994202 447.548553,720.193726 447.140656,720.602661 
	C446.596466,720.828918 446.187561,720.845703 445.475708,720.833618 
	C445.123871,720.395386 445.074982,719.985840 445.018951,719.264771 
	C445.987610,719.033936 446.963348,719.114685 448.016418,719.495117 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M495.159363,720.123840 
	C496.080811,719.543030 497.197021,719.259033 498.676514,719.046265 
	C497.811218,719.551880 496.582672,719.986267 495.159363,720.123840 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M559.591797,504.996216 
	C560.001648,505.182068 560.000793,505.353271 559.999268,505.781616 
	C558.691162,505.960052 557.383667,505.881348 555.986328,505.485962 
	C556.991333,505.106720 558.086243,505.044128 559.591797,504.996216 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M547.345642,504.965027 
	C548.260193,504.684723 549.579712,504.479309 550.964233,504.610992 
	C549.936401,504.978699 548.843445,505.009338 547.345642,504.965027 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M477.607208,499.911499 
	C477.989105,500.094330 477.993256,500.276886 477.998779,500.733459 
	C477.018860,500.897278 476.037598,500.787109 475.011841,500.359741 
	C475.721405,499.998749 476.475433,499.954956 477.607208,499.911499 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M541.996948,503.976044 
	C542.979553,504.063293 543.962158,504.150574 545.013428,504.541473 
	C544.312317,504.907593 543.542542,504.970062 542.386597,504.761230 
	C541.999390,504.318665 541.998169,504.147339 541.996948,503.976044 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M541.605713,503.972351 
	C541.998169,504.147339 541.999390,504.318665 542.000244,504.746948 
	C541.014832,504.934418 540.029663,504.864868 538.961670,504.499146 
	C539.657471,504.124847 540.435974,504.046753 541.605713,503.972351 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M576.005127,507.378052 
	C576.716553,507.026733 577.477783,506.990265 578.621094,507.233032 
	C579.004639,507.690094 579.006104,507.867950 579.007629,508.045807 
	C578.023438,507.928192 577.039185,507.810547 576.005127,507.378052 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M502.815918,717.137573 
	C503.126373,717.602234 503.075714,718.007202 503.013245,718.720154 
	C502.234741,719.019043 501.468048,719.010071 500.322998,718.931458 
	C500.781342,718.267212 501.618073,717.672546 502.815918,717.137573 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M507.114716,328.908478 
	C507.522034,328.896790 507.929321,328.885101 508.640930,328.888855 
	C508.099670,329.443665 507.254120,329.983063 506.325684,330.032715 
	C506.533417,329.331482 506.824066,329.119965 507.114716,328.908478 
z"/>
<path fill="#FB7070" opacity="1.000000" stroke="none" 
	d="
M631.038940,523.390930 
	C631.895752,523.504456 632.813293,523.957520 633.868530,524.702759 
	C633.037354,524.573425 632.068542,524.151978 631.038940,523.390930 
z"/>
<path fill="#FE8A8A" opacity="1.000000" stroke="none" 
	d="
M507.084900,328.624237 
	C506.824066,329.119965 506.533417,329.331482 506.117615,329.783356 
	C505.559357,330.031372 505.126312,330.039062 504.369995,330.072937 
	C504.046783,330.099121 503.945312,329.919037 503.928101,329.528351 
	C503.926453,328.726532 503.942078,328.315430 503.957703,327.904297 
	C504.725494,327.909119 505.493347,327.913940 506.639465,327.844238 
	C507.030212,327.959778 507.042664,328.149902 507.084900,328.624237 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M473.355072,500.094330 
	C473.013672,499.903198 473.009033,499.709900 473.003174,499.225983 
	C473.660461,499.083771 474.318939,499.232208 475.004211,499.668518 
	C474.584595,500.001678 474.138245,500.046936 473.355072,500.094330 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M511.057495,329.011353 
	C510.909515,328.548157 510.915833,328.356201 510.952454,327.874023 
	C511.423279,327.674286 511.863800,327.764709 512.639526,327.899597 
	C512.387024,328.390228 511.799377,328.836395 511.057495,329.011353 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M515.609497,327.143341 
	C516.084961,326.987335 516.649597,326.985321 517.632080,327.040314 
	C517.410767,327.463684 516.771606,327.830048 515.977173,327.913788 
	C515.821899,327.631165 515.698669,327.297333 515.609497,327.143341 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M460.289490,720.935547 
	C460.613708,720.678955 461.284607,720.500488 462.000488,720.606812 
	C461.575714,720.932373 461.105957,720.973022 460.289490,720.935547 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M487.646240,500.967865 
	C487.997620,501.137848 487.999390,501.305573 488.000671,501.724701 
	C487.338318,501.880096 486.676483,501.784088 485.962158,501.413757 
	C486.372040,501.081482 486.834351,501.023560 487.646240,500.967865 
z"/>
<path fill="#EC5758" opacity="1.000000" stroke="none" 
	d="
M302.294800,680.764404 
	C302.177368,679.823303 302.325195,678.646545 302.745178,677.238647 
	C302.864929,678.181213 302.712524,679.354919 302.294800,680.764404 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M615.291748,867.769592 
	C615.171021,866.822571 615.317383,865.637146 615.731873,864.212708 
	C615.852905,865.159546 615.705872,866.345337 615.291748,867.769592 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M616.287598,862.763794 
	C616.167786,861.818542 616.315552,860.637146 616.731628,859.219360 
	C616.851685,860.164490 616.703369,861.346069 616.287598,862.763794 
z"/>
<path fill="#FD7E7D" opacity="1.000000" stroke="none" 
	d="
M621.003113,844.993896 
	C621.512939,845.231323 622.026306,845.464355 622.539612,845.697510 
	C622.226562,846.100708 621.913513,846.503906 621.307556,846.612976 
	C621.014343,845.871765 621.013977,845.424622 621.013672,844.977539 
	C621.013611,844.977539 621.006653,844.989502 621.003113,844.993896 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M641.283203,774.762390 
	C641.164673,773.815186 641.315247,772.631653 641.731689,771.206726 
	C641.849121,772.152283 641.700684,773.339111 641.283203,774.762390 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M604.166626,513.217346 
	C604.660645,513.072571 605.314148,513.191772 606.019287,513.587891 
	C605.489258,513.736938 604.907715,513.609192 604.166626,513.217346 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M579.344604,508.053192 
	C579.006104,507.867950 579.004639,507.690094 579.002136,507.245300 
	C579.657715,507.116272 580.314270,507.254242 580.997559,507.679443 
	C580.576721,507.998016 580.129150,508.029297 579.344604,508.053192 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M567.957275,506.416473 
	C568.371704,506.078156 568.835449,506.014709 569.650391,506.225037 
	C570.002747,506.675964 570.003845,506.853149 570.004883,507.030334 
	C569.338806,506.917328 568.672668,506.804352 567.957275,506.416473 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M544.702515,771.026733 
	C544.445251,771.489868 543.890503,771.970032 543.165405,772.219788 
	C543.465088,771.674133 543.934998,771.358887 544.702515,771.026733 
z"/>
<path fill="#FBA0A0" opacity="1.000000" stroke="none" 
	d="
M570.349243,507.038757 
	C570.003845,506.853149 570.002747,506.675964 570.001587,506.232666 
	C570.660034,506.089447 571.318481,506.212311 572.019043,506.613678 
	C571.605225,506.943848 571.149414,506.995514 570.349243,507.038757 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M620.749878,845.184204 
	C621.013977,845.424622 621.014343,845.871765 621.012573,846.653809 
	C620.905212,847.305542 620.720215,847.565613 620.241577,847.880737 
	C620.180420,847.125366 620.333252,846.258057 620.749878,845.184204 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M621.323120,842.757812 
	C621.214172,842.117859 621.351379,841.270630 621.747192,840.225098 
	C621.860352,840.867981 621.714844,841.709290 621.323120,842.757812 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M640.270508,778.788940 
	C640.165039,778.132446 640.317932,777.266724 640.734619,776.195679 
	C640.841919,776.853394 640.685425,777.716614 640.270508,778.788940 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M619.276611,850.822510 
	C619.197632,850.439514 619.354309,849.885193 619.756714,849.167969 
	C619.839050,849.553772 619.675659,850.102478 619.276611,850.822510 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M614.225952,872.871216 
	C614.004517,872.572327 614.005493,872.125061 614.024109,871.343262 
	C614.177246,871.580017 614.312744,872.151428 614.225952,872.871216 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M642.101440,768.328369 
	C642.147034,767.994324 642.562805,767.997559 642.770386,767.997559 
	C642.818604,768.553589 642.659241,769.109680 642.259155,769.837708 
	C642.030945,769.560608 642.043396,769.111511 642.101440,768.328369 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M617.357666,856.755066 
	C617.272705,856.391296 617.405945,855.863403 617.772278,855.179504 
	C617.862244,855.546021 617.719055,856.068420 617.357666,856.755066 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M595.046753,509.518677 
	C594.228333,509.713867 593.321289,509.607758 592.205444,509.221863 
	C592.983765,509.033813 593.970947,509.125610 595.046753,509.518677 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M465.332458,340.915955 
	C465.949066,339.519043 466.939453,338.109344 467.966278,336.362183 
	C469.762726,335.773254 471.522736,335.521820 474.012756,335.126465 
	C475.511139,335.003174 476.279449,335.023865 477.047791,335.044556 
	C473.267303,336.997437 469.486816,338.950287 465.332458,340.915955 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M485.612366,330.975159 
	C486.325256,331.693848 486.654480,332.429413 487.030548,333.455383 
	C485.596191,334.181061 484.114990,334.616364 482.326721,335.048096 
	C481.563934,335.025085 481.108246,335.005676 480.315308,334.602753 
	C479.989319,333.482513 480.000549,332.745819 480.011810,332.009094 
	C481.750763,331.670074 483.489716,331.331055 485.612366,330.975159 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M479.638977,332.045654 
	C480.000549,332.745819 479.989319,333.482513 479.973816,334.586456 
	C479.176208,334.989471 478.382904,335.025299 477.318665,335.052826 
	C476.279449,335.023865 475.511139,335.003174 474.359039,334.964172 
	C475.738892,333.991302 477.502533,333.036743 479.638977,332.045654 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M498.760834,330.653198 
	C497.439117,330.753204 496.117371,330.853241 494.402100,330.685516 
	C494.001709,330.239838 493.994904,330.061920 493.988129,329.884003 
	C495.102631,329.489044 496.217163,329.094055 497.726074,329.058716 
	C498.333923,329.829956 498.547394,330.241577 498.760834,330.653198 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M574.005859,325.503571 
	C572.892578,325.741150 571.687439,325.658020 570.239868,325.306152 
	C571.303040,325.085876 572.608521,325.134369 574.005859,325.503571 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M493.615753,329.917969 
	C493.994904,330.061920 494.001709,330.239838 494.002411,330.684235 
	C493.175446,331.296539 492.354614,331.642395 491.234772,331.928101 
	C490.951752,331.441772 490.967743,331.015594 490.991943,330.267273 
	C491.747925,329.947418 492.495636,329.949677 493.615753,329.917969 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M499.116516,330.724518 
	C498.547394,330.241577 498.333923,329.829956 498.028381,329.196777 
	C499.012238,328.946381 500.088135,328.917542 501.595947,329.088196 
	C502.027832,329.287720 502.085938,329.758331 502.085938,329.758331 
	C501.214691,330.104187 500.343475,330.450012 499.116516,330.724518 
z"/>
<path fill="#FDBCBC" opacity="1.000000" stroke="none" 
	d="
M502.378235,329.800232 
	C502.085938,329.758331 502.027832,329.287720 501.996338,329.050720 
	C502.518921,328.369873 503.073029,327.926086 503.792419,327.693298 
	C503.942078,328.315430 503.926453,328.726532 503.875793,329.439209 
	C503.450653,329.774567 503.060577,329.808380 502.378235,329.800232 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M516.825317,714.164429 
	C516.351440,714.823975 515.512268,715.414429 514.321289,715.980225 
	C514.799744,715.335510 515.629944,714.715515 516.825317,714.164429 
z"/>
<path fill="#FEA8A8" opacity="1.000000" stroke="none" 
	d="
M441.036438,718.565247 
	C440.509125,718.718201 439.923523,718.596741 439.170654,718.213989 
	C439.661621,718.065308 440.319885,718.178101 441.036438,718.565247 
z"/>
<path fill="#FD6162" opacity="1.000000" stroke="none" 
	d="
M895.476624,858.597229 
	C895.421021,859.059631 895.191895,859.341003 894.962769,859.622314 
	C894.785156,859.436890 894.607544,859.251404 894.429932,859.065979 
	C894.721008,858.849426 895.012085,858.632812 895.476624,858.597229 
z"/>
<path fill="#FD6162" opacity="1.000000" stroke="none" 
	d="
M893.046509,852.380249 
	C893.339600,852.742493 893.379333,853.102478 893.419128,853.462402 
	C893.162048,853.460022 892.905029,853.457642 892.647949,853.455261 
	C892.696350,853.096130 892.744690,852.737061 893.046509,852.380249 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M636.901611,790.711670 
	C636.613892,790.795959 636.212158,790.697021 636.151672,790.495544 
	C636.091614,790.295532 636.374756,789.992371 636.507446,789.734436 
	C636.650696,789.984192 636.793945,790.233887 636.901611,790.711670 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M637.154541,786.302002 
	C637.438293,786.219177 637.837708,786.320312 637.890869,786.515747 
	C637.946472,786.720032 637.669006,787.015015 637.537109,787.270325 
	C637.397400,787.022705 637.257690,786.775085 637.154541,786.302002 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M635.652588,795.117432 
	C635.380310,794.845581 635.268555,794.437195 635.215515,794.021362 
	C635.205750,793.945129 635.434631,793.838562 635.553345,793.745972 
	C635.646667,794.156738 635.740051,794.567505 635.652588,795.117432 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M631.658203,808.117554 
	C631.389954,807.847473 631.279968,807.439697 631.228699,807.024658 
	C631.219177,806.947205 631.444763,806.840637 631.561829,806.747559 
	C631.653503,807.157532 631.745178,807.567505 631.658203,808.117554 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M625.894165,828.281372 
	C625.793823,828.762207 625.654968,829.015076 625.516113,829.267944 
	C625.379578,829.016052 625.085876,828.711487 625.148010,828.526245 
	C625.219421,828.313477 625.605957,828.206421 625.894165,828.281372 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M626.151855,824.738647 
	C626.253296,824.256714 626.390503,824.002258 626.527710,823.747803 
	C626.663757,824.001831 626.954346,824.304260 626.894104,824.496887 
	C626.828491,824.706421 626.437012,824.814026 626.151855,824.738647 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M627.400757,819.903748 
	C627.664185,820.171997 627.772522,820.578308 627.822327,820.991699 
	C627.831604,821.068848 627.605774,821.174316 627.488464,821.266785 
	C627.400818,820.859253 627.313171,820.451782 627.400757,819.903748 
z"/>
<path fill="#EE5959" opacity="1.000000" stroke="none" 
	d="
M630.251831,812.011353 
	C630.420044,811.899048 630.588745,811.954041 630.757385,812.009033 
	C630.588989,812.065613 630.420654,812.122131 630.251831,812.011353 
z"/>
<path fill="#FD6162" opacity="1.000000" stroke="none" 
	d="
M624.541077,833.557251 
	C624.478333,833.597229 624.603821,833.517334 624.541077,833.557251 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M570.340820,698.950806 
	C572.395508,697.651917 574.816040,696.325745 577.615723,694.994934 
	C575.565430,696.301331 573.136047,697.612427 570.340820,698.950806 
z"/>
<path fill="#FD8E8E" opacity="1.000000" stroke="none" 
	d="
M559.156616,703.271240 
	C559.567993,702.863403 560.131104,702.678284 560.850342,702.710571 
	C560.440491,703.116638 559.874451,703.305298 559.156616,703.271240 
z"/>
<path fill="#EC5758" opacity="1.000000" stroke="none" 
	d="
M644.709290,756.146118 
	C644.827209,756.618286 644.718323,757.276367 644.315369,757.967102 
	C644.175049,757.443848 644.328735,756.887939 644.709290,756.146118 
z"/>
<path fill="#EC5758" opacity="1.000000" stroke="none" 
	d="
M645.060181,752.266907 
	C645.239380,752.192627 645.408813,752.346252 645.532471,752.530579 
	C645.559204,752.570435 645.421326,752.720703 645.202759,752.903259 
	C645.046265,752.986450 645.034058,752.505859 645.060181,752.266907 
z"/>

</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
